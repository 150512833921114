import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { getWorkloadsPreferences } from '@/services/api/workspace/workspace';
import { TSuccessErrorHook } from '@/types/generic';
import { TWorkloadPreference } from '@/types/insights';

const GET_WORKLOADS_PREFRENCES_KEY = 'get-workloads-prefrences';
export function useWorkloadPref(
  {
    enabled = true,
    onError,
    onSuccess,
  }: TSuccessErrorHook<Array<TWorkloadPreference>> = { enabled: true },
) {
  const { workspaceId } = useContext(UserContext);
  return useQuery(
    [GET_WORKLOADS_PREFRENCES_KEY, workspaceId],
    async () => getWorkloadsPreferences(workspaceId ?? ''),
    {
      enabled: !!workspaceId && enabled,
      onError,
      onSuccess,
    },
  );
}
