import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { joinFeatureFlags } from '@/services/api/workspace/workspace';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';


export default function useJoinFeatureFlags({
  onSuccess,
  onError,
}: TSuccessErrorHook<void> = {}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);
  const queryClient = useQueryClient();

  return useMutation<void, TResponseError, { code: string }>(
    async ({ code }: { code: string }) => {
      if (!workspaceId) return;
      await joinFeatureFlags({ workspaceId, code });
    },
    {
      onError: (error: TResponseError) => {
        onError?.(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess() {
        queryClient.invalidateQueries([]);
        onSuccess?.();
      },
    },
  );
}
