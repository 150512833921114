import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Portal } from '@headlessui/react';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import {
  Item,
  Menu,
  RightSlot,
  TriggerEvent,
  useContextMenu,
} from 'react-contexify-moze';

import { TResourceItemList } from '@/types/timeline';

import styles from './styles.module.css';
import ShortcutMenu from '../ShortcutMenu';

interface BaseInputSelectProps<T> {
  type: 'normal' | 'shortcut';
  menuId: string;
  onResourceSelected?: (resource: TResourceItemList) => void;
  label?: JSX.Element | string;
  placeholder?: string;
  fullWidth?: boolean;
  options?: T[];
  optionsMapper?: (item: T) => JSX.Element;
  selectionMapper?: (item: T) => JSX.Element;
  onSelectChange?: (item: T) => void;
  shortcutMapper?: (item: T, index: number) => string;
  selectedElement?: T;
}

interface NormalInputSelectProps<T> extends BaseInputSelectProps<T> {
  type: 'normal';
  shortcutMapper?: never;
}

interface ShortcutInputSelectProps<T> extends BaseInputSelectProps<T> {
  type: 'shortcut';
  shortcutMapper: (item: T, index: number) => string;
}

type InputSelectProps<T> =
  | NormalInputSelectProps<T>
  | ShortcutInputSelectProps<T>;

function InputSelect<T>({
  type,
  menuId,
  label,
  placeholder,
  fullWidth,
  options,
  optionsMapper,
  onSelectChange,
  selectionMapper,
  shortcutMapper,
  selectedElement,
}: InputSelectProps<T>) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>();
  const { show, hideAll } = useContextMenu({ id: menuId });

  const [menuWidth, setMenuWidth] = useState(320);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function getMenuPosition(boundingRect?: DOMRect) {
    const { bottom, width, x } = boundingRect ?? {
      left: 0,
      bottom: 0,
      width: 0,
    };
    menuPositionRef.current = { x: x ?? width, y: bottom + 4 };
    return menuPositionRef.current;
  }

  const MenuType = type === 'normal' ? Menu : ShortcutMenu;

  const eventRef = useRef<TriggerEvent>();

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) hideAll();
      else {
        const boundingRect = triggerRef.current?.getBoundingClientRect();
        setMenuWidth(boundingRect?.width ?? 400);
        eventRef.current = e;

        show({
          id: menuId,
          event: e,
          position: getMenuPosition(boundingRect),
        });
      }
    },
    [hideAll, menuId, menuIsOpen, show],
  );

  const handleEscButton = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        hideAll();
      }
    },
    [hideAll],
  );

  useEffect(() => {
    if (menuIsOpen) {
      const opt = { capture: true };
      window.addEventListener('keydown', handleEscButton, opt);
      return () => {
        window.removeEventListener('keydown', handleEscButton, opt);
      };
    }
  }, [handleEscButton, menuIsOpen]);

  const renderOptions = useCallback(() => {
    const onSelect = (item: T) => {
      hideAll();
      onSelectChange?.(item);
      setMenuIsOpen(false);
    };
    if (!options) return 'No options available.';
    if (type === 'normal') {
      return options?.map((item, index) => (
        <Item key={index} onClick={() => onSelect(item)}>
          {optionsMapper?.(item) ?? <>{item}</>}
        </Item>
      ));
    }
    return options?.map((item, index) => {
      const shortcut = shortcutMapper(item, index);
      return (
        <ShortcutMenu.Item
          key={index}
          onClick={() => onSelect(item)}
          keyMatch={shortcut}
          keyHandler={() => onSelect(item)}
        >
          {optionsMapper?.(item)}
          <RightSlot>{shortcut.toUpperCase()}</RightSlot>{' '}
        </ShortcutMenu.Item>
      );
    });
  }, [hideAll, onSelectChange, options, optionsMapper, shortcutMapper, type]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.full]: fullWidth,
      })}
    >
      {label ? (
        React.isValidElement(label) ? (
          label
        ) : (
          <label>{label}</label>
        )
      ) : undefined}
      <button
        ref={triggerRef}
        onClick={onClickFn}
        className={classNames(styles.selectButton, {
          [styles.selected]: !!selectedElement,
          [styles.open]: menuIsOpen,
        })}
      >
        <span>
          {(selectedElement && selectionMapper?.(selectedElement)) ||
            placeholder ||
            'Select ...'}
        </span>
        <IconChevronDown size={20} />
      </button>
      <Portal>
        {menuIsOpen && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className={styles.menuOverlay}
            onClick={() => {
              hideAll();
              setMenuIsOpen(false);
            }}
          />
        )}
        <MenuType
          animation={false}
          style={{ minWidth: menuWidth ?? 304 }}
          className={styles.menu}
          id={menuId}
          onVisibilityChange={setMenuIsOpen}
        >
          {renderOptions()}
        </MenuType>
      </Portal>
    </div>
  );
}

export default InputSelect;
