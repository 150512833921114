import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { HIDDEN_PROJECTS_QUERY } from '@/hooks/workspace/projects/useHiddenProjectQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { SEARCH_PROJECTS_KEY } from '@/hooks/workspace/projects/useProjectsQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { assignProjectToResource } from '@/services/api/workspace/resources';

type Props = {
  resourceId: string;
  projectId: string;
};

export default function useAssignProjectToResource() {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>(
    async ({ resourceId, projectId }: Props): Promise<void> => {
      await assignProjectToResource({ workspaceId, resourceId, projectId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RESOURCES_QUERY_KEY, workspaceId]);
        queryClient.invalidateQueries([HIDDEN_PROJECTS_QUERY, workspaceId]);
        queryClient.invalidateQueries([SEARCH_PROJECTS_KEY, workspaceId]);
        queryClient.invalidateQueries([INSIGHT_PART_QUERY_KEY, workspaceId]);
        queryClient.invalidateQueries([PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY]);
      },
      onError: (error: Error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
