import { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { omit } from 'lodash';
import type { IContentLoaderProps } from 'react-content-loader';
import ContentLoader from 'react-content-loader';

import { getCssVariable } from '@/services/helpers';

import styles from './styles.module.css';

const SkeletonTimelineHeader = (
  props: { isExpanded: boolean } & IContentLoaderProps,
) => {
  const randomWidth = useCallback(() => {
    const min = 160;
    const max = 260;
    const step = 30;
    return Math.floor((Math.random() * (max - min + 1)) / step) * step + min;
  }, []);

  const width = useMemo(() => randomWidth(), [randomWidth]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.expanded]: props.isExpanded,
      })}
    >
      <ContentLoader
        speed={2}
        width={getCssVariable('--sidebar-width')}
        height={24}
        viewBox="0 0 288 24"
        {...omit(props, 'isExpanded')}
      >
        <rect x="0" y="0" rx="6" ry="6" width="24" height="24" />
        <rect x="32" y="6" rx="6" ry="6" width={width} height="12" />
      </ContentLoader>
    </div>
  );
};

export default SkeletonTimelineHeader;
