import { useEffect, useMemo, useState } from 'react';

export const useIntersectionObserver = (
  onVisibilityChange?: (entry: IntersectionObserverEntry[]) => void,
  initConfig: IntersectionObserverInit = {},
) => {
  const [config, setConfig] = useState<
    IntersectionObserverInit | undefined | null
  >({
    root: null,
    rootMargin: '10px',
    threshold: 0,
    ...(initConfig ?? {}),
  });

  if (initConfig.root !== config?.root) {
    setConfig({
      root: initConfig.root,
      rootMargin: '10px',
      threshold: 0,
      ...(initConfig ?? {}),
    });
  }

  const [target, setTarget] = useState<HTMLElement | undefined | null>(
    undefined,
  );

  const observer = useMemo(() => {
    if (onVisibilityChange && config)
      return new IntersectionObserver(onVisibilityChange, config);
  }, [config, onVisibilityChange]);

  useEffect(() => {
    if (observer && target) observer.observe(target);
    return () => observer?.disconnect();
  }, [target, observer]);

  return { observer, config, setTarget, setConfig, target };
};
