import { useCallback, useContext, useMemo } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import { IconSettings } from '@tabler/icons-react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Emoji } from 'emoji-picker-react';
import { t } from 'i18next';


import { useCollapsibleDrawer } from '@/contexts/CollapsibleDrawer';
import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { INSIGHTS_TYPE, USER_ROLE } from '@/types/enums';
import { TWorkloadData } from '@/types/insights';

import Button from '@/components/Button';
import DrawerCard from '@/components/DrawerCard';
import { ContentPages } from '@/components/Modals/ModalUserProfile';
import WorkloadSkeleton from '@/components/skeleton/WorkloadSkeleton';

import styles from './styles.module.css';




const weekText: string[] = ['one', 'two', 'three', 'four', 'five'];

export default function WorkloadDrawer() {
  const { drawerIsOpen } = useContext(UIContext);
  const { isCollapsed } = useCollapsibleDrawer('workload-widget');
  const { workspace } = useContext(UserContext);

  const { data: workloadData, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.WORKLOAD,
    enabled: drawerIsOpen,
  });

  const slicedWorkloadData = useMemo(
    () => workloadData?.data?.slice(0, 6) ?? [],
    [workloadData],
  );

  const thisWeekWorkload = useMemo(
    () => workloadData?.data?.[0],
    [workloadData],
  );

  const { openModal } = useProfileModal();

  const renderWorkloadBar = useCallback(
    (workloadData: TWorkloadData, index: number) => {
      return (
        <Tooltip.Root key={`${workloadData.workloadPref.name}_${index}`}>
          <Tooltip.Trigger asChild>
            <button className={styles.barTrigger}>
              <div
                key={index}
                className={classNames(styles.bar, {
                  [styles.thisWeek]: index === 0,
                  [styles[`shade-${workloadData.workloadPref.color}`]]: true,
                })}
              />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="TooltipContent" sideOffset={5}>
              <div className={styles.tooltipContainer}>
                <div className={styles.date}>
                  {`${workloadData.startDate.getDate()}-${workloadData.endDate.getDate()} ${format(workloadData.endDate, 'MMM')}`}
                </div>
                <div className={styles.description}>
                  <Emoji
                    unified={workloadData.workloadPref?.emoji ?? ''}
                    size={20}
                  />
                  <span>
                    {`${workloadData.workloadPref.name} · ${workloadData.workload}%`}
                  </span>
                </div>
              </div>
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      );
    },
    [],
  );

  const consecutiveWeeks: number = useMemo(() => {
    if (workloadData?.data?.length) {
      const [
        {
          workloadPref: { name: thisWeekStatus },
        },
        ...others
      ] = workloadData.data;
      let weekCounter = 0;
      for (const { workloadPref } of others) {
        if (workloadPref.name === thisWeekStatus) weekCounter++;
        else break;
      }
      return weekCounter;
    }
    return 0;
  }, [workloadData]);

  const consecutiveWeeksText: string | undefined = useMemo(() => {
    if (consecutiveWeeks === 0) {
      return t(`common:insights.forThisWeek`);
    } else if (consecutiveWeeks > 5) {
      return `${t(`common:insights.workloadWeekCounter`, {
        weeks: weekText[4],
      })} ${t('common:andBeyond')}`;
    }
    return (
      t(`common:insights.workloadWeekCounter`, {
        weeks: weekText[consecutiveWeeks - 1],
      }) +
      ' ' +
      t(`common:week${consecutiveWeeks > 1 ? 's' : ''}`)
    );
  }, [consecutiveWeeks]);

  return (
    <DrawerCard
      isLoading={isLoading}
      className={!isCollapsed ? styles.workloadDrawer : ''}
      draweId={'workload-widget'}
      title={t('common:insights.workload')}
      loader={<WorkloadSkeleton />}
      rightTitle={
        workspace?.role === USER_ROLE.OWNER ? (
          <Button
            icon={IconSettings}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              openModal?.(ContentPages.SETTINGS);
            }}
          />
        ) : undefined
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <div className={styles.headerContainer}>
            <div className={styles.headerEmoji}>
              <Emoji
                unified={thisWeekWorkload?.workloadPref?.emoji ?? ''}
                size={32}
              />
            </div>
            <span className={styles.headerTitle}>
              {thisWeekWorkload?.workloadPref?.name}
            </span>
            <span className={styles.headerPerc}>
              {thisWeekWorkload?.workload} %
            </span>
          </div>
          <div className={styles.subHeader}>
            <span>{consecutiveWeeksText}</span>
          </div>
        </div>
        <div className={styles.chartBarsContainer}>
          <Tooltip.Provider delayDuration={0}>
            {slicedWorkloadData?.map(renderWorkloadBar)}
          </Tooltip.Provider>
        </div>
      </div>
    </DrawerCard>
  );
}
