import { useContext } from 'react';

import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { updateAllocationDetail } from '@/services/api/allocations/allocations';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TTimeBlockRange } from '@/types/timeline';


type Props = {
  note?: string;
  allocationId: string;
};

export default function useUpdateAllocationDetails({
  onSuccess,
  onError,
}: Exclude<TSuccessErrorHook<TTimeBlockRange | undefined>, 'enabled'> = {}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<TTimeBlockRange | undefined, TResponseError, Props>(
    async ({ note, allocationId }) => {
      return updateAllocationDetail({
        workspaceId,
        allocationId,
        note,
      });
    },
    {
      onSuccess,
      onError: (_error: TResponseError) => {
        onError?.(_error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            _error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
