import { PropsWithChildren } from 'react';

import { TTimelineHiddenProject } from '@/types/timeline';

import Block from './Block';
import Content from './Content';
import HiddenRowContext from './Context';
import Dot from './Dot';
import Header from './Header';
import styles from './styles.module.css';

type Props = {
  projects: TTimelineHiddenProject[];
  resourceId: string;
  count: number;
};
const HiddenRow = ({
  projects,
  children,
  count,
  resourceId,
}: PropsWithChildren<Props>) => {
  return (
    <HiddenRowContext.Provider value={{ resourceId, count, projects }}>
      <div className={styles.container}>{children}</div>
    </HiddenRowContext.Provider>
  );
};

HiddenRow.Block = Block;
HiddenRow.Dot = Dot;
HiddenRow.Header = Header;
HiddenRow.Content = Content;

export default HiddenRow;
