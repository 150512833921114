import Button from '@components/Button';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { TAttachmentDataUrls } from '@/types/generic';

import styles from './styles.module.css';
import Avatar from '../Avatar';




type Props = {
  item: TAttachmentDataUrls;
  name: string;
  onDelete: (id: string) => void;
};
export default function FileItem({ item, onDelete, name }: Props) {
  const { t } = useTranslation(['commons, editor']);

  const src = item?.ref ?? item?.url;

  return (
    <div className={styles.container}>
      <Avatar size="large" image={src} name={name} />
      <div className={styles.wrapper}>
        {!isEmpty(item?.ref) && (
          <div>
            <Button
              label={t('common:removeImage')}
              onClick={() => onDelete(item?.id)}
              variant="outline"
              size="medium"
            />
          </div>
        )}
      </div>
    </div>
  );
}
