import { useContext, useMemo } from 'react';

import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { filter, find, map } from 'lodash';
import { isMobile } from 'react-device-detect';

import { UserContext } from '@/contexts/UserContext';
import { PROJECT_TYPE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TTimeBlockRange } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import BlockWrap from '@/components/Timelines/TimelineResources/BlockWrap';
import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import { useProjectRowContext } from './Context';




export default function Blocks() {
  const { project, resource, onActiveBlockFn } = useProjectRowContext();
  const { virtualizer, weeks, activeBlockIds, selectedBlockId } = useContext(
    TimelineResourcesContext,
  );

  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const visibleWeeks = virtualizer.getVirtualItems();
  const visibleBlocks = useMemo(() => {
    const startDate = startOfDay(weeks[visibleWeeks[0].index]);
    const endDate = endOfDay(
      weeks[visibleWeeks[visibleWeeks.length - 1].index],
    );
    return filter(
      project?.timeblocks,
      (block: TTimeBlockRange) =>
        isWithinInterval(block.start, {
          start: startDate,
          end: endDate,
        }) ||
        isWithinInterval(block.end, {
          start: startDate,
          end: endDate,
        }) ||
        (isBefore(block.start, startDate) && isAfter(block.end, endDate)) ||
        (selectedBlockId && selectedBlockId === block.id),
    );
  }, [project?.timeblocks, visibleWeeks, weeks, selectedBlockId]);
  return map(visibleBlocks, (block: TTimeBlockRange) => {
    const id = `${resource?.id}-${project?.id}-${format(
      block.start,
      'yyyy-MM-dd',
    )}-${format(block.end, 'yyyy-MM-dd')}`;

    return (
      <BlockWrap
        key={block.id}
        projectColor={project?.color ?? ''}
        isActive={activeBlockIds?.includes(id)}
        onClick={onActiveBlockFn}
        resourceId={resource?.id ?? ''}
        projectId={project?.id ?? ''}
        projectStatus={project?.status}
        isTimeoff={project?.type === PROJECT_TYPE.TIME_OFF}
        projectName={project?.name ?? ''}
        emoji={project?.emoji}
        block={block}
      >
        {!isMobile &&
          workspace?.permission ===
            WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
            <BlockWrap.Handle type="left" />
          )}
        {!isMobile && <BlockWrap.Tooltip />}
        <BlockWrap.Content />
        {!isMobile &&
          workspace?.permission ===
            WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
            <BlockWrap.Handle type="right" />
          )}
      </BlockWrap>
    );
  });
}
