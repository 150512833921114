import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';


import { NotificationsContext } from '@/contexts/NotificationContext';
import { updateWorkspace } from '@/services/api/workspace/workspace';
import { TResponseError } from '@/types/generic';
import { TUpdateWorkspaceBody } from '@/types/workspace';

import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';

type UpdateWorkspaceOptions = {
  onSuccess?: typeof noop;
  onError?: typeof noop;
  onMutate?: typeof noop;
};

export default function useUpdateWorkspace({
  onSuccess = noop,
  onError = noop,
  onMutate = noop,
}: UpdateWorkspaceOptions = {}) {
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, TResponseError, TUpdateWorkspaceBody>(
    async (option: TUpdateWorkspaceBody) => {
      await updateWorkspace(option);
    },
    {
      onError: (error) => {
        onError();
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
        onSuccess?.();
      },
      onMutate,
    },
  );
}
