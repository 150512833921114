import { useContext, useEffect, useMemo, useState } from 'react';

import { IconCalendar, IconClock } from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { useNavigate } from 'react-router';


import {
  ONBOARDING_STATUS,
  useOnboardingContext,
} from '@/contexts/OnboardingContext';
import { UserContext } from '@/contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useUpdateWorkspace from '@/hooks/workspace/useUpdateWorkspace';
import { titleCase } from '@/services/helpers';
import { WORKSPACE_MODE } from '@/types/enums';
import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';
import ContentSwitcher from '@/components/ContentSwitcher';

import avatar_1 from '@/assets/avatar_1.svg';
import avatar_2 from '@/assets/avatar_2.svg';
import avatar_3 from '@/assets/avatar_3.svg';

import styles from './styles.module.css';




export default function WorkspaceTimeModeScreen() {
  const { workspace } = useContext(UserContext);
  const { status, navigateNextStep, setNextStatus } = useOnboardingContext();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  useEffect(() => {
    if (!status) {
      navigate(ROUTES.ROOT, {
        preventScrollReset: false,
        replace: true,
      });
    } else if (status === ONBOARDING_STATUS.ADD_MEMBER) {
      navigateNextStep();
    }
  }, [navigate, navigateNextStep, status]);

  const [mode, setMode] = useState<WORKSPACE_MODE>(
    workspace?.mode ?? WORKSPACE_MODE.DAYS,
  );

  const { mutate: updateWorkspace, isLoading } = useUpdateWorkspace({
    onSuccess: () => {
      if (workspace?.id && mode === WORKSPACE_MODE.HOURS) {
        trackEvent(ANALYTICS_EVENTS.SWITCHED_TIME_MODE, workspace?.id, {
          TIME_MODE: 'HOURS_PER_WEEK',
          TOUCH_POINT: 'ONBOARDING',
        });
      }
      setNextStatus();
    },
  });

  const updateModeSetting = () => {
    if (workspace) {
      updateWorkspace({
        workspaceId: workspace.id,
        mode: mode,
      });
    }
  };

  const data = useMemo(() => {
    const [baseUnit, shortUnit] =
      mode === WORKSPACE_MODE.DAYS ? ['days', 'd'] : ['hours', 'h'];

    return [
      {
        logo: avatar_1,
        content: [
          {
            name: 'Web application',
            value: 5,
            cls: styles.webApplication,
            unit: `${baseUnit} per week`,
          },
        ],
      },
      {
        logo: avatar_2,
        content: [
          {
            name: 'Corporate website',
            value: 4,
            cls: styles.corporateWebsite,
            unit: `${baseUnit} per week`,
          },
          {
            name: 'Corporate website',
            value: 4,
            unit: `${shortUnit}/w`,
            cls: styles['corporateWebsite--2'],
          },
        ],
      },
      {
        logo: avatar_3,
        content: [
          {
            name: 'Fashion e-commerce',
            value: 3,
            cls: styles.eCommerce,
            unit: `${baseUnit} per week`,
          },
        ],
      },
    ];
  }, [mode]);

  const timeModes = useMemo(() => {
    return [
      {
        modeId: WORKSPACE_MODE.DAYS,
        modeKey: titleCase(WORKSPACE_MODE.DAYS),
        modeIcon: <IconCalendar width={24} />,
      },
      {
        modeId: WORKSPACE_MODE.HOURS,
        modeKey: titleCase(WORKSPACE_MODE.HOURS),
        modeIcon: <IconClock width={24} />,
      },
    ];
  }, []);

  return (
    <div className={styles.content__main}>
      <div className={styles.tilte__section}>
        <h1 className={styles.title}>
          {t('pages:onboarding.workspaceTimeMode.title')}
        </h1>
        <p className={styles.subtitle}>
          {t('pages:onboarding.workspaceTimeMode.subTitle')}
        </p>
      </div>
      <div className={styles.body}>
        <div className={styles.selectorList}>
          {timeModes.map(({ modeId, modeKey, modeIcon }) => (
            <ContentSwitcher
              key={modeKey}
              modeKey={modeKey}
              checked={modeId === mode}
              Icon={modeIcon}
              onClick={() => {
                setMode(modeId);
              }}
            />
          ))}
        </div>
        <div className={styles.features}>
          {[...Array(6).keys()].map((index, idx) => (
            <div
              key={`separator_${index}`}
              className={styles.separator}
              style={{ left: `calc(((100% / 7) * ${idx + 1}) - 1px)` }}
            ></div>
          ))}
          <div className={styles.features__body}>
            {data.map((item, index) => (
              <div key={index} className={styles.features__body__row}>
                <img
                  style={{ alignSelf: 'center' }}
                  src={item.logo}
                  alt={`avatar_${index + 1}`}
                />
                {item.content.map((content, index) => (
                  <div
                    key={index}
                    className={classNames(styles.timelineBlock, content.cls)}
                  >
                    <p className={styles.content__title}>{content.name}</p>
                    <p>
                      {mode === WORKSPACE_MODE.DAYS
                        ? content.value
                        : content.value * 8}{' '}
                      {content.unit}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <span className={styles.description}>
          {t(`pages:onboarding.workspaceTimeMode.description${mode}`)}
        </span>
      </div>
      <Button
        label={t('forms:workspace.create.submit') ?? ''}
        isLoading={isLoading}
        size="large"
        onClick={() => {
          updateModeSetting();
        }}
      />
    </div>
  );
}
