import { useCallback, useContext, useEffect, useState } from 'react';

import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { t } from 'i18next';
import { first } from 'lodash';
import { useNavigate } from 'react-router';
import { ClipLoader } from 'react-spinners';


import {
  ONBOARDING_STATUS,
  useOnboardingContext,
} from '@/contexts/OnboardingContext';
import { UserContext } from '@/contexts/UserContext';
import useCreateWorkspace from '@/hooks/workspace/useCreateWorkspace';
import { ROUTES } from '@/types/routes';
import { TUserAccount, TUserWorkspace } from '@/types/workspace';

import BoxMessage from '@/components/BoxMessage';
import CreateWorkspaceForm, {
  CreateWorkspaceFormData,
} from '@/components/CreateWorkspaceForm';

import styles from './styles.module.css';




export default function WorkspaceCreateScreen() {
  const navigate = useNavigate();
  const { status, setNextStatus, navigateNextStep } = useOnboardingContext();
  const [plnWs, setPlnWs] = useLocalStorage<string | undefined>(
    `pln-ws`,
    undefined,
  );

  const { isUserEmailVerified, setWorkspaceId, workspace, account } =
    useContext(UserContext);

  useEffect(() => {
    if (!plnWs && (account?.workspaces?.length ?? 0) > 0) {
      const firstWorkspace = first(account?.workspaces) as TUserWorkspace;
      if (firstWorkspace?.id) {
        setPlnWs(firstWorkspace?.id);
        setWorkspaceId(firstWorkspace?.id);
      }
    }
  }, [
    plnWs,
    account?.workspaces?.length,
    account?.workspaces,
    setPlnWs,
    setWorkspaceId,
  ]);

  useEffect(() => {
    setNextStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (workspace) {
      return navigate(ROUTES.ROOT, {
        preventScrollReset: false,
        replace: true,
      });
    }
  }, [isUserEmailVerified, navigate, status, workspace]);

  const [_error, setError] = useState<string>('');

  useEffect(() => {
    if (status && status === ONBOARDING_STATUS.SET_MODE) {
      navigateNextStep && navigateNextStep();
    }
  }, [navigateNextStep, status]);

  const {
    mutate,
    isLoading,
    error: err,
  } = useCreateWorkspace({
    onSuccess(data: TUserAccount) {
      const firstWorkspace = first(data?.workspaces) as TUserWorkspace;
      setWorkspaceId(firstWorkspace?.id);
      setNextStatus && setNextStatus();
    },
  });

  const onSubmitFn = useCallback(
    async (formData: CreateWorkspaceFormData) => {
      try {
        mutate({ name: formData.name });
      } catch (error) {
        setError('Something went wrong or workspace already exists');
      }
    },
    [mutate],
  );

  return isUserEmailVerified == null || isUserEmailVerified == undefined ? (
    <ClipLoader />
  ) : (
    <div className={styles.contentMain}>
      <h1 className={styles.title}>{t('forms:workspace.create.title')}</h1>
      {err && (
        <BoxMessage variant="error">{t('errors:generic.workspace')}</BoxMessage>
      )}
      <div className={styles['form-wrapper']}>
        <CreateWorkspaceForm onSubmitFn={onSubmitFn} isLoading={isLoading} />
      </div>
    </div>
  );
}
