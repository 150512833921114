import * as React from 'react';

import { useLocalStorage } from 'use-hooks';

export enum THEMES {
  SYSTEM = 'system',
  DARK = 'dark',
  LIGHT = 'light',
}

const MATCH_QUERY = '(prefers-color-scheme: dark)';

function getTheme() {
  if (window.matchMedia(MATCH_QUERY).matches) {
    return THEMES.DARK;
  } else {
    return THEMES.LIGHT;
  }
}

export default function useThemeSwitcher() {
  const [localThemeSetting, setLocalThemeSetting] = useLocalStorage<THEMES>(
    'theme',
    THEMES.SYSTEM,
  );

  const [theme, setTheme] = React.useState<THEMES.DARK | THEMES.LIGHT>(
    THEMES.LIGHT,
  );

  const changeThemeStatus = React.useCallback(
    (themeUpdated: THEMES.DARK | THEMES.LIGHT) => {
      setTheme(themeUpdated);
    },
    [setTheme],
  );

  const handleThemeSetting = React.useCallback(() => {
    changeThemeStatus(getTheme());
  }, [changeThemeStatus]);

  React.useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', theme);
  }, [theme, localThemeSetting]);

  React.useEffect(() => {
    if (localThemeSetting === THEMES.SYSTEM) {
      window
        .matchMedia(MATCH_QUERY)
        .addEventListener('change', handleThemeSetting);
      setTheme(getTheme());
    } else {
      setTheme(localThemeSetting);
    }
    return () =>
      window
        .matchMedia(MATCH_QUERY)
        .removeEventListener('change', handleThemeSetting);
  }, [handleThemeSetting, localThemeSetting, theme]);

  return {
    themeSetting: localThemeSetting,
    theme,
    setThemeDetection: setLocalThemeSetting,
  };
}
