import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import styles from './styles.module.css';
export default function LogoHeader() {
  const isMdDevice = useMediaQuery('(min-width: 992px)');
  return (
    <div className={styles.container}>
      {isMdDevice ? (
        <svg
          width="120"
          height="24"
          viewBox="0 0 120 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.82697 20.9308C8.81682 20.9984 10.0952 21 12 21C13.9048 21 15.1832 20.9984 16.173 20.9308C17.1359 20.8651 17.6048 20.7472 17.9134 20.6194C19.1386 20.1119 20.1119 19.1386 20.6194 17.9134C20.7472 17.6048 20.8651 17.1359 20.9308 16.173C20.9984 15.1832 21 13.9048 21 12C21 10.0952 20.9984 8.81682 20.9308 7.82697C20.8651 6.86406 20.7472 6.3952 20.6194 6.08658C20.1119 4.86144 19.1386 3.88807 17.9134 3.3806C17.6048 3.25277 17.1359 3.13486 16.173 3.06916C15.1832 3.00162 13.9048 3 12 3C10.0952 3 8.81682 3.00162 7.82697 3.06916C6.86406 3.13486 6.3952 3.25277 6.08658 3.3806C4.86144 3.88807 3.88807 4.86144 3.3806 6.08658C3.25277 6.3952 3.13486 6.86406 3.06916 7.82697C3.00162 8.81682 3 10.0952 3 12C3 13.9048 3.00162 15.1832 3.06916 16.173C3.13486 17.1359 3.25277 17.6048 3.3806 17.9134C3.88807 19.1386 4.86144 20.1119 6.08658 20.6194C6.3952 20.7472 6.86406 20.8651 7.82697 20.9308ZM0.608964 4.93853C0 6.4087 0 8.27247 0 12C0 15.7275 0 17.5913 0.608964 19.0615C1.42092 21.0217 2.97831 22.5791 4.93853 23.391C6.4087 24 8.27247 24 12 24C15.7275 24 17.5913 24 19.0615 23.391C21.0217 22.5791 22.5791 21.0217 23.391 19.0615C24 17.5913 24 15.7275 24 12C24 8.27247 24 6.4087 23.391 4.93853C22.5791 2.97831 21.0217 1.42092 19.0615 0.608964C17.5913 0 15.7275 0 12 0C8.27247 0 6.4087 0 4.93853 0.608964C2.97831 1.42092 1.42092 2.97831 0.608964 4.93853Z"
            fill="#151619"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3398 13.7642L5.60472 12.1859L6.39528 9.81415L11.1304 11.3925C11.6949 11.5807 12.3051 11.5807 12.8696 11.3925L17.6047 9.81415L18.3953 12.1859L13.6602 13.7642C12.5825 14.1234 11.4175 14.1234 10.3398 13.7642Z"
            fill="#151619"
          />
          <path
            d="M34.2712 4.135V10.713H34.3152C35.0192 9.635 36.1192 9.041 37.5712 9.041C39.8152 9.041 41.3992 10.339 41.3992 13.243V19.535H38.0772V14.167C38.0772 12.649 37.4392 11.967 36.2292 11.967C35.1072 11.967 34.2712 12.627 34.2712 14.431V19.535H30.9492V4.135H34.2712Z"
            fill="#151619"
          />
          <path
            d="M48.1417 9.063C51.1997 9.063 53.1797 10.823 53.1797 14.497V15.245H45.8977C46.1177 16.521 47.1297 17.093 48.4937 17.093C49.7257 17.093 50.8037 16.587 51.4417 16.169L52.6517 18.677C51.5737 19.337 50.1877 19.865 48.4497 19.865C45.0397 19.865 42.7737 17.731 42.7737 14.497C42.7737 11.065 45.0177 9.063 48.1417 9.063ZM48.0317 11.417C46.9097 11.417 46.0737 11.967 45.9197 13.287H49.9677C49.9237 12.099 49.1097 11.417 48.0317 11.417Z"
            fill="#151619"
          />
          <path d="M57.904 4.135V19.535H54.582V4.135H57.904Z" fill="#151619" />
          <path
            d="M63.2751 4.135V19.535H59.9531V4.135H63.2751Z"
            fill="#151619"
          />
          <path
            d="M70.4502 9.041C73.7502 9.041 76.1702 11.197 76.1702 14.453C76.1702 17.709 73.7502 19.865 70.4502 19.865C67.1722 19.865 64.7522 17.709 64.7522 14.453C64.7522 11.197 67.1722 9.041 70.4502 9.041ZM70.4502 12.077C69.1302 12.077 68.0742 13.001 68.0742 14.453C68.0742 15.905 69.1302 16.829 70.4502 16.829C71.7922 16.829 72.8262 15.905 72.8262 14.453C72.8262 13.001 71.7922 12.077 70.4502 12.077Z"
            fill="#151619"
          />
          <path
            d="M83.8046 12.143H81.7146V15.201C81.7146 16.147 81.9566 16.455 82.8146 16.455H83.1886V19.535H81.8026C79.7346 19.535 78.3926 18.545 78.3926 16.103V12.143H76.4126V9.371H78.3926V5.301H81.7146V9.371H83.8046V12.143Z"
            fill="#151619"
          />
          <path
            d="M88.6293 4.157V7.501H85.0653V4.157H88.6293ZM85.1973 19.535V9.371H88.5193V19.535H85.1973Z"
            fill="#151619"
          />
          <path
            d="M90.5464 19.535V9.371H93.8024V10.867H93.8464C94.4184 9.745 95.5184 9.041 97.0144 9.041C98.5764 9.041 99.6764 9.789 100.204 11.021H100.248C100.952 9.767 102.162 9.041 103.658 9.041C105.77 9.041 107.288 10.449 107.288 12.913V19.535H103.966V14.013C103.966 12.737 103.482 11.967 102.316 11.967C101.304 11.967 100.512 12.583 100.512 14.497V19.535H97.3223V14.013C97.3223 12.737 96.8384 11.967 95.6724 11.967C94.6604 11.967 93.8684 12.583 93.8684 14.497V19.535H90.5464Z"
            fill="#151619"
          />
          <path
            d="M114.013 9.063C117.071 9.063 119.051 10.823 119.051 14.497V15.245H111.769C111.989 16.521 113.001 17.093 114.365 17.093C115.597 17.093 116.675 16.587 117.313 16.169L118.523 18.677C117.445 19.337 116.059 19.865 114.321 19.865C110.911 19.865 108.645 17.731 108.645 14.497C108.645 11.065 110.889 9.063 114.013 9.063ZM113.903 11.417C112.781 11.417 111.945 11.967 111.791 13.287H115.839C115.795 12.099 114.981 11.417 113.903 11.417Z"
            fill="#151619"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.82697 20.9308C8.81682 20.9984 10.0952 21 12 21C13.9048 21 15.1832 20.9984 16.173 20.9308C17.1359 20.8651 17.6048 20.7472 17.9134 20.6194C19.1386 20.1119 20.1119 19.1386 20.6194 17.9134C20.7472 17.6048 20.8651 17.1359 20.9308 16.173C20.9984 15.1832 21 13.9048 21 12C21 10.0952 20.9984 8.81682 20.9308 7.82697C20.8651 6.86406 20.7472 6.3952 20.6194 6.08658C20.1119 4.86144 19.1386 3.88807 17.9134 3.3806C17.6048 3.25277 17.1359 3.13486 16.173 3.06916C15.1832 3.00162 13.9048 3 12 3C10.0952 3 8.81682 3.00162 7.82697 3.06916C6.86406 3.13486 6.3952 3.25277 6.08658 3.3806C4.86144 3.88807 3.88807 4.86144 3.3806 6.08658C3.25277 6.3952 3.13486 6.86406 3.06916 7.82697C3.00162 8.81682 3 10.0952 3 12C3 13.9048 3.00162 15.1832 3.06916 16.173C3.13486 17.1359 3.25277 17.6048 3.3806 17.9134C3.88807 19.1386 4.86144 20.1119 6.08658 20.6194C6.3952 20.7472 6.86406 20.8651 7.82697 20.9308ZM0.608964 4.93853C0 6.4087 0 8.27247 0 12C0 15.7275 0 17.5913 0.608964 19.0615C1.42092 21.0217 2.97831 22.5791 4.93853 23.391C6.4087 24 8.27247 24 12 24C15.7275 24 17.5913 24 19.0615 23.391C21.0217 22.5791 22.5791 21.0217 23.391 19.0615C24 17.5913 24 15.7275 24 12C24 8.27247 24 6.4087 23.391 4.93853C22.5791 2.97831 21.0217 1.42092 19.0615 0.608964C17.5913 0 15.7275 0 12 0C8.27247 0 6.4087 0 4.93853 0.608964C2.97831 1.42092 1.42092 2.97831 0.608964 4.93853Z"
            fill="#151619"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3398 13.7642L5.60472 12.1859L6.39528 9.81415L11.1304 11.3925C11.6949 11.5807 12.3051 11.5807 12.8696 11.3925L17.6047 9.81415L18.3953 12.1859L13.6602 13.7642C12.5825 14.1234 11.4175 14.1234 10.3398 13.7642Z"
            fill="#151619"
          />
        </svg>
      )}
    </div>
  );
}
