import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getFeatureFlags } from '@/services/api/workspace/workspace';
import {
  TSuccessErrorHook,
  TFeatureFlags,
  TResponseError,
} from '@/types/generic';


export const KEY = 'get-feature-flags';
export default function useFeatureFlags({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TFeatureFlags[]>) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery([KEY, workspaceId], () => getFeatureFlags({ workspaceId }), {
    enabled: enabled && !!workspaceId,
    onError: (error: TResponseError) => {
      onError(error);
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
    onSuccess,
  });
}
