import { useContext, useMemo } from 'react';

import {
  endOfDay,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { filter, map } from 'lodash';

import { TTimelineHiddenProject } from '@/types/timeline';

import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import { useProjectHideRowContext } from './Context';
import HiddenDot from '../HiddenDot';


export default function Dot() {
  const { projects } = useProjectHideRowContext();
  const { virtualizer, weeks } = useContext(TimelineResourcesContext);
  const visibleWeeks = virtualizer.getVirtualItems();
  const visibleBlocks = useMemo(() => {
    const startDate = startOfDay(weeks[visibleWeeks[0].index]);
    const endDate = endOfDay(
      weeks[visibleWeeks[visibleWeeks.length - 1].index],
    );
    return filter(
      projects,
      (block: TTimelineHiddenProject) =>
        isWithinInterval(block.start, {
          start: startDate,
          end: endDate,
        }) ||
        isWithinInterval(block.end, {
          start: startDate,
          end: endDate,
        }) ||
        (isBefore(block.start, startDate) && isAfter(block.end, endDate)),
    );
  }, [projects, visibleWeeks, weeks]);
  return map(visibleBlocks, (block: TTimelineHiddenProject, key: number) => {
    return <HiddenDot key={key} block={block} />;
  });
}
