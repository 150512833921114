import React, { useCallback, useEffect, useState } from 'react';

import Section from '@components/Section';
import { IconSparkles } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';

import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { useUpdateAutoHide } from '@/hooks/workspace/useUpdateAutoHide';
import { useUpdateAutoSync } from '@/hooks/workspace/useUpdateAutoSync';
import useUpdateWorkspace from '@/hooks/workspace/useUpdateWorkspace';
import { useWorkspaceFromContext } from '@/hooks/workspace/useWorkspaceFromContext';
import { getCssVariable } from '@/services/helpers';
import { WORKSPACE_MODE } from '@/types/enums';

import SelectDropdown from '@/components/SelectDropdown';
import Switch from '@/components/Switch';

import WorkloadPreferences from './WorkloadPreferences';
import styles from './styles.module.css';

export type UpdateWorkspaceDataForm = {
  workspaceName: string;
};

export default function ModalWorkspaceSettingContent() {
  const { trackEvent } = useAnalytics();

  const { setValue } = useForm<UpdateWorkspaceDataForm>({ mode: 'onSubmit' });

  const queryClient = useQueryClient();
  const { workspace } = useWorkspaceFromContext();

  const { mutate: updateMode } = useUpdateWorkspace({
    onSuccess: () => queryClient.invalidateQueries(),
  });
  useEffect(() => {
    setValue('workspaceName', workspace?.name ?? '');
  }, [setValue, workspace?.name]);

  const [mode, setMode] = useState<WORKSPACE_MODE>(
    workspace?.mode ?? WORKSPACE_MODE.DAYS,
  );

  const iconColor = getCssVariable('--icon-caution');

  const onHandleSelectChange = useCallback(
    (newMode: WORKSPACE_MODE) => {
      if (workspace?.id) {
        setMode(newMode);
        trackEvent(ANALYTICS_EVENTS.SWITCHED_TIME_MODE, workspace?.id, {
          TIME_MODE: newMode,
          TOUCH_POINT: 'SETTINGS',
        });
        updateMode({
          workspaceId: workspace?.id ?? '',
          mode: newMode,
        });
      }
    },
    [updateMode, trackEvent, workspace?.id],
  );

  const { mutate: updateAutoSync } = useUpdateAutoSync();
  const { mutate: updateAutoHide } = useUpdateAutoHide();

  return (
    <React.Fragment>
      <Section title={t(`forms:workspace.settings.timeMode.sectionTitle`)}>
        <div className={styles.mainContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(`forms:workspace.settings.timeMode.label`)}
              </div>
              <div className={styles.rowDescription}>
                {t(`forms:workspace.settings.timeMode.subLabel`)}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <SelectDropdown label={t(`common:${mode}PerWeek`)} align="end">
                <SelectDropdown.Item
                  label={t(`common:${WORKSPACE_MODE.DAYS}PerWeek`)}
                  subLabel="Works best with full-time people on your team"
                  checked={mode === WORKSPACE_MODE.DAYS}
                  onSelect={() => onHandleSelectChange(WORKSPACE_MODE.DAYS)}
                />
                <SelectDropdown.Item
                  label={t(`common:${WORKSPACE_MODE.HOURS}PerWeek`)}
                  subLabel="Works best with part-time people on your team"
                  checked={mode === WORKSPACE_MODE.HOURS}
                  onSelect={() => onHandleSelectChange(WORKSPACE_MODE.HOURS)}
                />
              </SelectDropdown>
            </div>
          </div>
        </div>
      </Section>
      <Section
        title={
          <div className={styles.automationSectionHeader}>
            <IconSparkles size={24} color={iconColor} fill={iconColor} />
            {'Automations'}
          </div>
        }
      >
        <div className={styles.mainContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoSync.label',
                )}
              </div>
              <div className={styles.rowDescription}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoSync.description',
                )}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <Switch
                id={'autoSync'}
                checked={!!workspace?.autoSync}
                onChange={updateAutoSync}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoHide.label',
                )}
              </div>
              <div className={styles.rowDescription}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoHide.description',
                )}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <Switch
                id={'autoHide'}
                checked={workspace?.autoHide ?? false}
                onChange={updateAutoHide}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.linkWrapper}>
              <a
                href={import.meta.env.VITE_BLOG_URL}
                target="_blank"
                rel="noreferrer"
              >
                <u>
                  {t(
                    'common:profileModal.workspaceSettings.manageAutomation.linkHighlightedText',
                  )}
                </u>
              </a>
              <span>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.linkNormalText',
                )}
              </span>
            </div>
          </div>
        </div>
      </Section>
      <Section title={'Workload'}>
        <WorkloadPreferences />
      </Section>
    </React.Fragment>
  );
}
