import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  differenceInWeeks,
  endOfWeek,
  isBefore,
  setDefaultOptions,
  subDays,
  subWeeks,
} from 'date-fns';
import { noop } from 'lodash';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { generateUUID, linearMap, weeksToPixels } from '@/services/helpers';
import { updateBlocksWithProperEvents } from '@/services/helpers/timelines/resources';
import {
  ALLOCATION_EVENT_OPERATION_TYPE,
  PROJECT_STATUS,
  WORKSPACE_MODE,
} from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import {
  TProjectListWithResources,
  TResourceItemList,
  TTimeBlockRange,
} from '@/types/timeline';

import { useResourceRowContext } from '@/components/Timelines/TimelineProjects/ResourceRow/Context';
import { TimelineProjectsContext } from '@/components/Timelines/TimelineProjects/context';

import Content from './Content';
import BlockContext, { MouseEventRef } from './Context';
import Handle from './Handle';
import Tooltip from './Tooltip';
import styles from './styles.module.css';
import ScrollDraggingOverlay from '../DraggingOverlay';
import { ProjectStatusContext } from '../ProjectStatusWrapper/ProjectStatusContext';


export type Props = {
  resourceId: string;
  projectId: string;
  currentProject: TProjectListWithResources<TResourceItemList>;
  projectColor: string;
  projectStatus?: PROJECT_STATUS;
  onClick: ({
    blockId,
    isMulti,
    projectId,
    resourceId,
  }: {
    blockId: string;
    isMulti: boolean;
    projectId: string;
    resourceId: string;
  }) => void;
  block: TTimeBlockRange;
  resourceName: string;
  isActive?: boolean;
  avatar?: TAttachmentDataUrls;
};

const BlockWrap = ({
  children,
  block,
  currentProject,
  resourceId,
  onClick,
  isActive,
  resourceName,
  projectStatus,
  avatar,
}: PropsWithChildren<Props>) => {
  setDefaultOptions({ weekStartsOn: 1 });

  const mouseEventRef = useRef<MouseEventRef>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shiftIsPressed, setShiftIsPressed] = useState(false);
  const [localProps, setLocalProps] = useState(block);
  const [isDragging, setDragging] = useState(false);
  const { onActiveBlockFn, rowDisabled } = useResourceRowContext();

  const {
    timeInterval,
    weekSizeInPx,
    setActiveBlockIds,
    activeBlockIds,
    virtualizer,
    setSelectedBlockId,
  } = useContext(TimelineProjectsContext);

  const { setBlocksToUpdate, updateData } = useContext(ProjectStatusContext);
  const { layoutIsExpanded } = useContext(UIContext);

  const [blockIdForActiveState, setBlockIdForActiveState] =
    useState<string>('');

  const currentResource = useMemo(
    () =>
      currentProject?.resources?.find(
        (r) => r.id === resourceId,
      ) as TResourceItemList,
    [currentProject, resourceId],
  );

  const lengthWeeks = differenceInCalendarWeeks(
    localProps.end,
    localProps.start,
  );

  const startPixels = weeksToPixels(
    localProps.start,
    timeInterval.start,
    false,
    weekSizeInPx,
  );

  const endPixels = weeksToPixels(
    localProps.end,
    timeInterval.start,
    true,
    weekSizeInPx,
  );

  const lengthOfBlockInPx = endPixels - startPixels;

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timerClickRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { workspaceMode = WORKSPACE_MODE.DAYS } = useContext(UserContext);
  useEffect(() => {
    document.addEventListener('keydown', onKeydownFn);
    document.addEventListener('keyup', onKeyupFn);
    return () => {
      document.removeEventListener('keydown', onKeydownFn);
      document.removeEventListener('keyup', onKeyupFn);
    };
  }, []);

  const finalizeData = useCallback(
    (newBlock: TTimeBlockRange) => {
      // Update blocks
      // const newTimeblocks = updateCollidingBlocks(
      //   currentResource?.timeblocks || [],
      //   newBlock,
      // );
      const blocksToUpdate = updateBlocksWithProperEvents(
        currentResource?.timeblocks || [],
        newBlock,
      );

      setBlocksToUpdate({
        events: blocksToUpdate,
        projectId: currentProject?.id,
        resourceId,
      });
    },
    [
      currentResource?.timeblocks,
      setBlocksToUpdate,
      currentProject?.id,
      resourceId,
    ],
  );

  const resizeBlock = useCallback(() => {
    if (rowDisabled) return;

    if (
      mouseEventRef.current.mouseDownX &&
      mouseEventRef.current.targetRect &&
      mouseEventRef.current?.mouseMoveX &&
      mouseEventRef.current.currentProps
    ) {
      const deltaX =
        mouseEventRef.current.mouseDownX - mouseEventRef.current?.mouseMoveX;
      if (
        mouseEventRef.current.mouseDownX !== null &&
        mouseEventRef.current.handle !== null &&
        Math.abs(deltaX) > 2
      ) {
        // allow a little threshold before considering the movement as "resizing"
        document.body.style.cursor = 'col-resize';

        if (mouseEventRef.current.handle === 'left') {
          // Resizing with left handle
          const newPosition =
            mouseEventRef.current.targetRect.left -
            deltaX +
            virtualizer.scrollOffset;
          let newSnapTime = addWeeks(
            timeInterval.start,
            newPosition / weekSizeInPx,
          );
          const newSnapPosition = weeksToPixels(
            newSnapTime,
            timeInterval.start,
            false,
            weekSizeInPx,
          );

          if (newSnapPosition > endPixels - weekSizeInPx) {
            // prevent resizing smaller than minimum width
            newSnapTime = addDays(
              subWeeks(mouseEventRef.current.currentProps.end, 1),
              1,
            );
          }

          if (newSnapTime !== mouseEventRef.current.currentProps.start) {
            // update only when needed
            const updatedProps = {
              ...mouseEventRef.current.currentProps,
              start: newSnapTime,
            };

            mouseEventRef.current.currentProps = updatedProps;
            setLocalProps(updatedProps);
            updateData(updatedProps);
          }
        } else {
          // Resizing with right handle
          const newPosition =
            mouseEventRef.current.targetRect.right -
            deltaX +
            virtualizer.scrollOffset;
          let newSnapTime = addWeeks(
            timeInterval.start,
            newPosition / weekSizeInPx,
          );
          const newSnapPosition = weeksToPixels(
            newSnapTime,
            timeInterval.start,
            false,
            weekSizeInPx,
          );

          if (newSnapPosition < startPixels + weekSizeInPx) {
            // prevent resizing smaller than minimum width
            newSnapTime = subDays(
              addWeeks(mouseEventRef.current.currentProps.start, 1),
              1,
            );
          }

          if (newSnapTime !== mouseEventRef.current.currentProps.end) {
            // update only when needed
            const updatedProps = {
              ...mouseEventRef.current.currentProps,
              end: endOfWeek(newSnapTime),
            };

            mouseEventRef.current.currentProps = updatedProps;
            setLocalProps(updatedProps);
            updateData(updatedProps);
          }
        }
      }
    }
  }, [
    endPixels,
    rowDisabled,
    startPixels,
    timeInterval.start,
    updateData,
    virtualizer.scrollOffset,
    weekSizeInPx,
  ]);

  const moveBlock = useCallback(() => {
    if (rowDisabled) return;

    if (
      mouseEventRef.current.mouseDownX &&
      mouseEventRef.current?.mouseMoveX &&
      mouseEventRef.current.targetRect &&
      mouseEventRef.current.currentProps
    ) {
      const deltaX =
        mouseEventRef.current.mouseDownX - mouseEventRef.current?.mouseMoveX;

      if (mouseEventRef.current.mouseDownX !== null && Math.abs(deltaX) > 2) {
        // allow a little threshold before considering the movement as "dragging"

        // Snap to week
        const newPosition =
          mouseEventRef.current.targetRect.left -
          deltaX +
          virtualizer.scrollOffset;
        const newSnapTime = addWeeks(
          timeInterval.start,
          newPosition / weekSizeInPx,
        );

        // update only when needed
        if (newSnapTime !== mouseEventRef.current.currentProps.start) {
          const newEndTime = endOfWeek(addWeeks(newSnapTime, lengthWeeks));
          const updatedProps = {
            ...mouseEventRef.current.currentProps,
            start: newSnapTime,
            end: newEndTime,
          };

          mouseEventRef.current.currentProps = updatedProps;
          setLocalProps(updatedProps);
          updateData(updatedProps);
        }
      }
    }
  }, [
    lengthWeeks,
    rowDisabled,
    timeInterval.start,
    updateData,
    virtualizer.scrollOffset,
    weekSizeInPx,
  ]);

  const onKeydownFn = (event: KeyboardEvent) => {
    // detect if shift is pressed
    if (event.key === 'Shift') {
      setShiftIsPressed(true);
    }
  };

  const onKeyupFn = () => {
    // detect if shift is pressed
    setShiftIsPressed(false);
  };

  const handleMouseUp = () => {
    document.body.style.cursor = 'default';
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('contextmenu', handleMouseUp);
    document.removeEventListener('mousemove', handleMove);
    if (
      mouseEventRef.current.currentProps &&
      JSON.stringify(mouseEventRef.current.currentProps) !==
        JSON.stringify(localProps)
    ) {
      finalizeData(mouseEventRef.current.currentProps);
    }

    setBlockIdForActiveState(block?.id);
    setSelectedBlockId(null);
    mouseEventRef.current = {};

    onClick({
      blockId: block.id,
      isMulti: false,
      // isMulti: shiftIsPressed,
      projectId: currentProject?.id,
      resourceId: resourceId,
    });
    setDragging(false);
    if (timerClickRef.current) {
      clearTimeout(timerClickRef.current);
    }
  };

  const handleMouseDownBlock = (event: React.MouseEvent) => {
    if (rowDisabled) return;
    if (event.button !== 0) return;
    setSelectedBlockId(block.id);
    timerClickRef.current = setTimeout(() => setDragging(true), 800);
    event.stopPropagation();

    mouseEventRef.current = {
      ...mouseEventRef.current,
      mouseDownX: event.clientX,
      mouseMoveX: event.clientX,
      targetRect: (event.target as HTMLElement).getBoundingClientRect(),
      currentProps: { ...localProps },
    };
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('contextmenu', handleMouseUp);
    document.addEventListener('mousemove', handleMove);
    setActiveBlockIds([]);
    setDragging(true);
  };

  const [hovered, setHovered] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const handleMouseOver = useCallback(() => {
    timerRef.current = setTimeout(() => setHovered(true), 800);
  }, []);

  const handleMouseOut = useCallback(() => {
    if (!timerRef?.current) {
      return;
    }
    clearTimeout(timerRef.current);
    setHovered(false);
  }, []);

  useEffect(() => {
    const node = ref?.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [handleMouseOut, handleMouseOver]);

  useEffect(() => {
    setShouldShowTooltip(
      (hovered && !activeBlockIds.includes(blockIdForActiveState)) ||
        (isDragging && !activeBlockIds.includes(blockIdForActiveState)),
    );
  }, [hovered, isDragging, activeBlockIds, blockIdForActiveState]);

  // EFFECTS
  useEffect(() => setLocalProps(block), [block]);

  const isPast = isBefore(localProps.end, new Date());
  const allocation = block.allocation;

  const clampedAllocation = useMemo(() => {
    const allocValue = Math.round(
      Math.min(Math.max(allocation, 1), currentResource?.capacity ?? 1),
    );
    return Math.floor(
      linearMap(allocValue, 1, currentResource?.capacity, 1, 5),
    );
  }, [allocation, currentResource?.capacity]);

  const shadeClass = useMemo(
    () => `shade-${currentProject?.color}-${clampedAllocation}`,
    [clampedAllocation, currentProject?.color],
  );

  const blockLength = useMemo(
    () => differenceInWeeks(block.end, block.start) + 1,
    [block.end, block.start],
  );

  const handleMove = useCallback(
    (event: { clientX: number }) => {
      mouseEventRef.current.mouseMoveX = event.clientX;
      if (mouseEventRef.current.handle) resizeBlock();
      else moveBlock();
    },
    [moveBlock, resizeBlock],
  );

  const createNewBlock = useCallback(
    ({ clientX }: { clientX: number }) => {
      setHovered(false);
      setDragging(false);
      if (timerClickRef.current) clearTimeout(timerClickRef.current);
      handleMouseOut();

      const newBlockWeek = addWeeks(
        timeInterval.start,
        (clientX + virtualizer.scrollOffset) / weekSizeInPx,
      );
      const newBlock = {
        id: generateUUID(),
        start: newBlockWeek,
        end: subDays(addWeeks(newBlockWeek, 1), 1),
        allocation: workspaceMode === WORKSPACE_MODE.DAYS ? 1 : 8,
        operation: ALLOCATION_EVENT_OPERATION_TYPE.INSERT,
      } as TTimeBlockRange;

      if (currentResource?.id) {
        onActiveBlockFn({
          blockId: newBlock.id,
          isMulti: false,
          projectId: currentProject.id,
          resourceId: currentResource.id ?? '',
        });

        const blocksToUpdate = [
          ...updateBlocksWithProperEvents(
            currentResource?.timeblocks || [],
            newBlock,
          ),
          newBlock,
        ];

        setBlocksToUpdate({
          events: blocksToUpdate,
          projectId: currentProject.id,
          resourceId: currentResource.id ?? '',
          shouldInvalidated: true,
        });
      }
    },
    [
      handleMouseOut,
      timeInterval.start,
      virtualizer.scrollOffset,
      weekSizeInPx,
      workspaceMode,
      currentResource?.id,
      currentResource?.timeblocks,
      onActiveBlockFn,
      currentProject.id,
      setBlocksToUpdate,
    ],
  );

  const canSplit = useMemo(() => {
    return (
      !rowDisabled && blockLength > 1 && !activeBlockIds?.length && !isDragging
    );
  }, [activeBlockIds?.length, blockLength, isDragging, rowDisabled]);

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  // RENDER
  return (
    <BlockContext.Provider
      value={{
        localProps,
        mouseEventRef,
        lengthWeeks,
        resourceId,
        blockIdForActiveState,
        shouldShowTooltip,
        resourceName,
        avatar,
        block,
        isDragging,
        setDragging,
        handleMouseUp,
        moveBlock,
        resizeBlock,
        handleMove,
      }}
    >
      <div
        ref={ref}
        onClick={(event) => event.stopPropagation()}
        className={classNames([styles.container, styles[shadeClass]], {
          [styles.isPast]: isPast,
          [styles.isExpanded]: layoutIsExpanded,
          [styles.active]: isActive || isDragging,
          [styles.classicCursor]: rowDisabled,
          [styles[`isUnconfirmed-${clampedAllocation}`]]:
            projectStatus === PROJECT_STATUS.UNCONFIRMED,
        })}
        style={{ left: startPixels + 1, width: lengthOfBlockInPx - 1 }}
        onMouseDown={handleMouseDownBlock}
        aria-hidden
      >
        {children}
        {canSplit &&
          Array.from(new Array(blockLength).keys()).map((i) => {
            return (
              <span
                key={i}
                className={classNames(styles.bottom, {
                  [styles.right]: i < blockLength - 1,
                  [styles.left]: i > 0,
                  [styles.isCutExpanded]: layoutIsExpanded,
                })}
                tabIndex={0}
                style={{ width: weekSizeInPx, left: i * weekSizeInPx }}
                role="button"
                onKeyDown={noop}
                onClick={createNewBlock}
                onMouseDown={(e) => e.stopPropagation()}
              />
            );
          })}
        {isDragging && (
          <ScrollDraggingOverlay
            panelId="project-row-handle"
            stepSize={weekSizeInPx}
            onScrollUpdate={() => {
              if (mouseEventRef.current.handle) resizeBlock();
              else moveBlock();
            }}
          />
        )}
      </div>
    </BlockContext.Provider>
  );
};

BlockWrap.Content = Content;
BlockWrap.Handle = Handle;
BlockWrap.Tooltip = Tooltip;

export default BlockWrap;
