import { CSSProperties, FC } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';

import { titleCase } from '@/services/helpers';
import { PROJECT_STATUS } from '@/types/enums';

import Completed from './Completed';
import InProgress from './InProgress';
import { IndicatorProp } from './IndicatorProp';
import ToDo from './ToDo';
import Unconfirmed from './Unconfirmed';


export const INDICATOR_MAPPING = {
  UNCONFIRMED: Unconfirmed,
  IN_PROGRESS: InProgress,
  COMPLETED: Completed,
  TO_DO: ToDo,
};

export function StatusIndicator({
  type,
  size,
  showTooltip = true,
  classNames,
  variant = 'default',
  style,
}: {
  type: PROJECT_STATUS;
  size: number;
  showTooltip?: boolean;
  style?: CSSProperties;
  variant?: 'default' | 'blackAndWhite';
  classNames?: string;
}) {
  const Comp: FC<IndicatorProp> = INDICATOR_MAPPING[type];
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={500}>
        <Tooltip.Trigger
          style={{ cursor: 'default', ...(style ?? {}) }}
          disabled={!showTooltip}
          className={classNames}
        >
          <Comp size={size} variant={variant} />
        </Tooltip.Trigger>
        <Tooltip.Content
          className="TooltipContent"
          sideOffset={5}
        >
          {titleCase(type.replace(/_/g, ' '))}
          <Tooltip.Arrow className="TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
