import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { noop } from 'lodash';

import styles from './styles.module.css';

type Props = {
  label: string;
  subLabel?: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  onSelect?: (e: Event) => void;
};
export default function Item({
  label,
  disabled = false,
  subLabel,
  checked = false,
  className = '',
  onSelect = noop,
}: Props) {
  return (
    <DropdownMenu.Item
      disabled={disabled}
      className={classNames(styles.item, className, {
        [styles.checked]: checked,
      })}
      onSelect={onSelect}
    >
      <IconCheck size={20} className={styles.itemIndicator} />
      <span className={styles.itemContent}>
        <span className={styles.itemLabel}>{label}</span>
        <span className={styles.itemSubLabel}>{subLabel}</span>
      </span>
    </DropdownMenu.Item>
  );
}
