import { useMemo } from 'react';

import { IconSparkles, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';

import useThemeSwitcher, { THEMES } from '@/hooks/utils/useThemeSwitcher';
import { getCssVariable } from '@/services/helpers';

import Button from '@/components/Button';

import autoSyncVideo from '@/assets/auto_sync.mp4';
import autoSyncDarkVideo from '@/assets/auto_sync_dark.mp4';

import styles from './styles.module.css';
import Modal, { ModalProps } from '../Modal';







type Props = Pick<ModalProps, 'isOpen' | 'overflowShouldPreventClose'> & {
  onClose: (answer?: boolean) => void;
};

export default function ModalManageAutomations({
  isOpen,
  onClose,
  overflowShouldPreventClose = false,
}: Props) {
  const { theme } = useThemeSwitcher();
  const video = useMemo(() => {
    if (theme === THEMES.DARK) return autoSyncDarkVideo;
    return autoSyncVideo;
  }, [theme]);
  return (
    <Modal
      modalKey="auto-sync-modal"
      isOpen={isOpen}
      footer={undefined}
      onClose={
        !overflowShouldPreventClose ? () => onClose(undefined) : undefined
      }
      overflowShouldPreventClose={overflowShouldPreventClose}
    >
      <div className={styles.contentWrapper}>
        {!overflowShouldPreventClose && (
          <div className={styles.closeHeader}>
            <IconX
              size={24}
              onClick={() => onClose(undefined)}
              color={getCssVariable('--icon-subdued')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
        <div
          className={classNames(styles.videoContainer, {
            [styles.isDark]: theme === THEMES.DARK,
          })}
        >
          <video autoPlay muted src={video} height={280 - 24} loop>
            <track kind="captions" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.descriptionContainer}>
          <div className={styles.block}>
            <div className={styles.heading}>
              <IconSparkles
                size={24}
                color={getCssVariable('--icon-caution')}
                fill={getCssVariable('--icon-caution')}
              />
              <p className={styles.title}>
                {t('common:modals.modalManageAutomations.title')}
              </p>
            </div>

            <div className={styles.description}>
              <span className={styles.text}>
                {t('common:modals.modalManageAutomations.description')}
              </span>
            </div>
          </div>
          <div className={styles.block}>
            <Button
              label={t(`common:modals.modalManageAutomations.action`)}
              size="large"
              onClick={() => onClose(true)}
            />
            <Button
              label={t('common:modals.modalManageAutomations.skipAction')}
              size="large"
              variant="ghost"
              onClick={() => onClose(undefined)}
              className={styles.skipButton}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
