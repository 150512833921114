import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';

export type TYPE =
  | 'informative'
  | 'critical'
  | 'success'
  | 'neutral'
  | 'white'
  | 'timeoff'
  | 'alert';
type Props = PropsWithChildren<{
  type?: TYPE;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}>;
export default function Badge({
  type = 'white',
  children,
  size = 'medium',
  className: cls = '',
  onClick,
}: Props) {
  return (
    <div
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyDown={(event) => event.key === 'Enter' && onClick?.()}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      className={classNames(cls, [
        styles.container,
        styles[`${type}`],
        styles[`${size}`],
      ])}
    >
      {children}
    </div>
  );
}
