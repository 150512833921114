import { IndicatorProp } from './IndicatorProp';

export default function ToDo({
  size = 24,
  variant = 'default',
}: IndicatorProp) {
  const primaryColor = variant === 'default' ? '#E38800' : '#fff';
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17317 2.7612C9.38642 2.25866 10.6868 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 10.6868 2.25866 9.38642 2.7612 8.17317C3.26375 6.95991 4.00035 5.85752 4.92893 4.92893C5.85752 4.00035 6.95991 3.26375 8.17317 2.7612ZM12 4.43902C11.0071 4.43902 10.0239 4.63459 9.10654 5.01457C8.1892 5.39454 7.35568 5.95148 6.65358 6.65358C5.95148 7.35568 5.39454 8.1892 5.01457 9.10654C4.63459 10.0239 4.43902 11.0071 4.43902 12C4.43902 12.9929 4.63459 13.9761 5.01457 14.8935C5.39454 15.8108 5.95148 16.6443 6.65358 17.3464C7.35568 18.0485 8.1892 18.6055 9.10654 18.9854C10.0239 19.3654 11.0071 19.561 12 19.561C12.9929 19.561 13.9761 19.3654 14.8935 18.9854C15.8108 18.6055 16.6443 18.0485 17.3464 17.3464C18.0485 16.6443 18.6055 15.8108 18.9854 14.8935C19.3654 13.9761 19.561 12.9929 19.561 12C19.561 11.0071 19.3654 10.0239 18.9854 9.10654C18.6055 8.1892 18.0485 7.35569 17.3464 6.65358C16.6443 5.95148 15.8108 5.39454 14.8935 5.01457C13.9761 4.63459 12.9929 4.43902 12 4.43902Z"
        fill={primaryColor}
      />
    </svg>
  );
}
