import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { getAccount } from '@/services/api/account';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TUserAccount } from '@/types/workspace';

export const ACCOUNT_QUERY_KEY = 'get-account';
export default function useAccountQuery({
  enabled,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TUserAccount>) {
  const { addNotification } = useContext(NotificationsContext);

  return useQuery([ACCOUNT_QUERY_KEY], () => getAccount(), {
    enabled,
    onError: (error: TResponseError) => {
      onError(error);
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
    retry: false,
    onSuccess,
  });
}
