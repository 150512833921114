import * as RadixSwitch from '@radix-ui/react-switch';

import styles from './styles.module.css';

type Props = {
  id: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
};
export default function Switch({
  id,
  label,
  onChange,
  checked,
  disabled = false,
}: Props) {
  return (
    <div className={styles.container}>
      <RadixSwitch.Root
        className={styles.root}
        onCheckedChange={onChange}
        id={id}
        checked={checked}
        disabled={disabled}
      >
        <RadixSwitch.Thumb className={styles.thumb} />
      </RadixSwitch.Root>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
}
