import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

import { useProjectRowContext } from './Context';
import styles from './styles.module.css';
import RowLeftHeader from '../RowLeftHeader';

// eslint-disable-next-line import/named
type Props = {
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};
export default function Header({ sortHandlerProps }: Props) {
  const { project, resource } = useProjectRowContext();

  if (!project || !resource) return null;
  return (
    <div className={styles.header}>
      <RowLeftHeader
        allocation={project?.totalAllocation}
        type={project.type}
        projectEmoji={project.emoji}
        projectStatus={project.status}
        resourceName={resource.firstName + ' ' + resource.lastName}
        resourceId={resource.id}
        projectColor={project.color}
        projectId={project.id}
        sortHandlerProps={sortHandlerProps}
      >
        {project.name}
      </RowLeftHeader>
    </div>
  );
}
