import { useCallback, useState } from 'react';


import useJoinFeatureFlags from '@/hooks/FeatureFlags/useJoinFeatureFlags';
import useLeaveFeatureFlags from '@/hooks/FeatureFlags/useLeaveFeatureFlags';

import Switch from '@/components/Switch';

import styles from './styles.module.css';

type FeatureFlagsRowProps = {
  code: string;
  name: string;
  description: string;
  isActive: boolean;
  isDisabled: boolean;
};
export default function FeatureFlagsRow({
  code,
  name,
  description,
  isActive,
  isDisabled,
}: FeatureFlagsRowProps) {
  const [value, setValue] = useState<boolean>(isActive);

  const { mutate } = useJoinFeatureFlags();

  const { mutate: mutateLeave } = useLeaveFeatureFlags();

  const handleChangeFn = useCallback(
    (code?: string, join = false) => {
      if (!code) return;
      setValue(join);
      const mutateFn = join ? mutate : mutateLeave;
      mutateFn(
        { code },
        {
          onError: () => setValue(!join),
        },
      );
    },
    [mutate, mutateLeave],
  );

  return (
    <div key={code} className={styles.mainContainer}>
      <div className={styles.textContainer}>
        <h4>{name}</h4>
        <p>{description}</p>
      </div>
      <div className={styles.switchContainer}>
        <Switch
          id={`${code}-feature-flag}`}
          label={''}
          checked={value}
          disabled={isDisabled}
          onChange={(e: boolean) => handleChangeFn(code, e)}
        />
      </div>
    </div>
  );
}
