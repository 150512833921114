import React, { useCallback, useState, PropsWithChildren } from 'react';

import * as Toast from '@radix-ui/react-toast';
import { noop } from 'lodash';

import { generateUUID } from '@/services/helpers';

import Button from '@/components/Button';

export type Notification = {
  id: string;
  type: 'error' | 'success' | 'warning' | 'info';
  title: string;
  description: string;
  action?: () => void;
  actionLabel?: string;
};

const NotificationsContext = React.createContext<{
  addNotification: (notification: Partial<Notification>) => void;
}>({
  addNotification: noop,
});

const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const onOpenChangeFn = useCallback((open: boolean, id: string) => {
    if (!open) {
      setNotifications((prev: Notification[]) =>
        prev.filter((e) => e.id !== id),
      );
    }
  }, []);

  const addNotification = useCallback((notification: Partial<Notification>) => {
    setNotifications((prev: Notification[]) => [
      ...prev,
      { ...(notification as Notification), id: generateUUID() },
    ]);
  }, []);

  return (
    <NotificationsContext.Provider value={{ addNotification }}>
      {children}
      <Toast.Provider>
        {notifications?.map((notification) => {
          return (
            <Toast.Root
              className={'ToastRoot'}
              onOpenChange={(open) => onOpenChangeFn(open, notification.id)}
              key={`error-${notification.id}`}
              duration={3000}
              data-type={notification.type}
            >
              <Toast.Title className="ToastTitle">
                {notification?.title}
              </Toast.Title>
              <Toast.Description className="ToastDescription">
                {notification?.description}
              </Toast.Description>
              {notification?.actionLabel && (
                <Toast.Action
                  altText={notification.actionLabel ?? ''}
                  className="ToastAction"
                >
                  <Button
                    label={notification.actionLabel}
                    onClick={notification?.action}
                  />
                </Toast.Action>
              )}
            </Toast.Root>
          );
        })}
        <Toast.Viewport className="ToastViewport" />
      </Toast.Provider>
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, NotificationsContext };
