import { useCallback, useContext, useEffect, useState } from 'react';

import type { FirebaseError } from '@firebase/app';
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';
import classNames from 'classnames';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserContext } from '@/contexts/UserContext';
import { ROUTES } from '@/types/routes';

import BoxMessage from '@/components/BoxMessage';
import SignInIllustration from '@/components/Illustrations/SignInIllustration';
import Layout from '@/components/Layout';
import LogoHeader from '@/components/LogoHeader';
import type { SignInFormData } from '@/components/SignInForm';
import SignInForm from '@/components/SignInForm';

import styles from './styles.module.css';






export default function SignInScreen() {
  const location = useLocation();
  const redirectRoute = location?.state?.redirectRoute;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { isLoggedIn, isLoading: isLoadingUserAccount } =
    useContext(UserContext);
  const navigate = useNavigate();
  const auth = getAuth();

  const onSuccessLoginFn = useCallback(() => {
    if (auth.currentUser?.emailVerified) {
      return navigate(redirectRoute ?? ROUTES.ROOT, {
        preventScrollReset: false,
        replace: true,
      });
    } else {
      navigate(ROUTES.EMAIL_VERIFICATION, {
        replace: true,
        preventScrollReset: false,
      });
    }
  }, [auth.currentUser?.emailVerified, navigate, redirectRoute]);

  useEffect(() => {
    if (isLoggedIn && !isLoadingUserAccount) {
      onSuccessLoginFn();
    }
  }, [isLoggedIn, onSuccessLoginFn, isLoadingUserAccount]);

  const onSubmitFn = useCallback(
    (data: SignInFormData) => {
      setIsLoading(true);
      const onSubmit = async () => {
        try {
          await signInWithEmailAndPassword(auth, data.email, data.password);
        } catch (error) {
          const err = error as FirebaseError;
          setError(err?.code);
        } finally {
          setIsLoading(false);
        }
      };
      onSubmit();
    },
    [auth],
  );

  const onDismissMessageError = useCallback(() => {
    setError('');
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <LogoHeader />
          </div>
          <div className={styles.right}>
            {/* <Button
              label={t('forms:signUp.submit')}
              variant="outline"
              size="medium"
              link={{ to: ROUTES.SIGN_UP, preventScrollReset: false }}
            /> */}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <div className={styles.form}>
              <h1 className={styles.title}>{t('forms:signIn.title')}</h1>
              {error && (
                <div className={styles.box}>
                  <BoxMessage variant="error" onDismiss={onDismissMessageError}>
                    {t(`errors:firebase.${error}`)}
                  </BoxMessage>
                </div>
              )}
              <SignInForm onSubmitFn={onSubmitFn} isLoading={isLoading} />
            </div>
          </div>
          <div className={classNames(styles.wrapper, styles.aside)}>
            {/* <img alt="notion-parade" src={parade} /> */}
            <div className={styles.caption}>
              <div className={styles.illustration}>
                <SignInIllustration />
              </div>
              <h1>{t('common:welcomePage.title')}</h1>
              <p>{t('common:welcomePage.description')}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
