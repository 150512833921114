import { ReactNode, useCallback, useState } from 'react';

import * as Collapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import { ClipLoader } from 'react-spinners';

import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { getCssVariable, cssVariablesByType } from '@/services/helpers';
import { INSIGHTS_TYPE } from '@/types/enums';
import { TInsightsPart } from '@/types/insights';

import styles from './styles.module.css';
import Modal from '../Modals/Modal';






type DataInsightPart<T extends INSIGHTS_TYPE> = TInsightsPart<
  Exclude<T, INSIGHTS_TYPE.ACTIVE_PROJECTS | INSIGHTS_TYPE.CAPACITY>
>['data'];

type Props<T extends INSIGHTS_TYPE> = {
  insightsType: Exclude<
    T,
    INSIGHTS_TYPE.ACTIVE_PROJECTS | INSIGHTS_TYPE.CAPACITY
  >;
  open: boolean;
  type: 'collapsible' | 'modal';
  title?: string | ReactNode;
  isLoading?: boolean;
  triggerLabel: string;
  onOpenChange?: (open: boolean) => void;
  titleClassName?: string;
  renderData: (data: DataInsightPart<T>) => JSX.Element;
};

export default function ShowMore<T extends INSIGHTS_TYPE>({
  open: defaultOpen = false,
  insightsType,
  type = 'collapsible',
  triggerLabel,
  onOpenChange = (_open) => {},
  renderData,
  titleClassName = '',
  title,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const { data, isLoading } = useInsightsPartQuery<
    Exclude<T, INSIGHTS_TYPE.ACTIVE_PROJECTS | INSIGHTS_TYPE.CAPACITY>
  >({
    insightsType,
    enabled: isOpen,
  });

  const renderElement = useCallback(
    () =>
      isLoading ? (
        <div className={styles.loaderContainer}>
          <ClipLoader
            color={getCssVariable(cssVariablesByType('neutral'))}
            cssOverride={{
              borderWidth: '2px',
            }}
            size={24}
            speedMultiplier={0.8}
          />
        </div>
      ) : (
        renderData(data?.data ?? [])
      ),
    [data, isLoading, renderData],
  );

  return (
    <Collapsible.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        onOpenChange(open);
      }}
    >
      <Collapsible.Content
        className={classNames({
          [styles.CollapsibleContent]: type === 'collapsible',
        })}
      >
        {type === 'collapsible' ? (
          renderElement()
        ) : (
          <Modal
            modalKey={`modal-show-more-${insightsType}`}
            isOpen={isOpen}
            title={title}
            panelClassName={[styles.modalContentWrapper]}
            onClose={() => setIsOpen(false)}
            footer={undefined}
            titleClassName={titleClassName}
            bodyClassName={styles.modalBody}
          >
            <div className={styles.modalContent}>{renderElement()}</div>
          </Modal>
        )}
      </Collapsible.Content>
      <Collapsible.Trigger>
        <p className={styles.more}>{triggerLabel}</p>
      </Collapsible.Trigger>
    </Collapsible.Root>
  );
}
