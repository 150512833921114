import { PropsWithChildren, createContext, useContext } from 'react';

import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { noop } from 'lodash';

export const CollapsibleDrawerContext = createContext<{
  collapsedDrawer?: { [key: string]: boolean };
  setCollapsedDrawer?: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
}>({
  collapsedDrawer: {},
  setCollapsedDrawer: noop,
});

export const useCollapsibleDrawer = (drawerId: string) => {
  const { collapsedDrawer, setCollapsedDrawer } = useContext(
    CollapsibleDrawerContext,
  );

  if (!collapsedDrawer || !setCollapsedDrawer)
    throw new Error(
      'useCollapsibleDrawer must be used within a CollapsibleDrawerContext',
    );

  const isCollapsed = collapsedDrawer?.[drawerId];

  const onCollapseChange = (value: boolean) => {
    setCollapsedDrawer?.((prevState) => ({
      ...prevState,
      [drawerId]: value,
    }));
  };

  return { isCollapsed, onCollapseChange };
};

export const CollapsibleDrawer = ({ children }: PropsWithChildren) => {
  const [collapsedDrawer, setCollapsedDrawer] = useLocalStorage<{
    [key: string]: boolean;
  }>('collpsed-drawer-card', {});

  return (
    <CollapsibleDrawerContext.Provider
      value={{
        collapsedDrawer: collapsedDrawer ?? {},
        setCollapsedDrawer,
      }}
    >
      {children}
    </CollapsibleDrawerContext.Provider>
  );
};
