import { useCallback, useContext, useEffect } from 'react';

import { applyActionCode, getAuth } from '@firebase/auth';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router';

import { UserContext } from '@/contexts/UserContext';
import useVerifyConsent from '@/hooks/iubenda/useVerifyConsent';
import { ROUTES } from '@/types/routes';

import Loader from '@/components/Loader';



export function ValidateEmailAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const { setIsUserEmailVerified } = useContext(UserContext);
  const { mode, oobCode } = queryString.parse(location.search);

  const { mutate: verifySubject } = useVerifyConsent();

  const validateEmailAccountFn = useCallback(async () => {
    const currentUser = auth?.currentUser;
    if (currentUser?.uid && !currentUser?.emailVerified) {
      await applyActionCode(auth, oobCode as string).then(async () => {
        await auth.currentUser?.reload();
        await auth.currentUser?.getIdToken(true);
        verifySubject(undefined, {
          onSuccess: async () => {
            setIsUserEmailVerified(true);
            return navigate(ROUTES.ROOT, {
              replace: true,
              preventScrollReset: false,
            });
          },
        });
      });
    } else {
      navigate(ROUTES.SIGN_IN, {
        replace: true,
        preventScrollReset: false,
      });
    }
  }, [auth, navigate, oobCode, setIsUserEmailVerified, verifySubject]);

  useEffect(() => {
    if (!mode || !oobCode) {
      navigate(ROUTES.ROOT, { preventScrollReset: false });
    } else {
      validateEmailAccountFn();
    }
  }, [mode, navigate, oobCode, validateEmailAccountFn]);

  return <Loader />;
}
