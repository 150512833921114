import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  // eslint-disable-next-line import/named
  User as FirebaseUser,
  getAuth,
  onAuthStateChanged,
} from '@firebase/auth';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { find, noop } from 'lodash';


import useAccountQuery from '@/hooks/account/useAccountQuery';
import useImageFirebaseQuery from '@/hooks/firebase/useImageFirebaseQuery';
import { WORKSPACE_MODE } from '@/types/enums';
import {
  TUserAccount,
  TUserWorkspace,
  TWorkspaceUsageInfo
} from '@/types/workspace';

import { useAnalytics } from '../hooks/utils/useAnalytics';



const UserContext = React.createContext<{
  isLoggedIn: boolean | null;
  user: FirebaseUser | null;
  isLoading: boolean;
  isUserEmailVerified: boolean | null;
  setIsUserEmailVerified: (value: boolean) => void;
  account: TUserAccount | null;
  workspaceId?: string | null;
  clearLocalStorage: () => void;
  workspaceMode?: WORKSPACE_MODE;
  mobileExperienceAccepted: boolean | null;
  setMobileExperienceAccepted: (value: boolean) => void;
  workspace?: TUserWorkspace;
  usageInfo?: TWorkspaceUsageInfo;
  modeMultiplier: number;
  setWorkspaceId: (wsId: string | undefined) => void;
  isMacOs: boolean;
}>({
  isLoggedIn: false,
  user: null,
  isLoading: false,
  clearLocalStorage: noop,
  isUserEmailVerified: false,
  setIsUserEmailVerified: noop,
  setWorkspaceId: noop,
  setMobileExperienceAccepted: noop,
  workspaceId: '',
  workspace: undefined,
  workspaceMode: WORKSPACE_MODE.DAYS,
  mobileExperienceAccepted: false,
  account: null,
  modeMultiplier: 8,
  usageInfo: undefined,
  isMacOs: false,
});

const UserProvider = ({ children }: PropsWithChildren) => {
  const [isLoggedIn = false, setIsLoggedIn] = useLocalStorage<
    boolean | undefined
  >('user-logged', false);
  const [workspaceMode, setWorkspaceMode] = useState<WORKSPACE_MODE>(
    WORKSPACE_MODE.DAYS,
  );
  const [workspaceId, setWorkspaceId] = useLocalStorage<string | undefined>(
    `pln-ws`,
    undefined,
  );
  const isMacOs = useMemo(() => {
    const usAgent = navigator.userAgent.toUpperCase();
    return usAgent.indexOf('MAC') > -1 || usAgent.indexOf('MACOS') > -1;
  }, []);

  const [mobileExperienceAccepted, setMobileExperienceAccepted] =
    useLocalStorage<boolean>(`mobileExperience`, false);

  const auth = getAuth();
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [isUserEmailVerified, setIsUserEmailVerified] = useState<
    boolean | null
  >(false);

  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(true);
  const enabled = useMemo(
    () => Boolean(isUserEmailVerified),
    [isUserEmailVerified],
  );
  const { data: userAccount, isLoading: isLoadingUserAccount } =
    useAccountQuery({
      enabled,
    });

  const _isLoadingUserAccount =
    Boolean(auth.currentUser?.email) && isLoadingUserAccount;

  const { trackUserId, trackGroupId } = useAnalytics();
  const workspace = find(userAccount?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;

  const { data: userAvatar } = useImageFirebaseQuery({
    path: userAccount?.image?.ref,
    enabled: Boolean(userAccount?.image?.ref),
  });
  const clearLocalStorage = useCallback(() => {
    setWorkspaceId(undefined);
    setIsLoggedIn(undefined);
    // localStorage.clear();
  }, [setIsLoggedIn, setWorkspaceId]);

  const [userAlreadyTracked, setUserAlreadyTracked] = useState<boolean>(false);
  // Track user and group ids
  useEffect(() => {
    if (!isLoggedIn || !user || !auth || userAlreadyTracked || !workspace)
      return;

    setUserAlreadyTracked(true);
    trackUserId({
      email: user?.email as string,
      name:
        userAccount?.firstName && userAccount.lastName
          ? `${userAccount?.firstName} ${userAccount?.lastName}`
          : '',
      avatar: userAvatar ?? undefined,
      weeklyEmail: workspace?.weeklyEmail,
      workspaceRole: workspace?.role,
      workspacePermission: workspace?.permission,
    });
    trackGroupId({
      id: workspace?.id,
      groupName: workspace?.name,
      timeMode: workspace?.mode,
    });
  }, [
    trackGroupId,
    userAlreadyTracked,
    setUserAlreadyTracked,
    workspace,
    trackUserId,
    user,
    isLoggedIn,
    auth,
    userAccount,
    userAvatar,
  ]);

  // const { data: usageInfo } = useUsageInfo({
  //   workspaceId: workspace?.id ?? '',
  //   enabled: !!workspace?.id && Boolean(isLoggedIn),
  // });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setIsLoggedIn(true);
        setIsUserEmailVerified(user.emailVerified);
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setIsUserEmailVerified(null);
      }
      setIsFetchingUser(false);
    });
    return unsubscribe;
  }, [auth, setIsFetchingUser, setIsLoggedIn]);

  useEffect(() => {
    if (workspace?.mode) {
      setWorkspaceMode(workspace.mode);
    } else setWorkspaceMode(WORKSPACE_MODE.DAYS);
  }, [workspace?.mode]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        user,
        setMobileExperienceAccepted,
        mobileExperienceAccepted,
        clearLocalStorage,
        isUserEmailVerified,
        setIsUserEmailVerified,
        account: userAccount ?? null,
        workspaceMode,
        isLoading:
          (isFetchingUser && !user) || (enabled && _isLoadingUserAccount),
        workspace,
        workspaceId,
        setWorkspaceId,
        // usageInfo,
        modeMultiplier: workspaceMode === WORKSPACE_MODE.DAYS ? 8 : 1,
        isMacOs,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
