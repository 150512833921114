import { useCallback, useContext, useEffect, useState } from 'react';

import type { FirebaseError } from '@firebase/app';
import { confirmPasswordReset, getAuth } from '@firebase/auth';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';


import { UserContext } from '@/contexts/UserContext';
import { checkPasswordValidity } from '@/services/helpers';
import { ROUTES } from '@/types/routes';

import BoxMessage from '@/components/BoxMessage';
import Button from '@/components/Button';
import Layout from '@/components/Layout';
import type { FormData } from '@/components/ResetPasswordForm';
import ResetPasswordForm from '@/components/ResetPasswordForm';

import logo from '@/assets/new_logo.svg';

import stylesSignIn from '../../SignIn/styles.module.css';




export default function ResetPasswordScreen() {
  const location = useLocation();
  const oobCode = location?.state?.oobCode;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState<Record<
    string,
    boolean
  > | null>(null);
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setSubmitted(true);
  }, [setSuccess]);

  useEffect(() => {
    setError('');
  }, [currentPassword]);

  const auth = getAuth();
  const { isLoggedIn } = useContext(UserContext);

  useEffect(() => {
    if (currentPassword) {
      setPasswordCheck(checkPasswordValidity(currentPassword));
    } else {
      setPasswordCheck(null);
    }
  }, [currentPassword, setPasswordCheck]);

  useEffect(() => {
    if (!oobCode || isLoggedIn) {
      navigate(ROUTES.SIGN_IN, { preventScrollReset: false });
      return;
    }
  }, [navigate, oobCode, isLoggedIn]);

  const onSubmitFn = useCallback(
    (data: FormData) => {
      setError('');

      setIsLoading(true);
      const onSubmit = async () => {
        setApiError(false);
        try {
          await confirmPasswordReset(auth, oobCode, data.password);
          onSuccess();

          setError('');
        } catch (error) {
          const err = error as FirebaseError;
          setError(err?.code);
          setApiError(true);
        } finally {
          setIsLoading(false);
        }
      };
      onSubmit();
    },
    [auth, oobCode, onSuccess],
  );

  const [submitted, setSubmitted] = useState(false);
  const onDismissMessageError = useCallback(() => {
    setError('');
  }, []);

  return (
    <Layout>
      <div className={stylesSignIn.container}>
        <div className={stylesSignIn.header}>
          <div className={stylesSignIn.left}>
            <img src={logo} alt="logo" width="115" height="32" />
          </div>
          <div className={stylesSignIn.right}>
            <Button
              label={t('forms:signUp.submit')}
              variant="outline"
              size="large"
              link={{ to: ROUTES.SIGN_UP, preventScrollReset: false }}
            />
          </div>
        </div>
        <div className={stylesSignIn.wrapper}>
          <div className={stylesSignIn.form}>
            <h1 className={stylesSignIn.title}>
              {t('forms:resetPassword.title')}
            </h1>
            {submitted && (
              <div className={stylesSignIn.box}>
                <BoxMessage variant="success">
                  {t('forms:resetPassword.success.message')}
                </BoxMessage>
              </div>
            )}
            {error && (
              <div className={stylesSignIn.box}>
                <BoxMessage variant="error" onDismiss={onDismissMessageError}>
                  {t(`errors:firebase.${error}`)}
                </BoxMessage>
              </div>
            )}

            {!success ? (
              <>
                <ResetPasswordForm
                  onSubmitFn={onSubmitFn}
                  isLoading={isLoading}
                  onChangeFn={setCurrentPassword}
                />
              </>
            ) : (
              <Button
                label={t('forms:resetPassword.success.submit') ?? ''}
                size="large"
                link={ROUTES.SIGN_IN}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
