import { useProjectHideRowContext } from './Context';
import styles from './styles.module.css';
import HiddenHeader from '../../../HiddenHeader';
export default function Header() {
  const { resourceId, count } = useProjectHideRowContext();

  return (
    <div className={styles.header}>
      <HiddenHeader id={resourceId} projectsNumber={count} />
    </div>
  );
}
