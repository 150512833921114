import { useState } from 'react';

import { IconKey } from '@tabler/icons-react';
import { t } from 'i18next';
import { useCopyToClipboard } from 'react-use';

import { getCssVariable } from '@/services/helpers';
import { AccessTokenInfo } from '@/types/workspace';

import Button from '@/components/Button';

import TokenSection from './TokenSection';
import styles from './styles.module.css';
import ModalRevokeAccessToken from '../../ModalRevokeAccessToken';



type Props = {
  token: AccessTokenInfo & { workspaceId: string };
  accessToken?: string;
};
export default function TokenInfoContainer({ token, accessToken }: Props) {
  const [_copyState, copyAccessToken] = useCopyToClipboard();
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState<boolean>(false);
  return (
    <>
      <div className={styles.revokeContainer}>
        <section className={styles.revokeInfoSection}>
          <div className={styles.revokeTextContainer}>
            <IconKey size={20} color={getCssVariable('--icon')} />
            <span>{token?.workspaceName}</span>
          </div>
          <Button
            label={t('common:modals.api.actionRevoke')}
            variant="outline"
            size="medium"
            onClick={() => setIsRevokeModalOpen(true)}
          />
        </section>
        {accessToken && (
          <TokenSection token={accessToken} onClipboardCopy={copyAccessToken} />
        )}
      </div>
      {isRevokeModalOpen && (
        <ModalRevokeAccessToken
          tokenInfo={token}
          onClose={() => setIsRevokeModalOpen(false)}
          isOpen={isRevokeModalOpen}
        />
      )}
    </>
  );
}
