import {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import Input from '@components/Input';
import { t } from 'i18next';


import { UserContext } from '@/contexts/UserContext';
import { useAnalytics } from '@/hooks/utils/useAnalytics';
import useUpdateProject from '@/hooks/workspace/projects/useUpdateProject';
import { titleCase } from '@/services/helpers';
import { PROJECT_COLOR, PROJECT_STATUS } from '@/types/enums';

import { INDICATOR_MAPPING } from '@/components/Indicators';
import InputSelect from '@/components/InputSelect';
import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import ColorSelector from '../../ColorSelector';
import EmojiSelector from '../../EmojiSelector';


type Props = {
  name: string;
  isOpen: boolean;
  color: PROJECT_COLOR;
  projectId: string;
  projectEmoji?: string;
  projectStatus?: PROJECT_STATUS;
  onClose: () => void;
};
export default function ModalEditProject({
  isOpen,
  name: n,
  color: c,
  projectEmoji,
  projectStatus,
  onClose,
  projectId,
}: Props) {
  const { mutate, isLoading } = useUpdateProject();
  const [name, setName] = useState<string>('');
  const [emoji, setEmoji] = useState<string | undefined>(projectEmoji ?? '');
  const [color, setColor] = useState<PROJECT_COLOR>(PROJECT_COLOR.GREY);
  const [status, setStatus] = useState<PROJECT_STATUS>(
    projectStatus ?? PROJECT_STATUS.TO_DO,
  );
  const [nameError, setNameError] = useState<string | undefined>(undefined);

  const { trackEvent } = useAnalytics();
  const { workspace } = useContext(UserContext);
  const onSubmit = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault();
      mutate({ name, projectId, color, emoji, status }, { onSuccess: onClose });
      if (projectStatus !== status)
        trackEvent('PROJECT_STATUS_CHANGED', workspace?.id as string);
    },
    [
      color,
      emoji,
      mutate,
      name,
      onClose,
      projectId,
      projectStatus,
      status,
      trackEvent,
      workspace?.id,
    ],
  );

  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  const onSelectEmojiFn = (emoji: string | undefined) => {
    setEmoji(emoji);
  };

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    setName('');
    setName(n);
    setColor(c);
  }, [isOpen, n, c]);

  const mapStatusItem = useCallback((item: PROJECT_STATUS) => {
    const Indicator = INDICATOR_MAPPING[item];
    return (
      <div className={styles.statusItem}>
        <Indicator />
        {titleCase(item.replace('_', ' '))}
      </div>
    );
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onSubmit]);

  const onSubmitFn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!name) {
      setNameError(
        t('forms:required', {
          field: t('common:modals.editProject.label'),
        }),
      );
      return;
    } else if (name.length > 180) {
      setNameError(
        t('forms:maxLength', { field: 'TProject name', count: 180 }),
      );
      return;
    }
    setNameError(undefined);
    onSubmit();
  };
  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-edit-project-${projectId}`}
      onClose={onClose}
      title={t('common:modals.editProject.title')}
      footer={
        <>
          <Button
            label={t('common:modals.editProject.primaryCtaLabel')}
            onClick={onSubmitFn}
            size="medium"
            disabled={!name}
            isLoading={isLoading}
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <form className={styles.inputWrapper} onSubmit={onSubmit}>
        <div className={styles.inputRow}>
          <div className={styles.inputMain}>
            <Input
              required
              ref={ref}
              label={t('common:modals.editProject.label') as string}
              value={name}
              placeholder={'E.g My project'}
              onChange={onChangeFn}
              name="name"
            />
          </div>

          <div className={styles.inputTrailing}>
            <EmojiSelector defaultValue={emoji} onSelect={onSelectEmojiFn} />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContent}>
            <InputSelect
              type="shortcut"
              label="Status"
              menuId="status-select"
              fullWidth
              options={Object.values(PROJECT_STATUS)}
              optionsMapper={mapStatusItem}
              selectionMapper={mapStatusItem}
              selectedElement={status}
              onSelectChange={(item) => setStatus(item)}
              shortcutMapper={(_, index) => `${index + 1}`}
            />
            <div className={styles.autoUpdateEnabled}>
              <span>
                {t('common:modals.editProject.automations.autoUpdate', {
                  value: workspace?.autoSync ? 'enabled' : 'disabled',
                })}
              </span>
            </div>
          </div>
        </div>
        <span
          style={{
            display: !nameError ? 'none' : 'block',
          }}
          className={styles.error}
        >
          {nameError}
        </span>

        <ColorSelector color={color} onChange={setColor} />
      </form>
    </Modal>
  );
}
