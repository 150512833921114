import { Fragment, useContext } from 'react';

import Section from '@components/Section';
import { t } from 'i18next';

import { UIContext } from '@/contexts/UIContext';
import { THEMES } from '@/hooks/utils/useThemeSwitcher';

import SelectDropdown from '@/components/SelectDropdown';

import styles from './styles.module.css';

export type UpdateWorkspaceDataForm = {
  workspaceName: string;
};

export default function ModalWorkspacePreferencesContent() {
  const { theme, setThemeDetection } = useContext(UIContext);

  return (
    <Fragment>
      <Section title={t('common:profileModal.preferences.title')}>
        <div className={styles.mainContainer}>
          <div className={styles.modeChangeWrapper}>
            <div className={styles.modeChangeLabelWrapper}>
              <div className={styles.modeChangeLabel}>
                {t('common:profileModal.preferences.appearance.title')}
              </div>
              <div className={styles.modeChangeSubLabel}>
                {t('common:profileModal.preferences.appearance.description')}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <SelectDropdown
                label={t(
                  `common:profileModal.preferences.appearance.labels.${theme}.label`,
                )}
                align="end"
              >
                <SelectDropdown.Item
                  label={t(
                    `common:profileModal.preferences.appearance.labels.system.label`,
                  )}
                  subLabel={t(
                    `common:profileModal.preferences.appearance.labels.system.description`,
                  )}
                  checked={theme === THEMES.SYSTEM}
                  onSelect={() => setThemeDetection(THEMES.SYSTEM)}
                />
                <SelectDropdown.Item
                  label={t(
                    `common:profileModal.preferences.appearance.labels.light.label`,
                  )}
                  subLabel={t(
                    `common:profileModal.preferences.appearance.labels.light.description`,
                  )}
                  checked={theme === THEMES.LIGHT}
                  onSelect={() => setThemeDetection(THEMES.LIGHT)}
                />
                <SelectDropdown.Item
                  label={t(
                    `common:profileModal.preferences.appearance.labels.dark.label`,
                  )}
                  subLabel={t(
                    `common:profileModal.preferences.appearance.labels.dark.description`,
                  )}
                  checked={theme === THEMES.DARK}
                  onSelect={() => setThemeDetection(THEMES.DARK)}
                />
              </SelectDropdown>
            </div>
          </div>
        </div>
      </Section>
    </Fragment>
  );
}
