import { PropsWithChildren } from 'react';

import { createPortal } from 'react-dom';


export default function Portal({
  children,
  element = document.body,
}: PropsWithChildren<{ element?: Element }>) {
  return createPortal(children, element);
}
