import { t } from 'i18next';

import Button from '@/components/Button';

import styles from './styles.module.css';
import { ErrorScreen } from '..';


export function Error403({ onClearError }: { onClearError: () => void }) {
  return (
    <ErrorScreen>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.head}>{t('common:forbiddenError.title')}</h1>
          <p>{t('common:forbiddenError.description')}</p>
        </div>
        <div className={styles.cta}>
          <Button
            label={t('common:forbiddenError.ctaLabel')}
            size="large"
            onClick={onClearError}
          />
        </div>
      </div>
    </ErrorScreen>
  );
}
