import { useContext, useMemo } from 'react';

import ActiveBlockWrapper from '@components/Timelines/TimelineProjects/ActiveBlockWrapper';
import { filter, first, isEmpty, last } from 'lodash';

import { weeksToPixels } from '@/services/helpers';
import { PROJECT_TYPE } from '@/types/enums';
import { TTimeBlockRange } from '@/types/timeline';

import { TimelineProjectsContext } from '@/components/Timelines/TimelineProjects/context';

import { useResourceRowContext } from './Context';





export default function ActivePanel() {
  const { activeBlockIds, weekSizeInPx, timeInterval } = useContext(
    TimelineProjectsContext,
  );
  const { resource, project } = useResourceRowContext();

  // RENDER
  const activeBlocks = useMemo(
    () =>
      filter(resource?.timeblocks, (block) =>
        activeBlockIds.includes(block?.id),
      ).sort((a, b) => a?.start?.getTime() - b?.start.getTime()),
    [activeBlockIds, resource?.timeblocks],
  );
  // get the start date of the first block and the end date of the last block
  // then calculate the width of the block in pixels
  // then set the width of the content div to that width
  // then set the left position of the content div to the start date of the first block
  const firstActiveBlock = useMemo(
    () => first(activeBlocks) ?? ({} as TTimeBlockRange),
    [activeBlocks],
  );
  const lastActiveBlock = useMemo(
    () => last(activeBlocks) ?? ({} as TTimeBlockRange),
    [activeBlocks],
  );

  const left = useMemo(
    () =>
      !isEmpty(lastActiveBlock)
        ? weeksToPixels(
            firstActiveBlock?.start,
            timeInterval.start,
            false,
            weekSizeInPx,
          )
        : 0,
    [
      firstActiveBlock?.start,
      lastActiveBlock,
      timeInterval.start,
      weekSizeInPx,
    ],
  );

  const width = useMemo(
    () =>
      !isEmpty(firstActiveBlock)
        ? weeksToPixels(
            lastActiveBlock?.end,
            firstActiveBlock?.start,
            true,
            weekSizeInPx,
          )
        : 0,
    [firstActiveBlock, lastActiveBlock?.end, weekSizeInPx],
  );

  return (
    <ActiveBlockWrapper
      capacity={resource?.capacity ?? 0}
      activeBlocks={activeBlocks}
      left={left}
      width={width}
      type={project?.type ?? PROJECT_TYPE.PROJECT}
    />
  );
}
