import { useContext, useMemo } from 'react';

import { UserContext } from '@/contexts/UserContext';

export const useWorkspaceFromContext = () => {
  const { account, workspaceId } = useContext(UserContext);
  const workspace = useMemo(() => {
    return account?.workspaces.find((w) => w.id === workspaceId);
  }, [account?.workspaces, workspaceId]);
  return { workspace };
};
