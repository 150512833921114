import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { isNil, noop } from 'lodash';
import queryString from 'query-string';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { getInvitationInfo } from '@/services/api/account';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TInvitationInfo } from '@/types/workspace';

export const INVITATION_INFO_QUERY_KEY = 'get-invitation-info';
export default function useInvitationQuery({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TInvitationInfo>) {
  const { tk: token = '' } = queryString.parse(location.search);
  const { addNotification } = useContext(NotificationsContext);
  return useQuery(
    [INVITATION_INFO_QUERY_KEY],
    () => getInvitationInfo(token as string),
    {
      enabled: enabled && !isNil(token),
      onError: (error: TResponseError) => {
        onError(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess,
      retry: false,
    },
  );
}
