import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IconCheck } from '@tabler/icons-react';
import { RightSlot } from 'react-contexify-moze';

import { titleCase } from '@/services/helpers';
import { PROJECT_STATUS } from '@/types/enums';

import { StatusIndicator } from '@/components/Indicators';
import Portal from '@/components/Portal';
import ShortcutMenu from '@/components/ShortcutMenu';


type Props = {
  menuId: string;
  status: PROJECT_STATUS;
  onClose?: (clicked?: boolean) => void;
  onItemClicked?: (status: PROJECT_STATUS) => void;
  position?: {
    x?: number;
    y?: number;
    width?: number;
  };
};
const options = [
  PROJECT_STATUS.UNCONFIRMED,
  PROJECT_STATUS.TO_DO,
  PROJECT_STATUS.IN_PROGRESS,
  PROJECT_STATUS.COMPLETED,
];
export default function ChangeStatusMenu({
  status,
  menuId,
  onItemClicked,
  onClose,
  position: { x = 0, y = 0, width = 0 } = {},
}: Props) {
  const MENU_ID = useMemo(() => `change-status-menu_${menuId}`, [menuId]);

  const menuContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => onClose, [onClose]);

  const matchStatusKey = useCallback(
    ({ key }: KeyboardEvent) => !isNaN(+key) && !!options[parseInt(key) - 1],
    [],
  );

  const handleOnItemClicked = useCallback(
    (selectedStatus: PROJECT_STATUS) => onItemClicked?.(selectedStatus),
    [onItemClicked],
  );

  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const calcWindowSize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', matchStatusKey, { capture: true });
    window.addEventListener('resize', calcWindowSize);
    return () => {
      window.removeEventListener('keydown', matchStatusKey, { capture: true });
      window.removeEventListener('resize', calcWindowSize);
    };
  }, [calcWindowSize, matchStatusKey]);

  const position = useMemo(() => {
    const menuHeight = 32 * options.length + 16;
    const top = y + menuHeight > windowSize.height ? y - menuHeight + 36 : y;
    const left = x + width > windowSize.width ? x - width : x;
    return { top, left, width };
  }, [width, windowSize.height, windowSize.width, x, y]);

  return (
    <Portal>
      <div
        ref={menuContainerRef as MutableRefObject<HTMLDivElement>}
        key={MENU_ID}
        role="menu"
        className="contexify is-submenu"
        style={{
          opacity: 1,
          top: position?.top,
          left: position?.left,
          width: position?.width,
        }}
      >
        <div
          className="contexify_item"
          style={{ width: '100%' }}
          tabIndex={-1}
          role="menuitem"
        >
          {options.map((opt, idx) => {
            return (
              <ShortcutMenu.Item
                key={opt}
                closeOnClick={false}
                keyMatch={`${idx + 1}`}
                keyHandler={() => handleOnItemClicked(opt)}
                data={{ status: opt }}
              >
                <IconCheck
                  size={20}
                  style={{
                    marginRight: 8,
                    visibility: opt !== status ? 'hidden' : 'visible',
                  }}
                />
                <StatusIndicator
                  size={20}
                  type={opt}
                  style={{ marginRight: 8, cursor: 'pointer' }}
                />
                {titleCase(opt.replace(/_/g, ' '))}
                <RightSlot>{idx + 1}</RightSlot>
              </ShortcutMenu.Item>
            );
          })}
        </div>
      </div>
    </Portal>
  );
}
