import { PropsWithChildren } from 'react';

import styles from './styles.module.css';
type SectionProps = {
  title?: string | JSX.Element;
};

export default function Section(props: PropsWithChildren<SectionProps>) {
  return (
    <div className={styles.container}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
