import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { WORKSPACE_ASSIGN_QUERY_KEY } from '@/hooks/workspace/resources/useResourceWorkspaceAssigned';
import { TResourceWorkspaceAssigned } from '@/types/timeline';
import { TUserAccount } from '@/types/workspace';

import { WorkspaceResourceListItem } from './WorspaceResourceListItem';
import styles from './styles.module.css';


export type WorkspaceResourceListProps = {
  resources?: TResourceWorkspaceAssigned[] | null;
  account?: TUserAccount | null;
  workspaceId?: string | null;
  workspaceName?: string;
};

export default function WorkspaceResourceList({
  resources = [],
  account,
  workspaceId,
  workspaceName,
}: WorkspaceResourceListProps) {
  const queryClient = useQueryClient();

  const workspace = useMemo(() => {
    return account?.workspaces.find((ws) => ws.id === workspaceId);
  }, [account?.workspaces, workspaceId]);

  const onOperationComplete = useCallback(() => {
    queryClient.invalidateQueries([WORKSPACE_ASSIGN_QUERY_KEY, workspaceId]);
  }, [queryClient, workspaceId]);

  return (
    <>
      <div className={styles.container}>
        <WorkspaceResourceListItem
          capacity={0}
          {...account}
          role={workspace?.role}
          workspaceName={workspaceName}
        />
        {resources?.map((resource) => (
          <WorkspaceResourceListItem
            key={resource.id}
            resourceId={resource.id}
            {...resource}
            onDelete={onOperationComplete}
            onUpdate={onOperationComplete}
            workspaceName={workspaceName}
          />
        ))}
      </div>
    </>
  );
}
