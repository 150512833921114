import { useContext, useEffect } from 'react';

import { DndContext } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import { UserContext } from '@/contexts/UserContext';
import { ANALYTICS_PAGE_NAME, useAnalytics } from '@/hooks/utils/useAnalytics';

import Drawer from '@/components/Drawer';
import Header from '@/components/Header';
import Layout from '@/components/Layout';
import ModalMobileExperience from '@/components/Modals/ModalMobileExperience';
import ModalTermsOfService from '@/components/Modals/ModalTermsOfService';
import TimelineResources from '@/components/Timelines/TimelineResources';
import { TimelineProvider } from '@/components/Timelines/TimelineResources/context';

export default function DashboardResourceView() {
  const { trackPage } = useAnalytics();
  const { workspaceId } = useContext(UserContext);
  useEffect(() => {
    trackPage(ANALYTICS_PAGE_NAME.PEOPLE, workspaceId as string);
  }, [workspaceId, trackPage]);

  return (
    <Layout>
      <TimelineProvider>
        <Header />
        <DndContext
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <TimelineResources />
          <Drawer />
        </DndContext>
      </TimelineProvider>
      <ModalMobileExperience />

      <ModalTermsOfService />
    </Layout>
  );
}
