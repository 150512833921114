import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { getImageFromFirebase } from '@/services/api/firebase';
import { TSuccessErrorHook } from '@/types/generic';

export const KEY = 'get-firebase-image';
export default function useImageFirebaseQuery({
  path = '',
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<string> & {
  path?: string;
  enabled?: boolean;
}) {
  return useQuery([KEY, path], () => getImageFromFirebase(path), {
    onError,
    onSuccess,
    retry: 10,
    enabled,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
