import { useCallback, useContext, useEffect, useState } from 'react';

import type { FirebaseError } from '@firebase/app';
import { getAuth, sendPasswordResetEmail } from '@firebase/auth';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '@/contexts/UserContext';
import { ROUTES } from '@/types/routes';

import BoxMessage from '@/components/BoxMessage';
import Button from '@/components/Button';
import Layout from '@/components/Layout';
import LogoHeader from '@/components/LogoHeader';
import type { FormData } from '@/components/RecoverPasswordForm';
import RecoverPasswordForm from '@/components/RecoverPasswordForm';

import styles from '../SignIn/styles.module.css';






export default function RecoverPasswordScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setSubmitted(true);
  }, [setSuccess]);

  const auth = getAuth();

  const { isLoggedIn } = useContext(UserContext);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.ROOT, { preventScrollReset: false });
    }
  }, [navigate, isLoggedIn]);

  const onSubmitFn = useCallback(
    (data: FormData) => {
      setIsLoading(true);
      const signIn = async () => {
        try {
          await sendPasswordResetEmail(auth, data.email);
          onSuccess();
          setError('');
        } catch (error) {
          const err = error as FirebaseError;
          setError(err?.code);
        } finally {
          setIsLoading(false);
        }
      };
      signIn();
    },
    [auth, onSuccess],
  );

  const [submitted, setSubmitted] = useState(false);
  const onDismissMessageError = useCallback(() => {
    setError('');
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <LogoHeader />
          </div>
          <div className={styles.right}>
            <Button
              label={t('forms:signIn.submit')}
              variant="outline"
              size="medium"
              link={{ to: ROUTES.SIGN_IN, preventScrollReset: false }}
            />
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.form}>
            <h1 className={styles.title}>{t('forms:recoverPassword.title')}</h1>
            {submitted && (
              <div className={styles.box}>
                <BoxMessage variant="success">
                  {t('forms:recoverPassword.success.message')}
                </BoxMessage>
              </div>
            )}
            {error && (
              <div className={styles.box}>
                <BoxMessage variant="error" onDismiss={onDismissMessageError}>
                  {t(`errors:firebase.${error}`)}
                </BoxMessage>
              </div>
            )}
            {!success ? (
              <>
                <RecoverPasswordForm
                  onSubmitFn={onSubmitFn}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <Button
                label={t('forms:recoverPassword.success.submit') ?? ''}
                size="large"
                link={ROUTES.SIGN_IN}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
