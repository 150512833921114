import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { t } from 'i18next';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import useDeleteProject from '@/hooks/workspace/projects/useDeleteProject';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import Input from '../../Input';


const MODAL_SCOPE = 'ModalDeleteProject-scope';
type Props = {
  name: string;
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
};
export default function ModalDeleteProject({
  isOpen,
  onClose,
  projectId,
  name = '',
}: Props) {
  const { mutate, isLoading } = useDeleteProject();
  const [value, setValue] = useState('');
  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const { disableScope, enableScope } = useHotkeysContext();
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) enableScope(MODAL_SCOPE);
    else disableScope(MODAL_SCOPE);
    return () => disableScope(MODAL_SCOPE);
  }, [disableScope, enableScope, isOpen]);

  useHotkeys(
    'enter',
    () => value && value === name && !isLoading && deleteProject(),
    { scopes: MODAL_SCOPE, enableOnFormTags: true, preventDefault: true },
  );

  const deleteProject = useCallback(() => {
    mutate({ projectId });
  }, [mutate, projectId]);

  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-delete-project-${projectId}`}
      onClose={onClose}
      title={t('common:modals.deleteProject.title')}
      footer={
        <>
          <Button
            label={t('common:modals.deleteProject.primaryCtaLabel')}
            onClick={deleteProject}
            isLoading={isLoading}
            disabled={value !== name || isLoading}
            variant="destructive"
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <div className={styles.container}>
        <div className={styles.alert}>
          <IconAlertCircleFilled size={24} />
          <p>{t('common:modals.deleteProject.description')}</p>
        </div>
        <p className={styles.actionLabel}>
          {t('common:modals.deleteProject.actionLabel', { value: name })}
        </p>
        <Input ref={ref} value={value} onChange={onChangeFn} name="destroy" />
      </div>
    </Modal>
  );
}
