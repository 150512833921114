import { useCallback, useContext, useMemo } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import type { VirtualItem } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { addWeeks, differenceInCalendarWeeks, format } from 'date-fns';
import { max, min } from 'lodash';


import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { getTimelineModeLabel } from '@/services/helpers';
import { PROJECT_COLOR, PROJECT_TYPE, WORKSPACE_MODE } from '@/types/enums';
import { TProject, TTimeBlockRange, TTimelineResource } from '@/types/timeline';

import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import styles from './styles.module.css';
import ProjectNameWithEmoji from '../../../ProjectNameWithEmoji';



type Props = {
  resource: TTimelineResource;
};
type RecapTimeBlockRange = TTimeBlockRange &
  Pick<TProject, 'name' | 'color' | 'emoji' | 'type'> & { size: number };

type FlatRecapAllocationType = {
  name: string;
  emoji?: string;
  color: PROJECT_COLOR;
  type: PROJECT_TYPE;
  allocation: number;
};
type FlatRecapType = {
  start: Date;
  end: Date;
  totalAllocation?: number;
  size?: number;
  allocations: FlatRecapAllocationType[];
};

export default function RecapBlock({ resource }: Props) {
  const { workspace: { mode } = { mode: WORKSPACE_MODE.DAYS } } =
    useContext(UserContext);

  const { layoutIsExpanded } = useContext(UIContext);
  const { virtualizer, weeks, compressedByIds, setCompressedByIds } =
    useContext(TimelineResourcesContext);
  const visibleWeeks = virtualizer.getVirtualItems();

  const onExpand = useCallback(() => {
    setCompressedByIds?.(
      compressedByIds?.filter((id) => id !== resource.id) ?? [],
    );
  }, [compressedByIds, resource.id, setCompressedByIds]);

  const flatAllocationsRecap: RecapTimeBlockRange[] = useMemo(() => {
    const arch =
      resource?.hidden?.flatMap(({ start, end, projects }) =>
        projects.flatMap(
          ({ name, color, allocation, id }) =>
            ({
              start,
              end,
              name,
              color,
              allocation,
              id,
              size: differenceInCalendarWeeks(end, start) + 1,
            }) as RecapTimeBlockRange,
        ),
      ) ?? [];

    return (
      resource?.projects
        .flatMap((project) => {
          const dt =
            project.timeblocks?.map(
              (timeblock) =>
                ({
                  ...timeblock,
                  size:
                    differenceInCalendarWeeks(timeblock.end, timeblock.start) +
                    1,
                  name: project.name,
                  emoji: project.emoji,
                  type: project.type,
                  color: project.color,
                }) as RecapTimeBlockRange,
            ) ?? [];
          return dt;
        })
        .concat(...arch)
        .sort(
          (a, b) =>
            a.start.getTime() - b.start.getTime() ||
            b.size - a.size ||
            b.allocation - a.allocation,
        )
        .flatMap((m) => {
          if (m.size === 1) return m;
          const arr: RecapTimeBlockRange[] = [];
          for (let i = 0; i < m.size; i++) {
            arr.push({
              ...m,
              start: addWeeks(m.start, i),
              end: addWeeks(m.start, i + 1),
              size: 1,
            });
          }
          return arr;
        }) ?? []
    );
  }, [resource?.hidden, resource?.projects]);

  const weekFlatRecap = useMemo(() => {
    const acc: FlatRecapType[] = [];
    visibleWeeks.forEach((visibleWeek) => {
      const wkDate = weeks[visibleWeek.index];
      const filteredFlat = flatAllocationsRecap.filter(
        (b) => b.start.getTime() === wkDate.getTime(),
      );
      if (filteredFlat.length > 0)
        acc.push({
          start: wkDate,
          end: addWeeks(wkDate, 1),
          size: 1,
          allocations: filteredFlat.map((b) => ({
            name: b.name,
            start: b.start,
            end: b.end,
            emoji: b.emoji,
            type: b.type,
            color: b.color,
            allocation: b.allocation,
          })),
        });
    });
    return acc?.sort((a, b) => a.start.getTime() - b.start.getTime()) ?? [];
  }, [flatAllocationsRecap, weeks, visibleWeeks]);

  const visibleBlocks = useMemo(() => {
    const acc: FlatRecapType[] = [];
    // let lastAcc: FlatRecapType | undefined = undefined;
    for (let i = 0; i < weekFlatRecap.length; i++) {
      acc.push({
        start: weekFlatRecap[i].start,
        end: weekFlatRecap[i].end,
        size: 1,
        totalAllocation: weekFlatRecap[i].allocations.reduce(
          (acc, a) => a.allocation + acc,
          0,
        ),
        allocations: weekFlatRecap[i].allocations.sort(
          (a, b) => b.allocation - a.allocation,
        ),
      });
    }
    return acc;
  }, [weekFlatRecap]);

  const renderAllocation = useCallback(
    (allocation: FlatRecapAllocationType, modeLabel?: string) => {
      return (
        <li key={allocation.name} className={classNames(styles.item)}>
          <div className={styles.tooltip__item__title}>
            <ProjectNameWithEmoji
              size={16}
              emojiSize={10}
              radius={4}
              type={PROJECT_TYPE.PROJECT}
              emoji={allocation.emoji}
              color={allocation.color}
            />
            <span className={styles.projectName}>{allocation.name}</span>
          </div>
          <p>
            {allocation.allocation} {modeLabel}
          </p>
        </li>
      );
    },
    [],
  );

  const renderBlock = useCallback(
    (virtualWeek: VirtualItem) => {
      const weekDay = weeks[virtualWeek.index];
      const weekBlock = visibleBlocks.find(
        (b) => b.start.getTime() === weekDay.getTime(),
      );
      if (!weekBlock) return;

      const totalAllocation: number = weekBlock.totalAllocation ?? 0;
      const shade =
        resource.capacity === 0
          ? 100
          : Math.floor(
              (100 *
                (min([max([totalAllocation, 1]), resource.capacity ?? 1]) ??
                  1)) /
                (resource.capacity ?? 1),
            );

      const isOverflow = totalAllocation > (resource?.capacity ?? 1);

      const modeLabel = getTimelineModeLabel({
        mode: mode ?? WORKSPACE_MODE.DAYS,
        isCompact: true,
      });
      return (
        <Tooltip.Provider key={virtualWeek.key} delayDuration={300}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild disabled={resource.capacity === 0}>
              <div
                aria-disabled={resource.capacity === 0}
                className={classNames(styles.totalAllocation, styles.recap, {
                  [styles.isExpanded]: layoutIsExpanded,
                })}
                style={{
                  width: `calc(${virtualWeek.size}px)`,
                  transform: `translateX(${virtualWeek.start}px)`,
                }}
              >
                <div
                  style={{
                    height: `calc(${shade}% - ${shade >= 100 ? 5 : 0}%)`,
                  }}
                  className={classNames(styles.blockContainer, styles.shaded, {
                    [styles.isExpanded]: layoutIsExpanded,
                    [styles.overflow]: isOverflow,
                  })}
                ></div>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal
              container={document.getElementById('radix-tooltip-portal')}
            >
              <Tooltip.Content className="TooltipContent" sideOffset={5}>
                <div className={styles.tooltip}>
                  <span className={styles.date}>{`${format(
                    weekBlock?.start,
                    'E, dd MMM',
                  )} - ${format(weekBlock.end, 'E, dd MMM')}`}</span>
                  <ul className={styles.list}>
                    {weekBlock?.allocations?.map((wb) =>
                      renderAllocation(wb, modeLabel),
                    )}
                  </ul>
                  <div className={styles.recapInfo}>
                    <span>Total allocated</span>
                    <span>
                      {weekBlock.totalAllocation} {modeLabel}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      styles.recapInfo,
                      styles.availableInfo,
                    )}
                  >
                    <span>Available</span>
                    <span>
                      {max([
                        Number(
                          (
                            resource.capacity -
                            (weekBlock?.totalAllocation ?? 0)
                          ).toFixed(1),
                        ),
                        0,
                      ])}{' '}
                      {modeLabel}
                    </span>
                  </div>
                </div>
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    },
    [
      weeks,
      visibleBlocks,
      resource.capacity,
      mode,
      layoutIsExpanded,
      renderAllocation,
    ],
  );

  return (
    <div
      onKeyDown={(event) => event.key === 'Enter' && onExpand()}
      tabIndex={0}
      className={classNames(styles.allocation, {
        [styles.isExpanded]: layoutIsExpanded,
      })}
      role="button"
      onClick={onExpand}
    >
      {virtualizer?.getVirtualItems().map(renderBlock)}
    </div>
  );
}
