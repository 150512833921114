import {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';


import type { TYPE } from '@components/Badge';
import Badge from '@components/Badge';
import Button from '@components/Button';
import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconDots,
  IconEraser,
  IconGripVertical,
  IconPencil,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { id } from 'date-fns/locale';
import { t } from 'i18next';
import { find } from 'lodash';
import { RightSlot, contextMenu } from 'react-contexify-moze';


import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useUnassignProjectToResource from '@/hooks/workspace/projects/useUnassignProjectToResource';
import { getCssVariable, shrinkValue } from '@/services/helpers';
import { PROJECT_TYPE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TProject, TResourceItemList } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import Avatar from '@/components/Avatar';
import ModalDeleteTeamMember from '@/components/Modals/ModalDeleteTeamMember';
import ModalEditTeamMember from '@/components/Modals/ModalEditTeamMember';
import ModalUnassign from '@/components/Modals/ModalUnassign';
import ShortcutMenu from '@/components/ShortcutMenu';

import styles from './styles.module.css';


type Props = PropsWithChildren<{
  allocation: number;
  label?: string;
  labelType?: TYPE;
  resource: TResourceItemList;
  project: TProject;
  image?: TAttachmentDataUrls;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
}>;
export default function RowLeftHeader({
  children,
  resource,
  project,
  image,
  allocation,
  label,
  labelType,
  sortHandlerProps,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>();
  const { layoutIsExpanded } = useContext(UIContext);
  const resourceId: string = resource.id;
  const resourceName: string = resource.firstName + ' ' + resource.lastName;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const projectId = project?.id;
  const projectName = project?.name;
  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const [isModalEditTeamMemberOpen, setIsModalEditTeamMemberOpen] =
    useState<boolean>(false);

  const onCloseModalDeleteTeamMember = useCallback(
    () => setIsModalDeleteTeamMemberOpen(false),
    [],
  );

  const [isModalUnassignResourceOpen, setIsModalUnassignResourceOpen] =
    useState(false);

  const onCloseModalUnassignResource = useCallback(
    () => setIsModalUnassignResourceOpen(false),
    [],
  );

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (160 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const MENU_ID = useMemo(
    () => `team-context-menu-${projectId}-${resourceId}`,
    [projectId, resourceId],
  );

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [MENU_ID, menuIsOpen],
  );

  const [isModalDeleteTeamMemberOpen, setIsModalDeleteTeamMemberOpen] =
    useState(false);

  const onCloseModalEditTeamMember = useCallback(() => {
    setIsModalEditTeamMemberOpen(false);
  }, []);

  const onOpenModalDeleteTeamMember = useCallback(
    () => setIsModalDeleteTeamMemberOpen(true),
    [],
  );
  const onOpenModalUnassignResource = useCallback(
    () => setIsModalUnassignResourceOpen(true),
    [],
  );

  const { mutate, isLoading } = useUnassignProjectToResource();

  const onUnassignresourceFn = useCallback(() => {
    mutate(
      { resourceId, projectId },
      { onSuccess: onCloseModalUnassignResource },
    );
  }, [mutate, onCloseModalUnassignResource, projectId, resourceId]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.isExpanded]: layoutIsExpanded,
      })}
    >
      <div className={styles.content}>
        <span>
          <Avatar image={image?.ref} name={resourceName} size="medium" />
        </span>
        <div className={classNames(styles.desktop)}>
          {sortHandlerProps?.activator && (
            <div
              className={classNames(
                styles.sortHandler,
                styles.sortHandlerHidden,
                {},
              )}
              {...sortHandlerProps?.listeners}
              {...sortHandlerProps?.attributes}
              ref={sortHandlerProps?.activator}
            >
              <IconGripVertical
                size={16}
                color={getCssVariable('--color-neutral-500')}
              />
            </div>
          )}

          <div className={styles.nameLabelBadgeWrapper}>
            <Avatar image={image?.ref} name={resourceName} size="medium" />

            {label && (
              <span>
                <Badge type={labelType}>{label}</Badge>
              </span>
            )}
            {children}
          </div>
          <div className={styles.allocationButtonWrapper}>
            <p
              className={classNames(styles.allocation, {
                [styles.allocationVisible]:
                  workspace?.permission !==
                  WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
              })}
            >
              {shrinkValue(allocation)}
            </p>
            {workspace?.permission ===
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
              <Button
                ref={triggerRef}
                icon={IconDots}
                variant="ghost"
                size="small"
                onClick={onClickFn}
              />
            )}
          </div>
        </div>
      </div>
      {workspace.permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
        <>
          <ShortcutMenu
            onVisibilityChange={(visible: boolean) => setMenuIsOpen(visible)}
            animation={false}
            id={MENU_ID}
          >
            <ShortcutMenu.Item
              keyHandler={() => setIsModalEditTeamMemberOpen(true)}
              keyMatch="e"
            >
              <IconPencil size={20} style={{ marginRight: 8 }} />{' '}
              {t('common:editContextMenuActions.edit')} <RightSlot>E</RightSlot>{' '}
            </ShortcutMenu.Item>
            {project.type !== PROJECT_TYPE.TIME_OFF && (
              <ShortcutMenu.Item
                keyHandler={onOpenModalUnassignResource}
                keyMatch="u"
              >
                <IconEraser size={20} style={{ marginRight: 8 }} />{' '}
                {t('common:editContextMenuActions.unassign')}{' '}
                <RightSlot>U</RightSlot>
              </ShortcutMenu.Item>
            )}
          </ShortcutMenu>
          {isModalEditTeamMemberOpen && (
            <ModalEditTeamMember
              isOpen={isModalEditTeamMemberOpen}
              onClose={onCloseModalEditTeamMember}
              resourceId={resourceId}
              image={image}
              onDelete={() => {
                onCloseModalEditTeamMember();
                onOpenModalDeleteTeamMember();
              }}
              member={{
                firstName: resource.firstName,
                lastName: resource.lastName,
                capacity: resource.capacity,
                workspaceAccess: resource.workspaceAccess,
              }}
            />
          )}
          {isModalDeleteTeamMemberOpen && (
            <ModalDeleteTeamMember
              isOpen={isModalDeleteTeamMemberOpen}
              resourceId={resourceId}
              key={`delete-resource-${id}`}
              name={`${resourceName}`}
              onClose={onCloseModalDeleteTeamMember}
              onDeleteSuccess={onCloseModalDeleteTeamMember}
            />
          )}
          {isModalUnassignResourceOpen && (
            <ModalUnassign
              modalTitle={t('common:modals.removeResource.title')}
              modalKey={`remove-${projectId}_${resourceId}`}
              isLoading={isLoading}
              title={t('common:modals.removeResource.subtitle', {
                value: resourceName,
              })}
              description={t('common:modals.removeResource.description', {
                value: projectName,
              })}
              onSave={onUnassignresourceFn}
              isOpen={isModalUnassignResourceOpen}
              onClose={onCloseModalUnassignResource}
            />
          )}
        </>
      )}
    </div>
  );
}
