import { useCallback, useContext, useState } from 'react';

import { IconCheck } from '@tabler/icons-react';

import { UserContext } from '@/contexts/UserContext';
import useUpdateAccountSettings from '@/hooks/account/useUpdateAccountSettings';

import styles from './styles.module.css';
import Button from '../Button';
import DrawerCard from '../DrawerCard';


type Props = {
  onConfirm: () => void;
  onDismiss: () => void;
  initialValue: boolean;
};
export default function WeeklyInsightsDrawer({
  onConfirm,
  onDismiss,
  initialValue = false,
}: Props) {
  const [turnedOn, setTurnedOn] = useState(initialValue);

  const { workspaceId } = useContext(UserContext);

  const { mutate } = useUpdateAccountSettings({
    onSuccess: () => {
      onConfirm();
    },
    onError: () => setTurnedOn(false),
  });

  const onTurnOn = useCallback(() => {
    setTurnedOn(true);
    mutate({ weeklyEmail: true, workspaceId: workspaceId ?? '' });
  }, [mutate, workspaceId]);

  return (
    <DrawerCard draweId="weekly-insights" title={''}>
      <div className={styles.weeklyInsightsContainer}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>
            <span className={styles.emoji}>📭</span>
            <span>Weekly insights</span>
          </p>
          <p className={styles.subTitle}>Receive weekly email</p>
        </div>
        <div className={styles.buttonWrapper}>
          {!turnedOn ? (
            <>
              <Button
                label="Dismiss"
                variant="ghost"
                size="medium"
                onClick={onDismiss}
              />
              <Button label="Turn on" size="medium" onClick={onTurnOn} />
            </>
          ) : (
            <span className={styles.turnedOnIcon}>
              <IconCheck size={20} />
            </span>
          )}
        </div>
      </div>
    </DrawerCard>
  );
}
