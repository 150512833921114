
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class EventEmitter<T = any> {
  _events: {
    [key: string]: {
      lastValue?: T;
      fns: ((data?: T) => void)[];
    };
  } = {};

  public dispatch(event: string, data?: T) {
    if (!this._events[event]) return;
    this._events[event]?.fns?.forEach((callback) => callback(data));
  }
  public subscribe(event: string, callback: (data?: T) => void) {
    if (!this.has(event))
      this._events[event] = { lastValue: undefined, fns: [] };
    this._events[event].fns.push(callback);
    callback(this._events[event].lastValue);
    return () => this.unsubscribe(event, callback);
  }

  public complete(event: string, data?: T) {
    this.dispatch(event, data);
    delete this._events[event];
  }

  public unsubscribe(event: string, callback: (data?: T) => void) {
    if (!this._events[event]) return;
    this._events[event].fns = this._events[event].fns.filter(
      (cb) => cb !== callback,
    );
  }

  public has(event: string) {
    return this._events[event] !== undefined;
  }

  public lastValue(event: string) {
    return this._events[event]?.lastValue;
  }
}
// export const eventEmitter = new EventEmitter();
