import { useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { searchResources } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TResourceItemList } from '@/types/timeline';

export const SEARCH_RESOURCES_KEY = 'search-resources';
export default function useSearchResourcesQuery({
  query,
  enabled,
  projectId,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TResourceItemList[]> & {
  query?: string;
  projectId?: string;
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  const queryKey = useMemo(() => {
    const key = [SEARCH_RESOURCES_KEY, workspaceId];
    if (query) key.push(query);
    if (projectId) key.push(projectId);
    return key;
  }, [projectId, query, workspaceId]);
  return useQuery(
    queryKey,
    () => searchResources({ projectId, query, workspaceId }),
    {
      enabled,
      onError: (error: TResponseError) => {
        onError(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess,
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
}
