import { useCallback, useRef } from 'react';

export default function useRequestAnimationFrame({
  onAnimate,
}: {
  onAnimate: (deltaTime: number) => void;
}) {
  // const [isRunning, setIsRunning] = useState<boolean>(false);
  const isRunning = useRef<boolean>(false);
  const requestRef = useRef<number>();
  const lastAnimateTime = useRef<number>();

  const stopAnimation = useCallback(() => {
    if (requestRef.current) cancelAnimationFrame(requestRef.current);
    isRunning.current = false;
    lastAnimateTime.current = undefined;
    requestRef.current = undefined;
  }, []);

  const animate = useCallback(() => {
    if (isRunning.current) {
      if (!lastAnimateTime.current) lastAnimateTime.current = Date.now();
      const newTime = Date.now();
      const deltaTime = newTime - lastAnimateTime.current;
      onAnimate(deltaTime / 1000);
      lastAnimateTime.current = newTime;
      requestRef.current = requestAnimationFrame(animate);
    }
  }, [onAnimate]);

  const startAnimation = useCallback(() => {
    if (isRunning.current) return;
    lastAnimateTime.current = Date.now();
    isRunning.current = true;

    requestRef.current = requestAnimationFrame(animate);
  }, [animate]);

  return { requestRef, startAnimation, stopAnimation, isRunning };
}
