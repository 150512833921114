import { Fragment, useContext, useMemo } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import { endOfWeek, format, startOfWeek, subDays } from 'date-fns';
import { t } from 'i18next';


import { UIContext } from '@/contexts/UIContext';
import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { INSIGHTS_TYPE, PROJECT_TYPE } from '@/types/enums';
import { TInsightsThisWeek } from '@/types/insights';

import styles from './styles.module.css';
import Avatar from '../Avatar';
import DrawerCard from '../DrawerCard';
import ProjectNameWithEmoji from '../ProjectNameWithEmoji';
import ShowMore from '../ShowMore';
import ListSkeleton from '../skeleton/ListSkeleton';


export default function ThisWeekDrawer() {
  const { drawerIsOpen } = useContext(UIContext);

  const { data: thisWeek, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.THIS_WEEK,
    min: 0,
    max: 9,
    enabled: drawerIsOpen,
  });

  const thisWeekDifference =
    (thisWeek?.count && thisWeek.count - (thisWeek?.data?.length ?? 0)) ?? 0;
  function renderThisWeekItem(item: TInsightsThisWeek, key: number) {
    const resources = item?.resources?.slice(0, 3) ?? [];
    const resourceDifference = item?.resources?.length - resources.length;
    return (
      <li className={styles.item} key={`this-week-item${key}`}>
        <div className={styles.subItem}>
          <ProjectNameWithEmoji
            type={PROJECT_TYPE.PROJECT}
            name={item?.name}
            emoji={item?.emoji}
            color={item?.color}
          />
        </div>
        <div className={classNames([styles.horizontalList, styles.avatars])}>
          {item?.resources?.length > 2 && Boolean(resourceDifference) ? (
            <p>+{Math.floor(item?.resources?.length - resources.length)}</p>
          ) : null}
          {resources?.map((resource, k) => (
            <Tooltip.Provider key={`avatar-${k}`} delayDuration={0}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <span>
                    <Avatar
                      size="medium"
                      image={resource?.image?.ref}
                      name={resource?.firstName + ' ' + resource?.lastName}
                    />
                  </span>
                </Tooltip.Trigger>
                <Tooltip.Portal
                  container={document.getElementById('radix-tooltip-portal')}
                >
                  <Tooltip.Content className="TooltipContent" sideOffset={5}>
                    {resource?.firstName + ' ' + resource?.lastName}
                    <Tooltip.Arrow className="TooltipArrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          ))}
        </div>
      </li>
    );
  }
  const title = t('common:insights.thisWeek');
  const rightTitle = useMemo(
    () =>
      `${format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'dd')} - ${format(
        subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 2),
        'd MMM',
      )}`,
    [],
  );
  return (
    <DrawerCard
      draweId="thisWeek"
      emoji="🗓️"
      title={title}
      rightTitle={rightTitle}
      isLoading={isLoading}
      loader={<ListSkeleton />}
      emptyLabel={t('common:insights.noThisWeekProjectsLabel')}
    >
      {thisWeek?.count ? (
        <>
          <ul className={styles.list}>
            {thisWeek?.data?.map(renderThisWeekItem)}
          </ul>
          {thisWeekDifference ? (
            <ShowMore
              insightsType={INSIGHTS_TYPE.THIS_WEEK}
              renderData={(data) => {
                return (
                  <ul className={styles.list}>
                    {data.map(renderThisWeekItem)}
                  </ul>
                );
              }}
              triggerLabel={`+ ${thisWeekDifference} ${t('common:more')}`}
              open={false}
              type="modal"
              titleClassName={styles.modalHeader}
              title={
                <Fragment>
                  <h2>🗓️ {title}</h2>
                  <p>{rightTitle}</p>
                </Fragment>
              }
            />
          ) : (
            false
          )}
        </>
      ) : (
        false
      )}
    </DrawerCard>
  );
}
