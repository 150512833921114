
import { PropsWithChildren } from 'react';

import { t } from 'i18next';
import { Helmet } from 'react-helmet';

type Props = PropsWithChildren<{
  title?: string;
}>;
export default function Layout({ children, title }: Props) {
  return (
    <main>
      <Helmet>
        <title>{title ? title : t('common:title')}</title>
      </Helmet>
      {children}

      <div id="radix-tooltip-portal"></div>
    </main>
  );
}
