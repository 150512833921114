import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { setDefaultOptions } from 'date-fns';
import { xor } from 'lodash';
import { isMobile } from 'react-device-detect';

import {
  TTimeBlockRange,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';

import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import ActivePanel from './ActivePanel';
import Blocks from './Blocks';
import Content from './Content';
import type { MouseEventRef } from './Context';
import ProjectRowContext from './Context';
import Cursor from './Cursor';
import Header from './Header';
import NewBlock from './NewBlock';
import styles from './styles.module.css';


type Props = {
  resource: TTimelineResource;
  project: TTimelineProject;
  disabled: boolean;
};

const ProjectRow = ({
  resource,
  project,
  children,
  disabled = false,
}: PropsWithChildren<Props>) => {
  setDefaultOptions({ weekStartsOn: 1 });
  const [hoverWeek, setHoverWeek] = useState<Date | undefined>(undefined);
  const [newBlock, setNewBlock] = useState<TTimeBlockRange | null>(null);
  const mouseEventRef = useRef<MouseEventRef>({});

  const {
    activeBlockIds,
    activeProjectId,
    setActiveBlockIds,
    setActiveProjectId,
    setActiveResourceId,
  } = useContext(TimelineResourcesContext);

  const onActiveBlockFn = useCallback(
    ({
      blockId,
      isMulti,
      projectId,
      resourceId,
    }: {
      blockId: string;
      isMulti: boolean;
      projectId: string;
      resourceId: string;
    }) => {
      if (isMobile) return;
      if (activeProjectId && isMulti && activeProjectId !== projectId) return;

      if (isMulti) {
        setActiveBlockIds((prev) => xor(prev, [blockId]));
      } else {
        setActiveBlockIds((prev) => (prev.includes(blockId) ? [] : [blockId]));
      }
      setActiveProjectId(projectId);
      setActiveResourceId(resourceId);
    },
    [
      activeProjectId,
      setActiveBlockIds,
      setActiveResourceId,
      setActiveProjectId,
    ],
  );

  useEffect(() => {
    if (activeBlockIds.length === 0) {
      setActiveProjectId(null);
      setActiveResourceId(null);
    }
  }, [activeBlockIds.length, setActiveProjectId, setActiveResourceId]);

  return (
    <ProjectRowContext.Provider
      value={{
        project,
        resource,
        setNewBlock,
        newBlock,
        hoverWeek,
        setHoverWeek,
        mouseEventRef,
        onActiveBlockFn,
        rowDisabled: disabled,
      }}
    >
      <div className={styles.container}>{children}</div>
    </ProjectRowContext.Provider>
  );
};

ProjectRow.Header = Header;
ProjectRow.Content = Content;
ProjectRow.Cursor = Cursor;
ProjectRow.Blocks = Blocks;
ProjectRow.NewBlock = NewBlock;
ProjectRow.ActivePanel = ActivePanel;

export default ProjectRow;
