import { useContext } from 'react';

import classNames from 'classnames';

import { UIContext } from '@/contexts/UIContext';
import { weeksToPixels } from '@/services/helpers';

import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import { useProjectRowContext } from './Context';
import styles from './styles.module.css';

export default function Cursor() {
  const { hoverWeek } = useProjectRowContext();

  const { weekSizeInPx, timeInterval } = useContext(TimelineResourcesContext);
  const { layoutIsExpanded } = useContext(UIContext);
  return (
    <div
      className={classNames(styles.cursor, {
        [styles.isExpanded]: layoutIsExpanded,
      })}
      style={{
        width: weekSizeInPx + 1,
        left: hoverWeek
          ? weeksToPixels(hoverWeek, timeInterval.start, false, weekSizeInPx)
          : 0,
      }}
    />
  );
}
