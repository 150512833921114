/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SyntheticEvent, useCallback, useState } from 'react';

import { KeyboardCode } from '@dnd-kit/core';
import * as Popover from '@radix-ui/react-popover';
import { IconChevronDown, IconMoodPlus, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import EmojiPicker, { Emoji, EmojiStyle, Theme } from 'emoji-picker-react';
import { t } from 'i18next';
import { noop } from 'lodash';

import styles from './styles.module.css';
import Button from '../Button';

type EmojiSelectorProps = {
  defaultValue?: string;
  onSelect?: (emoji?: string) => void;
};

export default function EmojiSelector({
  defaultValue,
  onSelect = noop,
}: EmojiSelectorProps) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [emoji, setEmoji] = useState<{ unified: string } | undefined>(
    defaultValue ? { unified: defaultValue } : undefined,
  );

  const triggerPopover = useCallback(() => {
    setIsMenuOpened((isOpen) => !isOpen);
  }, []);

  const handleKeydown = useCallback(
    (e: SyntheticEvent<HTMLDivElement, KeyboardEvent>) => {
      if (e.nativeEvent.key === KeyboardCode.Esc) setIsMenuOpened(false);
    },
    [],
  );

  return (
    <div className={styles.selectorContainer} onKeyDown={handleKeydown}>
      {isMenuOpened && (
        <div className={styles.overlay} onClick={triggerPopover}></div>
      )}
      <Popover.Root open={isMenuOpened}>
        <Popover.Trigger
          className={styles.trigger}
          onClick={triggerPopover}
          asChild={true}
        >
          <div
            className={classNames(styles.selectButton, {
              [styles.selected]: !!emoji,
              [styles.open]: isMenuOpened,
            })}
          >
            {emoji ? (
              <Emoji
                unified={emoji.unified}
                emojiStyle={EmojiStyle.NATIVE}
                size={20}
              />
            ) : (
              <IconMoodPlus size={20} />
            )}
            <IconChevronDown size={20} />
          </div>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className={styles.content}
            align="center"
            sideOffset={2}
            side="bottom"
          >
            <div>
              <div className={styles.buttonContainer}>
                <Button
                  icon={IconTrash}
                  size="medium"
                  onClick={() => {
                    setEmoji(undefined);
                    onSelect?.(undefined);
                    setIsMenuOpened(false);
                  }}
                />
              </div>
              <EmojiPicker
                height={336}
                onEmojiClick={(dt) => {
                  setEmoji(dt);
                  setIsMenuOpened(false);
                  onSelect?.(dt.unified);
                }}
                searchPlaceHolder={`${t('common:search')}...`}
                autoFocusSearch={false}
                theme={Theme.DARK}
                skinTonesDisabled
                lazyLoadEmojis={true}
                previewConfig={{
                  showPreview: false,
                }}
                emojiStyle={EmojiStyle.NATIVE}
              />
            </div>
            <Popover.Arrow height={4} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}
