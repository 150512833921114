
import { useContext } from 'react';

// eslint-disable-next-line import/named
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { find } from 'lodash';
import { isMobile } from 'react-device-detect';

import { UserContext } from '@/contexts/UserContext';
import { PROJECT_TYPE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TTimelineProject, TTimelineResource } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import ProjectRow from '../ProjectRow';

type Props = {
  project: TTimelineProject;
  resource: TTimelineResource;
  activeSortingId: UniqueIdentifier | null;
  disabled: boolean;
};
export default function SortableProject({
  project,
  resource,
  activeSortingId,
  disabled = false,
}: Props) {
  const { activeBlockIds } = useContext(TimelineResourcesContext);
  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id: project?.id,
    disabled:
      workspace?.permission !== WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ||
      project.type === PROJECT_TYPE.TIME_OFF,
  });

  const isDragging = activeSortingId === project?.id;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
  };

  return (
    <div
      ref={project?.type !== PROJECT_TYPE.TIME_OFF ? setNodeRef : null}
      style={{ ...style, position: isDragging ? 'relative' : undefined }}
    >
      <ProjectRow
        key={project.id}
        resource={resource}
        project={project}
        disabled={disabled}
      >
        <ProjectRow.Header
          sortHandlerProps={{
            listeners,
            activator: setActivatorNodeRef,
            attributes,
          }}
        />
        <ProjectRow.Content>
          {activeBlockIds.length === 0 && !disabled && <ProjectRow.Cursor />}
          <ProjectRow.Blocks />
          <ProjectRow.NewBlock />
          {!isMobile && <ProjectRow.ActivePanel />}
        </ProjectRow.Content>
      </ProjectRow>
    </div>
  );
}
