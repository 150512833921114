import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';

import { noop } from 'lodash';
import { useLocalStorage } from 'use-hooks';

import useThemeSwitcher, { THEMES } from '@/hooks/utils/useThemeSwitcher';

import { UserContext } from './UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '../hooks/utils/useAnalytics';


const UIContext = React.createContext<{
  drawerIsOpen: boolean;
  theme: THEMES;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setThemeDetection: (theme: THEMES) => void;
  layoutIsExpanded: boolean;
  onChangeLayout: () => void;
}>({
  drawerIsOpen: false,
  setDrawerOpen: noop,
  setThemeDetection: noop,
  theme: THEMES.SYSTEM,
  layoutIsExpanded: false,
  onChangeLayout: noop,
});

const UIProvider = ({ children }: PropsWithChildren) => {
  const [drawerIsOpen, setDrawerOpen] = useState(false);

  const { themeSetting: theme, setThemeDetection } = useThemeSwitcher();

  const [layoutIsExpanded, setLayoutIsExpanded] = useLocalStorage<boolean>(
    'layoutIsExpanded',
    false,
  );

  const onChangeLayout = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setLayoutIsExpanded((prevState: boolean) => !prevState);
  }, [setLayoutIsExpanded]);

  const { trackEvent } = useAnalytics();
  const { workspaceId } = useContext(UserContext);

  useEffect(() => {
    if (drawerIsOpen) {
      trackEvent(ANALYTICS_EVENTS.INSIGHT_OPENED, workspaceId as string);
    }
  }, [drawerIsOpen, trackEvent, workspaceId]);

  return (
    <UIContext.Provider
      value={{
        setDrawerOpen,
        drawerIsOpen,
        layoutIsExpanded,
        setThemeDetection,
        theme,
        onChangeLayout,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider };
