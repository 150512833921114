import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { getUsageInfo } from '@/services/api/workspace/workspace';

type UsageInfoProps = {
  enabled: boolean;
  workspaceId: string;
  onSuccess?: typeof noop;
  onError?: typeof noop;
};

export const USAGE_INFO_QUERY_KEY: string = 'get-usage-info';
export default function useUsageInfo({
  enabled = true,
  workspaceId,
  onError,
  onSuccess,
}: UsageInfoProps) {
  return useQuery(
    [USAGE_INFO_QUERY_KEY, workspaceId],
    ({ queryKey: [_, wsId] }) => getUsageInfo({ workspaceId: wsId as string }),
    {
      enabled,
      onError: () => {
        onError?.();
      },
      onSuccess: () => {
        onSuccess?.();
      },
    },
  );
}
