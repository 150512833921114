import classNames from 'classnames';
import { Emoji } from 'emoji-picker-react';
import { t } from 'i18next';

import { PROJECT_COLOR, PROJECT_TYPE } from '@/types/enums';

import styles from './styles.module.css';


type Props = {
  color?: PROJECT_COLOR;
  emoji?: string;
  type: PROJECT_TYPE;
  name?: string;
  size?: number;
  emojiSize?: number;
  radius?: number;
};
export default function ProjectNameWithEmoji({
  color,
  emoji,
  type,
  name,
  size = 24,
  emojiSize = 16,
  radius = 6,
}: Props) {
  return (
    <>
      <span
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: `${radius}px`,
        }}
        className={classNames(styles.shade, styles[`shade-${color}`])}
      >
        <Emoji unified={emoji ?? ''} size={emojiSize} />
      </span>
      {name && (
        <p className={styles.text}>
          {type === PROJECT_TYPE.TIME_OFF ? t('common:timeOff') : name}
        </p>
      )}
    </>
  );
}
