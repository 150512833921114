import { useContext } from 'react';

import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep, findIndex } from 'lodash';


import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { updateProjectsOrder } from '@/services/api/workspace/projects';
import { createChunks } from '@/services/helpers';
import {
  PAGE_SIZE,
  orderProjectsByStatus,
} from '@/services/helpers/timelines/projects';
import { PROJECT_STATUS } from '@/types/enums';
import { TPagedQuery } from '@/types/generic';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';

import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from './useProjectListWithResourcesStatusQuery';



type Props = {
  newOrder: number;
  projectId: string;
  oldStatus?: PROJECT_STATUS;
  newStatus?: PROJECT_STATUS;
};

export default function useReorderProjects() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>(
    async ({ newOrder, projectId, newStatus }: Props): Promise<void> => {
      await updateProjectsOrder({
        workspaceId,
        newOrder,
        projectId,
        newStatus,
      });
    },
    {
      onMutate: ({ newOrder, projectId, newStatus, oldStatus }) => {
        const data = queryClient.getQueryData<
          InfiniteData<
            TPagedQuery<TProjectListWithResources<TResourceItemList>>
          >
        >([PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId]);
        if (!data) return;

        let allResults = data?.pages?.flatMap((page) => page.results);
        if (!allResults) return;
        allResults = orderProjectsByStatus(allResults);
        const projectIndex = allResults.findIndex((pr) => pr.id === projectId);

        const firstStatusResIdx =
          oldStatus === newStatus
            ? findIndex(allResults, {
                status: oldStatus,
              })
            : findIndex(allResults, {
                status: newStatus,
              });

        allResults.splice(
          firstStatusResIdx + newOrder - 1,
          0,
          allResults.splice(projectIndex, 1)[0],
        );

        createChunks(allResults, PAGE_SIZE)?.forEach(
          (res, idx) => (data.pages[idx].results = res),
        );
        queryClient.setQueryData(
          [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
          cloneDeep(data),
        );
      },
      onError: (error: Error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
