import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { SEARCH_RESOURCES_KEY } from '@/hooks/workspace/resources/useSearchResourcesQuery';
import { addNewResource } from '@/services/api/workspace/resources';
import { TWorkspaceAccess } from '@/types/workspace';

type Props = {
  firstName: string;
  lastName: string;
  capacity: number;
  workspaceAccess: TWorkspaceAccess;
  image: {
    filePath: string;
  };
};

export default function useAddNewResource() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>(
    async ({
      firstName,
      lastName,
      capacity,
      image,
      workspaceAccess,
    }: Props): Promise<void> => {
      await addNewResource({
        workspaceId,
        firstName,
        lastName,
        capacity,
        image,
        workspaceAccess,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RESOURCES_QUERY_KEY]);
        queryClient.invalidateQueries([SEARCH_RESOURCES_KEY]);
      },
      onError: (error: Error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
