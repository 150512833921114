import { useContext } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getCompletedProjects } from '@/services/api/workspace/projects';
import {
  TPagedQuery,
  TResponseError,
  TSuccessErrorHook,
} from '@/types/generic';
import { TCompletedProject } from '@/types/timeline';

const PAGE_SIZE = import.meta.env.VITE_PAGE_SIZE as number ?? 15;

export const COMPLETED_PROJECT_QUERY = 'get-completed-projects';
export default function useCompletedQuery({
  query,
  enabled = true,
  onError = noop,
}: TSuccessErrorHook<TPagedQuery<TCompletedProject>> & {
  query?: string;
  page?: number;
  pageSize?: number;
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);
  const KEY = [COMPLETED_PROJECT_QUERY, workspaceId, query];
  return useInfiniteQuery(
    KEY,
    async ({ pageParam = 1, queryKey: [_, wsId, q] }) => {
      if (wsId)
        return await getCompletedProjects({
          workspaceId: wsId as string,
          q: q as string,
          page: pageParam as number,
          pageSize: PAGE_SIZE,
        });
    },
    {
      enabled,
      onError: (error: TResponseError) => {
        onError(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      retry: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage?.nextPage ?? null,
      getPreviousPageParam: (firstPage) => firstPage?.previousPage || null,
    },
  );
}
