import { useContext, createContext, MutableRefObject } from 'react';

import { noop } from 'lodash';

import {
  TTimeBlockRange,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';

export type MouseEventRef = {
  mouseDownX?: number;
  mouseMoveX?: number;
  newInterval?: Interval;
  projectId?: string;
};

type Interval = {
  start: Date;
  end: Date;
};

const Context = createContext<{
  project?: TTimelineProject;
  resource?: TTimelineResource;
  mouseEventRef: MutableRefObject<MouseEventRef>;
  hoverWeek?: Date;
  rowDisabled: boolean;
  newBlock: TTimeBlockRange | null;
  setNewBlock: React.Dispatch<React.SetStateAction<TTimeBlockRange | null>>;
  setHoverWeek: React.Dispatch<React.SetStateAction<Date | undefined>>;
  onActiveBlockFn: ({
    blockId,
    isMulti,
    projectId,
    resourceId,
  }: {
    blockId: string;
    isMulti: boolean;
    projectId: string;
    resourceId: string;
  }) => void;
}>({
  mouseEventRef: { current: {} },
  newBlock: null,
  setNewBlock: noop,
  rowDisabled: false,
  resource: undefined,
  project: undefined,
  hoverWeek: undefined,
  setHoverWeek: noop,
  onActiveBlockFn: noop,
});

export function useProjectRowContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'ProjectRowContext must be used within a ProjectRowProvider',
    );
  }
  return context;
}

export default Context;
