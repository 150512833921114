import classNames from 'classnames';

import styles from './styles.module.css';
type Props = {
  children: string;
  size?: 'small' | 'medium';
  type?: 'alert' | 'informative' | 'success' | 'critical' | 'neutral';
};
export default function Chip({
  children,
  size = 'medium',
  type = 'neutral',
}: Props) {
  return (
    <div className={classNames([styles.container, styles[type], styles[size]])}>
      {children}
    </div>
  );
}
