import { useContext, useMemo } from 'react';

import { t } from 'i18next';

import { UIContext } from '@/contexts/UIContext';
import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { INSIGHTS_TYPE } from '@/types/enums';

import styles from './styles.module.css';
import DrawerCard from '../DrawerCard';
import InsightChart from '../InsightChart';
import ChartSkeleton from '../skeleton/ChartSkeleton';



type Props = {
  footer?: JSX.Element;
};

export default function ActiveProjectsDrawer({ footer }: Props) {
  const drawerId = 'activeProjects';
  const { drawerIsOpen } = useContext(UIContext);
  const { data, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.ACTIVE_PROJECTS,
    enabled: drawerIsOpen,
  });

  const activeProjectChartData = useMemo(
    () =>
      data?.lastWeeks?.map((item) => ({
        date: item?.startDate,
        value: item?.activeProjectsCount,
        endDate: item?.endDate,
      })) ?? [],
    [data?.lastWeeks],
  );
  return (
    <DrawerCard
      draweId={drawerId}
      isLoading={isLoading}
      loader={<ChartSkeleton />}
      emoji="🔥"
      title={t('common:insights.activeProjects')}
    >
      <div className={styles.valueData}>
        <p className={styles.chartNumber}>{data?.today?.activeProjectsCount}</p>
      </div>
      <div className={styles.chart}>
        {activeProjectChartData?.length ? (
          <InsightChart data={activeProjectChartData ?? []} />
        ) : (
          false
        )}
      </div>
      <div className={styles.chartFooter}>{footer}</div>
    </DrawerCard>
  );
}
