import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY  } from '@/hooks/workspace/resources/useResourcesQuery';
import { updateResourceImage } from '@/services/api/workspace/resources';
import { TResources } from '@/types/timeline';

type Props = {
  resourceId: string;
  name: string;
  filePath: string;
  url: string;
};

export default function useUpdateResourceImage() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<TResources | undefined, Error, Props>(
    async ({ filePath, resourceId }: Props): Promise<TResources | undefined> =>
      await updateResourceImage({
        workspaceId,
        filePath,
        resourceId,
      }),
    {
      onSuccess: (data) => {
        const allResources = queryClient.getQueryData<TResources[]>([
          RESOURCES_QUERY_KEY,
          workspaceId,
        ]);

        queryClient.invalidateQueries<TResources[]>([
          PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
          workspaceId,
        ]);

        const newResources =
          allResources?.map((resource) => {
            return resource.id === data?.id
              ? { ...resource, image: data?.image }
              : resource;
          }) ?? [];
        queryClient.setQueryData<TResources[]>(
          [RESOURCES_QUERY_KEY, workspaceId],
          newResources,
        );
        // queryClient.invalidateQueries([RESOURCES_QUERY_KEY]);
      },
      onError: (_error: Error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description: t('errors:generic.description') as string,
          //             error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
