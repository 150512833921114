import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';


import { NotificationsContext } from '@/contexts/NotificationContext';
import { createWorkspace } from '@/services/api/workspace/workspace';
import { TUserAccount } from '@/types/workspace';

import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';


type CreateWorkspaceOptions = {
  onSuccess?: typeof noop;
  onError?: typeof noop;
  onMutate?: typeof noop;
};

export default function useCreateWorkspace({
  onSuccess = noop,
  onError = noop,
  onMutate = noop,
}: CreateWorkspaceOptions = {}) {
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<TUserAccount, Error, { name: string }>(
    async ({ name }: { name: string }) => {
      return await createWorkspace({ name });
    },
    {
      onError: (error) => {
        onError();
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess: async (data: TUserAccount) => {
        queryClient.setQueryData([ACCOUNT_QUERY_KEY], data);
        onSuccess && onSuccess(data);
      },
      onMutate,
    },
  );
}
