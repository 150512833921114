import { useCallback, useContext, useEffect, useRef, useState } from 'react';



import Badge from '@components/Badge';
import { IconSearch } from '@tabler/icons-react';
import classNames from 'classnames';
import { Emoji } from 'emoji-picker-react';
import { t } from 'i18next';
import { noop } from 'lodash';
import {
  Item,
  Menu,
  RightSlot,
  Separator,
  TriggerEvent,
  contextMenu,
} from 'react-contexify-moze';
import { isMobile } from 'react-device-detect';
import { ClipLoader } from 'react-spinners';
import { useDebounce } from 'use-hooks';


import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useUnhideProjectToResource from '@/hooks/workspace/projects/useUnhideProjectToResource';
import useHiddenQuery from '@/hooks/workspace/resources/useHiddenQuery';
import { getCssVariable, truncate } from '@/services/helpers';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';

import styles from './styles.module.css';
import Input from '../Input';
import Portal from '../Portal';


type Props = {
  projectsNumber: number;
  id: string;
};

export default function HiddenHeader({ projectsNumber, id }: Props) {
  const { layoutIsExpanded } = useContext(UIContext);

  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>();

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const [queryIsEnabled, setQueryIsEnabled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { workspace } = useContext(UserContext);

  const {
    data: allProjects,
    isLoading,
    isFetched,
  } = useHiddenQuery({
    query: debouncedValue.length > 2 ? debouncedValue : '',
    enabled: queryIsEnabled || debouncedValue.length > 2,
    resourceId: id,
    onError: noop,
  });

  const MENU_ID = `unhide-project-context-menu-${id}`;

  function getMenuPosition() {
    const { bottom } = triggerRef?.current?.getBoundingClientRect() ?? {
      bottom: 0,
    };
    menuPositionRef.current = { x: 8, y: bottom + 8 };
    return menuPositionRef.current;
  }

  const eventRef = useRef<TriggerEvent>();

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setQueryIsEnabled(true);
      eventRef.current = e;
      if (!isMenuOpen && isFetched) {
        contextMenu?.show({
          id: MENU_ID,
          event: e,
          position: getMenuPosition(),
        });
      } else {
        contextMenu?.hideAll();
      }
    },
    [MENU_ID, isFetched, isMenuOpen],
  );

  useEffect(() => {
    if (isFetched && eventRef.current) {
      contextMenu?.show({
        id: MENU_ID,
        event: eventRef?.current as TriggerEvent,
        position: getMenuPosition(),
      });
    }
  }, [MENU_ID, isFetched]);

  const [unhiddenProjectsId, setUnhiddenProjectsId] = useState<string | null>(
    null,
  );
  const onChangeFn = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const { mutate, isLoading: isLoadingMutate } = useUnhideProjectToResource();

  const ref = useRef<HTMLInputElement>(null);

  const onProjectClickFn = useCallback(
    (projectId: string) => {
      setUnhiddenProjectsId(projectId);
      mutate(
        { resourceId: id, projectId },
        {
          onSuccess: () => {
            contextMenu.hideAll();
            setUnhiddenProjectsId(null);
          },
        },
      );
    },
    [id, mutate],
  );

  return (
    <>
      <button
        ref={triggerRef}
        onClick={
          workspace?.permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
            ? onClickFn
            : noop
        }
        className={classNames([styles.container], {
          [styles.isExpanded]: layoutIsExpanded,
        })}
        style={{
          pointerEvents:
            workspace?.permission ===
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && !isMobile
              ? 'auto'
              : 'none',
        }}
      >
        <div className={styles.content}>
          <span>
            <Badge type={'white'}>{truncate('Hidden' as string, 12)}</Badge>
          </span>
          <div className={classNames([styles.desktop, styles.leftPadding])}>
            <div className={styles.nameLabelBadgeWrapper}>
              <span className={classNames(styles.shade)}>
                <Emoji unified={'1f4e6'} size={14} />
              </span>
              <p>
                {t('common:hidden')}{' '}
                {projectsNumber ? <span>({projectsNumber})</span> : false}
              </p>
            </div>
          </div>
        </div>
      </button>
      <Portal>
        <Menu
          animation={false}
          className={classNames(styles.menu, 'top-triangle')}
          id={MENU_ID}
          onVisibilityChange={(isVisible) => {
            setValue('');
            // setQueryIsEnabled(!isVisible);
            setIsMenuOpen(isVisible);
          }}
        >
          <Item
            preventCloseOnKeyDown
            className="unstyled-item"
            style={{ opacity: 1 }}
            disabled
            closeOnClick={false}
          >
            <Input
              ref={ref}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              LeadingIcon={<IconSearch size={20} />}
              isDark
              name="input"
              placeholder={`${t('common:search')}...`}
              value={value}
              onChange={onChangeFn}
            />
          </Item>
          <Separator />

          {allProjects?.length ? (
            allProjects?.map((project, index) => (
              <Item
                key={index}
                tabIndex={index}
                closeOnClick={false}
                className={styles.item}
                onClick={() => onProjectClickFn(project?.id)}
              >
                {isLoadingMutate && unhiddenProjectsId === project?.id && (
                  <ClipLoader
                    color={getCssVariable('--color-neutral-500')}
                    cssOverride={{
                      borderWidth: '2px',
                      marginRight: 4,
                    }}
                    size={16}
                    speedMultiplier={0.8}
                  />
                )}
                {project.name}
                <RightSlot>{t('common:unhide')}</RightSlot>
              </Item>
            ))
          ) : isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '10px 0',
              }}
              className={styles.loader}
            >
              <ClipLoader
                color={getCssVariable('--color-neutral-500')}
                cssOverride={{
                  borderWidth: '2px',
                }}
                size={20}
                speedMultiplier={0.8}
              />
            </div>
          ) : (
            <Item disabled>{t('common:noProjectToAssign')}</Item>
          )}
        </Menu>
      </Portal>
    </>
  );
}
