import Input from '@components/Input';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';

import styles from './styles.module.css';




export type CreateWorkspaceFormData = { name: string };

type Props = {
  /**
   * Function to call when the form is submitted
   * @param data
   */
  onSubmitFn: (data: CreateWorkspaceFormData) => void;
  /**
   * Loading state of the button
   */
  isLoading?: boolean;
};

export default function CreateWorkspaceForm({ onSubmitFn, isLoading }: Props) {
  const { t } = useTranslation(['common', 'forms']);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateWorkspaceFormData>({ mode: 'onSubmit' });

  const name = watch('name');
  return (
    <form onSubmit={handleSubmit(onSubmitFn)} noValidate>
      <div className={classNames(styles.field, styles['margin-bottom-8'])}>
        <Controller
          rules={{
            required: {
              value: true,
              message: '',
            },
            validate: (value) => !!value.trim(),
          }}
          control={control}
          name="name"
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <Input
                onBlur={onBlur}
                name={name}
                type="email"
                size="large"
                value={value}
                label={t('forms:workspace.create.name.label') as string}
                placeholder={
                  t('forms:workspace.create.name.placeholder') as string
                }
                onChange={onChange}
                error={errors?.name?.message as string}
              />
            );
          }}
        />
        <p className={styles['label']}>
          {t('forms:workspace.create.name.description')}
        </p>
      </div>

      <Button
        label={t('forms:workspace.create.submit') ?? ''}
        isLoading={isLoading}
        size="large"
        disabled={!name?.length}
      />

      <div className={styles.cta}></div>
    </form>
  );
}
