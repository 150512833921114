import { uniqBy } from 'lodash';

import { PROJECT_STATUS } from '@/types/enums';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';



// export const PAGE_SIZE = 15;
export const PAGE_SIZE = (import.meta.env.VITE_PAGE_SIZE as number) ?? 15;
export const ProjectStatusEnumOrder = Object.keys(PROJECT_STATUS);

/**
 * This function merges two lists of project data, updating existing projects and resources with new data and adding new projects and resources.
 *
 * @param {TProjectListWithResources[]} oldData - The existing list of projects.
 * @param {TProjectListWithResources[]} newData - The new list of projects.
 * @returns {TProjectListWithResources[]} - The merged list of projects.
 */

export function mergeProjectData(
  oldData: TProjectListWithResources<TResourceItemList>[] = [],
  newData: TProjectListWithResources<TResourceItemList>[] = [],
): TProjectListWithResources<TResourceItemList>[] {
  // The function uses the reduce method to iterate over the new data.
  return newData.reduce(
    (
      prevProject: TProjectListWithResources<TResourceItemList>[],
      newProject: TProjectListWithResources<TResourceItemList>,
    ) => {
      // For each new project, it checks if the project already exists in the old data.
      const existingProject = prevProject.find(
        (project: TProjectListWithResources<TResourceItemList>) =>
          project.id === newProject.id,
      );
      if (existingProject) {
        // If the project exists, it updates the project with the new data.
        const updatedProject = {
          ...existingProject,
          resources: newProject.resources.reduce(
            (
              prevResources: TResourceItemList[],
              newResource: TResourceItemList,
            ) => {
              // For each new resource, it checks if the resource already exists in the old data.
              const existingResource = prevResources.find(
                (resource: TResourceItemList) => resource.id === newResource.id,
              );
              if (existingResource) {
                // If the resource exists, it updates the resource with the new data.
                const updatedResource = {
                  ...existingResource,
                  timeblocks: uniqBy(
                    [
                      ...(newResource?.timeblocks ?? []),
                      ...(existingResource?.timeblocks ?? []),
                    ],
                    'id',
                  ),
                };
                // It then returns the updated list of resources.
                return prevResources.map((resource: TResourceItemList) =>
                  resource.id === updatedResource.id
                    ? updatedResource
                    : resource,
                );
              } else {
                // If the resource does not exist, it adds the new resource to the list.
                return [...prevResources, newResource];
              }
            },
            existingProject.resources,
          ),
        };
        // It then returns the updated list of projects.
        return prevProject.map(
          (project: TProjectListWithResources<TResourceItemList>) =>
            project.id === updatedProject.id ? updatedProject : project,
        );
      } else {
        // If the project does not exist, it adds the new project to the list.
        return [...prevProject, newProject];
      }
    },
    oldData,
  );
}


export function orderProjectsByStatus<T extends { status?: PROJECT_STATUS }>(
  data: T[],
) {
  if (!data?.length) return data;
  data.sort((a, b) => {
    return (
      ProjectStatusEnumOrder.indexOf(a.status!) -
      ProjectStatusEnumOrder.indexOf(b.status!)
    );
  });
  return data;
}
