import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from './styles.module.css';

type SelectDropdownLabelProps = {
  children?: React.ReactNode;
};

const Label = ({ children }: SelectDropdownLabelProps) => {
  return (
    <DropdownMenu.Label className={styles.label}>{children}</DropdownMenu.Label>
  );
};

export default Label;
