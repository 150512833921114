/**
 * Determine if user is logged in and redirect to login page if not logged in
 * after login, redirect to the page user was trying to access.
 * @param children
 */

import { useContext } from "react";

import { useLocation, Navigate } from "react-router";

import { UserContext } from "./contexts/UserContext";
import { ROUTES } from "./types/routes";

export function PrivateRoute({ children }: { children: React.ReactElement }) {
  const { isLoggedIn } = useContext(UserContext);

  const location = useLocation();
  const redirectRoute = `${location?.pathname}${location?.search}`;
  return isLoggedIn ? (
    children
  ) : (
    <Navigate state={{ redirectRoute }} to={ROUTES.SIGN_IN} />
  );
}
