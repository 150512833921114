/* eslint-disable import/named */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';


import * as Dialog from '@radix-ui/react-dialog';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import classNames from 'classnames';
import { addMonths, format } from 'date-fns';
import { debounce } from 'lodash';
import { animated, useTransition } from 'react-spring';

import { CollapsibleDrawer } from '@/contexts/CollapsibleDrawer';
import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';

import ActiveProjectsDrawer from './ActiveProjectsDrawer';
import OverburdenedDrawer from './OverburdenedDrawer';
import StartingSoonDrawer from './StartingSoonDrawer';
import ThisWeekDrawer from './ThisWeekDrawer';
import TopProjectDrawer from './TopProjectDrawer';
import WeeklyInsightsDrawer from './WeeklyInsightsDrawer';
import WhosFreeDrawer from './WhosFreeDrawer';
import WorkloadDrawer from './WorkloadDrawer';
import styles from './styles.module.css';


const Drawer = () => {
  const { drawerIsOpen, setDrawerOpen } = useContext(UIContext);
  const { account, workspace } = useContext(UserContext);

  const initialWeeklyInsightsAnswered = useMemo(
    () => ({
      answered: workspace?.weeklyEmail ?? false,
      value: workspace?.weeklyEmail ?? false,
    }),
    [workspace?.weeklyEmail],
  );
  const [turnedOn, setTurnedOn] = useLocalStorage<
    { answered: boolean; value: boolean } | undefined
  >(`weekly-insights-${account?.email}`, initialWeeklyInsightsAnswered);

  useEffect(() => {
    if (workspace?.weeklyEmail) {
      setTurnedOn({ answered: true, value: workspace?.weeklyEmail });
    }
  }, [setTurnedOn, turnedOn?.answered, workspace?.weeklyEmail]);

  const months = useMemo(() => {
    const today = new Date();
    const months = new Set<string>();
    for (let i = -3; i <= 3; i++) {
      months.add(format(addMonths(today, i), 'MMM'));
    }
    return months;
  }, []);

  const [isAnimation, setIsAnimation] = useState<boolean>(false);

  const transitions = useTransition(drawerIsOpen, {
    from: { opacity: 1, x: 368 },
    enter: { opacity: 1, x: 0 },
    onStart: () => setIsAnimation(true),
    onDestroyed: () => setIsAnimation(false),
    leave: { opacity: 1, x: 368 },
    config: { tension: 600, friction: 40, clamp: true },
  });

  const ChartFooter = useMemo(() => {
    return (
      <>
        {Array.from(months).map((month) => (
          <p key={month}>{month}</p>
        ))}
      </>
    );
  }, [months]);

  const [disappear, setDisappear] = useState<boolean>(false);
  const debouncedTurnOnFn = useMemo(
    () => debounce(() => setTurnedOn({ answered: true, value: true }), 500),
    [setTurnedOn],
  );
  const onDismissFn = useCallback(() => {
    setDisappear(true);
    debounce(() => setTurnedOn({ answered: true, value: false }), 300)();
  }, [setTurnedOn]);
  const onConfirmFn = useCallback(() => {
    debounce(() => {
      setDisappear(true);
      debouncedTurnOnFn();
    }, 500)();
  }, [debouncedTurnOnFn]);

  return (
    <Dialog.Root modal={false} open={drawerIsOpen} onOpenChange={setDrawerOpen}>
      {transitions((style, item) =>
        item ? (
          <Dialog.Content
            forceMount
            asChild
            onPointerDownOutside={(event) => event.preventDefault()}
            onInteractOutside={(event) => event.preventDefault()}
          >
            <animated.div
              style={style}
              className={classNames(styles.container, {
                [styles.animation]: isAnimation,
              })}
            >
              <CollapsibleDrawer>
                <div className={styles.bluredOverlay}></div>
                {(!turnedOn || !turnedOn.answered) && (
                  <div
                    className={classNames(styles.disappearingDrawerWrapper, {
                      [styles.disappearingDrawer]: disappear,
                    })}
                  >
                    <WeeklyInsightsDrawer
                      onDismiss={onDismissFn}
                      onConfirm={onConfirmFn}
                      initialValue={turnedOn?.value ?? false}
                    />
                  </div>
                )}
                <WorkloadDrawer />
                <ActiveProjectsDrawer footer={ChartFooter} />
                {/* <CapacityDrawer footer={ChartFooter} /> */}
                <WhosFreeDrawer />
                <OverburdenedDrawer />
                <ThisWeekDrawer />
                <StartingSoonDrawer />
                <TopProjectDrawer />
              </CollapsibleDrawer>
            </animated.div>
          </Dialog.Content>
        ) : null,
      )}
    </Dialog.Root>
  );
};

export default Drawer;
