import { useBlockContext } from './Context';
import BlockTooltip from '../../../Blocks/BlockTooltip';
export default function Tooltip() {
  const { localProps, shouldShowTooltip } = useBlockContext();

  return shouldShowTooltip ? (
    <BlockTooltip startDate={localProps?.start} endDate={localProps?.end} note={localProps?.note} />
  ) : (
    false
  );
}
