import React, { useCallback, useEffect, useState } from 'react';

import Input from '@components/Input';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { validate as EmailValidator } from 'email-validator';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

import { INVITATION_INFO_QUERY_KEY } from '@/hooks/account/useInvitationQuery';
import { TInvitationInfo } from '@/types/workspace';

import Button from '@/components/Button';

import styles from './styles.module.css';
import Checkbox from '../Checkbox';



export type SignUpFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  consent: boolean;
};

type Props = {
  /**
   * Function to call when the form is submitted
   * @param data
   */
  onSubmitFn: (formData: SignUpFormData, html: string) => void;
  /**
   * Loading state of the button
   */
  isLoading?: boolean;
};

export default function SignUpForm({ onSubmitFn, isLoading }: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<TInvitationInfo>([
    INVITATION_INFO_QUERY_KEY,
  ]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignUpFormData>({ mode: 'onSubmit' });

  useEffect(() => {
    if (!data) return;
    setValue('email', (data?.email as string) ?? '');
    setValue('firstName', (data?.firstName as string) ?? '');
    setValue('lastName', (data?.lastName as string) ?? '');
  }, [data, setValue]);

  const onTogglePassword = useCallback(
    (e: React.SyntheticEvent) => {
      e?.preventDefault();
      setShowPassword((old) => !old);
    },
    [setShowPassword],
  );
  const [formHtml, setFormHtml] = useState<string>('');

  useEffect(() => {
    const signUpForm = document.getElementById('sign-up-form');
    if (signUpForm?.outerHTML) setFormHtml(signUpForm.outerHTML);
  }, []);

  return (
    <div className={styles.container}>
      <form
        id="sign-up-form"
        onSubmit={handleSubmit((formData) =>
          onSubmitFn(formData, formHtml ?? ''),
        )}
      >
        <div className={styles.group}>
          <div className={styles.half}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: t('forms:signUp.firstName.required'),
                },
                validate: (value) => !!value,
              }}
              control={control}
              name="firstName"
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    onBlur={onBlur}
                    name={name}
                    size="large"
                    type="text"
                    value={value}
                    autoComplete="given-name"
                    label={t('forms:signUp.firstName.label') as string}
                    placeholder={
                      t('forms:signUp.firstName.placeholder') as string
                    }
                    onChange={onChange}
                    error={errors?.firstName?.message as string}
                  />
                );
              }}
            />
          </div>
          <div className={styles.half}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: t('forms:signUp.lastName.required'),
                },
                validate: (value) => !!value,
              }}
              control={control}
              name="lastName"
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    onBlur={onBlur}
                    name={name}
                    size="large"
                    type="text"
                    value={value}
                    autoComplete="family-name"
                    label={t('forms:signUp.lastName.label') as string}
                    placeholder={
                      t('forms:signUp.lastName.placeholder') as string
                    }
                    onChange={onChange}
                    error={errors?.lastName?.message as string}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className={styles.group}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:signIn.email.required'),
              },
              validate: (value) => {
                if (!EmailValidator(value)) {
                  return t('forms:signIn.email.invalid') as string;
                }
                return true;
              },
            }}
            control={control}
            name="email"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  disabled={!!data?.email}
                  onBlur={onBlur}
                  name={name}
                  size="large"
                  type="text"
                  value={value}
                  autoComplete="email"
                  label={t('forms:signUp.email.label') as string}
                  placeholder={t('forms:signUp.email.placeholder') as string}
                  onChange={onChange}
                  error={errors?.email?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.group}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:signUp.password.required'),
              },
              validate: (value) => !!value,
            }}
            control={control}
            name="password"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  TrailingIcon={
                    <Button
                      icon={showPassword ? IconEyeClosed : IconEye}
                      variant="ghost"
                      onClick={onTogglePassword}
                    />
                  }
                  onBlur={onBlur}
                  name={name}
                  size="large"
                  value={value}
                  type={showPassword ? 'text' : 'password'}
                  label={t('forms:signUp.password.label') as string}
                  placeholder={t('forms:signUp.password.placeholder') as string}
                  onChange={onChange}
                  error={errors?.password?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.group}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:signUp.terms.required'),
              },
            }}
            control={control}
            name="consent"
            render={({ field: { value, name, onChange } }) => {
              return (
                <Checkbox
                  value={value}
                  name={name}
                  onChange={onChange}
                  error={errors?.consent?.message}
                  label={
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('forms:signUp.terms.label', {
                          POLICY_URL: import.meta.env.VITE_PRIVACY_POLICY_URL,
                          TOS_URL: import.meta.env.VITE_TOS_URL,
                        }),
                      }}
                    ></p>
                  }
                />

                // <Input
                //   TrailingIcon={
                //     <Button icon={showPassword ? IconEyeClosed : IconEye} variant="ghost" onClick={onTogglePassword} />
                //   }
                //   onBlur={onBlur}
                //   name={name}
                //   size="large"
                //   value={value}
                //   type={showPassword ? 'text' : 'password'}
                //   label={t('forms:signUp.password.label') as string}
                //   placeholder={t('forms:signUp.password.placeholder') as string}
                //   onChange={onChange}
                //   error={errors?.password?.message as string}
                // />
              );
            }}
          />
        </div>

        <div
          className={styles.button}
          style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
        >
          <Button
            label={t('forms:signUp.submit') as string}
            isLoading={isLoading}
            size="large"
          />
        </div>
      </form>
    </div>
  );
}
