import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { ACCOUNT_QUERY_KEY  } from '@/hooks/account/useAccountQuery';
import { deleteAccountImage } from '@/services/api/account';


export default function useDeleteAccountImage() {
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation(async () => await deleteAccountImage(), {
    onSuccess: () => {
      queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
