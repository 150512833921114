import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Portal } from '@headlessui/react';
import { IconChevronDown, IconSearch } from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import {
  Item,
  Menu,
  Separator,
  TriggerEvent,
  useContextMenu,
} from 'react-contexify-moze';
import { ClipLoader } from 'react-spinners';
import { useDebounce } from 'use-hooks';

import useSearchResourcesQuery from '@/hooks/workspace/resources/useSearchResourcesQuery';
import { getCssVariable } from '@/services/helpers';
import { TResourceItemList } from '@/types/timeline';

import Input from '@/components/Input';

import styles from './styles.module.css';

type SelectResourceProps = {
  queryEnabled: boolean;
  onResourceSelected?: (resource: TResourceItemList) => void;
  label?: JSX.Element | string;
  selectedResourceId?: string;
};
const MENU_ID = 'search-resource';
export default function SelectResource({
  queryEnabled,
  onResourceSelected,
  selectedResourceId,
  label,
}: SelectResourceProps) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>();
  const { show, hideAll } = useContextMenu({ id: MENU_ID });
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const [menuWidth, setMenuWidth] = useState(
    Number(getCssVariable('--sidebar-width')?.replace('px', '')),
  );
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function getMenuPosition(boundingRect?: DOMRect) {
    const { bottom, width, x } = boundingRect ?? {
      left: 0,
      bottom: 0,
      width: 0,
    };
    menuPositionRef.current = { x: x ?? width, y: bottom + 4 };
    return menuPositionRef.current;
  }

  const ref = useRef<HTMLInputElement>(null);

  const onChangeFn = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const { data: resources, isLoading } = useSearchResourcesQuery({
    query: debouncedValue,
    enabled: queryEnabled,
  });

  const selectedResource = useMemo(
    () => resources?.find(({ id }) => selectedResourceId === id),
    [resources, selectedResourceId],
  );
  const eventRef = useRef<TriggerEvent>();

  const onResourceClick = useCallback(
    (resource: TResourceItemList) => {
      onResourceSelected?.(resource);
    },
    [onResourceSelected],
  );

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) hideAll();
      else {
        const boundingRect = triggerRef.current?.getBoundingClientRect();
        setMenuWidth(boundingRect?.width ?? 400);
        eventRef.current = e;

        show({
          id: MENU_ID,
          event: e,
          position: getMenuPosition(boundingRect),
        });
      }
    },
    [hideAll, menuIsOpen, show],
  );

  const handleEscButton = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        hideAll();
      }
    },
    [hideAll],
  );
  useEffect(() => {
    if (menuIsOpen) {
      const opt = { capture: true };
      window.addEventListener('keydown', handleEscButton, opt);
      return () => {
        window.removeEventListener('keydown', handleEscButton, opt);
      };
    }
  }, [handleEscButton, menuIsOpen]);

  return (
    <div className={styles.searchResources}>
      {label ? (
        React.isValidElement(label) ? (
          label
        ) : (
          <label>{label}</label>
        )
      ) : undefined}
      <button
        ref={triggerRef}
        onClick={onClickFn}
        className={classNames(styles.selectButton, {
          [styles.selected]: !!selectedResource,
          [styles.open]: menuIsOpen,
        })}
      >
        <span>
          {selectedResource
            ? `${selectedResource.firstName} ${selectedResource.lastName}`
            : t('timeline:assignResource')}
        </span>
        <IconChevronDown size={20} />
      </button>
      <Portal>
        {menuIsOpen && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className={styles.menuOverlay}
            onClick={() => {
              hideAll();
              setMenuIsOpen(false);
            }}
          />
        )}
        <Menu
          animation={false}
          style={{ minWidth: menuWidth ?? 304 }}
          className={classNames('shrinkSeparator', styles.menu)}
          id={MENU_ID}
          onVisibilityChange={setMenuIsOpen}
        >
          <Item
            preventCloseOnKeyDown
            className="unstyled-item"
            style={{ opacity: 1 }}
            disabled
            closeOnClick={false}
          >
            <Input
              ref={ref}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              LeadingIcon={<IconSearch size={20} />}
              isDark
              name="input"
              placeholder={`${t('common:search')}...`}
              value={value}
              onChange={onChangeFn}
            />
          </Item>
          <Separator />

          {resources?.length ? (
            resources?.map((item, index) => (
              <Item
                key={index}
                tabIndex={index}
                closeOnClick={true}
                onClick={() => onResourceClick(item)}
              >
                <span className={styles.name}>
                  {item.firstName} {item.lastName}
                </span>
              </Item>
            ))
          ) : isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '10px 0',
              }}
              className={styles.loader}
            >
              <ClipLoader
                color={getCssVariable('--color-neutral-500')}
                cssOverride={{
                  borderWidth: '2px',
                }}
                size={20}
                speedMultiplier={0.8}
              />
            </div>
          ) : (
            <Item disabled>{t('common:noResourcesToAssign')}</Item>
          )}
        </Menu>
      </Portal>
    </div>
  );
}
