import { useEffect } from 'react';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { ROUTES } from '@/types/routes';

import styles from './styles.module.css';



export default function AuthRedirectScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, oobCode } = queryString.parse(location.search);
  useEffect(() => {
    // navigate to reset password screen if mode is resetPassword
    if (oobCode) {
      if (mode === 'resetPassword') {
        navigate(`${ROUTES.PASSWORD_RESET}?mode=${mode}&oobCode=${oobCode}`, {
          preventScrollReset: false,
          state: { oobCode },
        });
        return;
      }
      if (mode === 'verifyEmail') {
        navigate(`${ROUTES.VALIDATE_EMAIL}?mode=${mode}&oobCode=${oobCode}`, {
          preventScrollReset: false,
          state: { oobCode },
        });
        return;
      }
    }
    // navigate to home if no mode or oobcode is found
    navigate(ROUTES.ROOT, { preventScrollReset: false });
  }, [mode, navigate, oobCode]);

  return (
    <div className={styles['loading-container']}>
      <ClipLoader />
    </div>
  );
}
