/* eslint-disable import/named */

import { useCallback, useMemo, useState } from 'react';

import { Path } from 'd3-path';
import * as d3 from 'd3-shape';
import { addWeeks, compareAsc, format, startOfWeek, subDays } from 'date-fns';
import { maxBy } from 'lodash';
import { AxisOptions, Chart } from 'react-charts';

import { getCssVariable } from '@/services/helpers';

import styles from './styles.module.css';


type ChartData = {
  date: Date;
  value: number;
  endDate: Date;
};

type Props = {
  data: Array<ChartData>;
  tooltipValueUnit?: string;
};

export default function InsightChart({ data = [], tooltipValueUnit }: Props) {
  const maxValue = maxBy(data, 'value')?.value ?? 0;
  const [todayWeek] = useState<Date>(startOfWeek(new Date()));

  const [preToday, afterToday]: [ChartData[], ChartData[]] = useMemo(() => {
    return (
      data?.reduce(
        ([pre, after], value): [ChartData[], ChartData[]] => {
          const compared = compareAsc(value.date, todayWeek);
          if (compared === 0) {
            pre.push(value);
            after.push(value);
          } else if (compared < 0) {
            pre.push(value);
          } else {
            after.push(value);
          }
          return [pre, after];
        },
        [[], []] as [ChartData[], ChartData[]],
      ) ?? [[], []]
    );
  }, [data, todayWeek]);

  const parsedData = useMemo(
    () => [
      {
        label: 'LinePre',
        data: preToday,
        secondaryAxisId: 'linePre',
      },
      {
        label: 'bar',
        data: [
          {
            date: todayWeek,
            value: (maxValue ?? 0) + 100,
            endDate: addWeeks(todayWeek, 1),
          } as ChartData,
        ],
        secondaryAxisId: 'bar',
      },
      {
        label: 'LineAfter',
        data: afterToday,
        secondaryAxisId: 'lineAfter',
      },
      {
        label: 'Area',
        data,
        secondaryAxisId: 'area',
      },
    ],
    [afterToday, data, maxValue, preToday, todayWeek],
  );
  const curveFn = useCallback(
    (context: CanvasRenderingContext2D | Path) => d3.curveBumpX(context),
    [],
  );

  const primaryAxis = useMemo(
    (): AxisOptions<ChartData> => ({
      getValue: (datum) => datum.date,
      tickCount: 5,
      scaleType: 'localTime',
      showGrid: false,
      show: false,
      formatters: {
        scale: (value) => format(value as Date, 'MMM'),
      },
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<ChartData>[] => [
      {
        getValue: (datum) => datum.value,
        showGrid: false,
        show: false,
        min: 0,
        max: maxValue > 0 ? maxValue : 100,
        id: 'linePre',
        elementType: 'line',
        curve: curveFn,
      },
      {
        getValue: (datum) => datum.value,
        showGrid: false,
        show: false,
        min: 0,
        max: maxValue > 0 ? maxValue : 100,
        id: 'bar',
        showDatumElements: true,
        elementType: 'bar',
      },
      {
        getValue: (datum) => datum.value,
        showGrid: false,
        show: false,
        min: 0,
        max: maxValue > 0 ? maxValue : 100,
        id: 'lineAfter',
        padBandRange: true,
        elementType: 'line',
        curve: curveFn,
      },
      {
        getValue: (datum) => datum.value,
        showGrid: false,
        show: false,
        id: 'area',
        elementType: 'area',
        max: maxValue > 0 ? maxValue : 100,
        min: 0,
        showDatumElements: false,
        curve: curveFn,
        styles: {
          pointerEvents: 'none',
          width: '100%',
        },
      },
    ],
    [curveFn, maxValue],
  );

  return (
    <div
      style={{ width: '100%', height: '64px' }}
      className={styles.chartWrapper}
    >
      <Chart
        options={{
          data: parsedData,
          initialHeight: 64,
          interactionMode: 'primary',
          primaryAxis,
          secondaryAxes,
          primaryCursor: false,
          secondaryCursor: false,
          initialWidth: 100,
          padding: {
            left: -56,
            right: -56,
            top: 8,
            bottom: 8,
          },
          tooltip: {
            invert: true,
            render: ({ focusedDatum }) => {
              if (!focusedDatum?.originalDatum?.date) return null;
              if (focusedDatum?.originalSeries.secondaryAxisId === 'bar') {
                return (
                  <div className={styles.tooltip}>
                    <p>
                      {format(focusedDatum?.originalDatum?.date as Date, 'dd')}{' '}
                      -{' '}
                      {format(
                        subDays(
                          focusedDatum?.originalDatum?.endDate,
                          2,
                        ) as Date,
                        'dd MMM',
                      )}
                    </p>
                    <span>this week</span>
                  </div>
                );
              }
              return (
                <div className={styles.tooltip}>
                  <p>
                    {format(focusedDatum?.originalDatum?.date as Date, 'dd')} -{' '}
                    {format(
                      subDays(focusedDatum?.originalDatum?.endDate, 2) as Date,
                      'dd MMM',
                    )}
                  </p>
                  <span>
                    {focusedDatum?.originalDatum?.value}
                    {tooltipValueUnit}
                  </span>
                </div>
              );
            },
          },
          getSeriesStyle: (series) => {
            switch (series.secondaryAxisId) {
              case 'area':
                return {
                  line: {
                    display: 'none',
                  },
                  color: `url(#${series.secondaryAxisId})`,
                };
              case 'linePre':
                return { color: getCssVariable('--color-blue-700') };
              case 'lineAfter':
                return {
                  strokeDasharray: '4',
                  color: getCssVariable('--color-blue-700'),
                };
              case 'bar':
                return {
                  color: getCssVariable('--color-neutral-300'),
                  width: '2px',
                  opacity: 0.5,
                  y: -12,
                  height: 64,
                };
              default:
                return {};
            }
          },
          renderSVG: () => (
            <defs>
              <linearGradient id="area" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop
                  offset="0%"
                  stopColor={getCssVariable('--color-blue-700')}
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={getCssVariable('--bg')}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
          ),
        }}
      />
    </div>
  );
}
