import { useContext } from 'react';

import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { acceptInvitation } from '@/services/api/account';
import { TResponseError } from '@/types/generic';

export default function useAcceptInvitation() {
  const { addNotification } = useContext(NotificationsContext);
  return useMutation(
    async (token: string) => {
      return acceptInvitation(token);
    },
    {
      onError: (error: TResponseError) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
