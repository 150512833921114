import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IconPlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';


import {
  ONBOARDING_STATUS,
  useOnboardingContext,
} from '@/contexts/OnboardingContext';
import { UserContext } from '@/contexts/UserContext';
import useResourceWorkspaceAssignedQuery, {
  WORKSPACE_ASSIGN_QUERY_KEY as WORKSPACE_ASSIGN_QUERY_KEY,
} from '@/hooks/workspace/resources/useResourceWorkspaceAssigned';
import { USER_ROLE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { ROUTES } from '@/types/routes';
import { TUserMember } from '@/types/workspace';

import Button from '@/components/Button';
import ModalAddNewTeamMember from '@/components/Modals/ModalAddNewTeamMember';
import WorkspaceResourceList from '@/components/WorkspaceResourceList';

import styles from './styles.module.css';
import Error404 from '../../ErrorScreen/404';



export default function AddMemberScreen() {
  const queryClient = useQueryClient();

  const { account } = useContext(UserContext);
  const { workspaceId } = useParams<{ workspaceId?: string }>();

  const { status, setNextStatus, navigateNextStep } = useOnboardingContext();

  const workspace = account?.workspaces.find(
    (ws) =>
      ws.id === workspaceId &&
      (ws.role === USER_ROLE.OWNER ||
        ws.permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE),
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!status) {
      navigate(ROUTES.ROOT, {
        preventScrollReset: false,
        replace: true,
      });
    } else if (status === ONBOARDING_STATUS.DONE) navigateNextStep();
  }, [navigate, navigateNextStep, status]);

  if (!workspace || workspace?.role !== USER_ROLE.OWNER) {
    return <Error404 />;
  }

  const { data: resources } = useResourceWorkspaceAssignedQuery({
    workspaceId,
  });

  const data = useMemo(
    () =>
      resources?.filter(
        (resource) => !resource.email || resource.email !== account?.email,
      ),
    [account?.email, resources],
  );

  const [addPersonModalOpen, setAddPersonModalOpen] = useState(false);

  const onAddComplete = useCallback(
    (newRes?: object | TUserMember, saved?: boolean) => {
      if (saved) {
        const res: TUserMember = newRes as TUserMember;
        queryClient.invalidateQueries([
          WORKSPACE_ASSIGN_QUERY_KEY,
          workspaceId,
        ]);
      }
      setAddPersonModalOpen(false);
    },
    [queryClient, workspaceId],
  );

  return (
    <div className={styles['content__main']}>
      <h1 className={styles['content__title']}>
        {t('common:addMember.title', { workspaceName: workspace.name })}
      </h1>
      <div>
        <WorkspaceResourceList
          account={account}
          workspaceId={workspaceId}
          resources={data}
          workspaceName={workspace.name}
        />

        <button
          onClick={() => {
            setAddPersonModalOpen(true);
          }}
          className={styles.addButton}
        >
          <div className={styles.addButtonContent}>
            <IconPlus size={16} />
            <span className={styles.addButtonText}>
              {t('common:addMember.primaryCtaLabel')}
            </span>
          </div>
        </button>
      </div>

      <div className={styles.actionContainer}>
        <Button
          label={t('common:addMember.secondaryCtaLabel')}
          size="large"
          onClick={setNextStatus}
          disabled={!data?.length}
        />
        <Button
          label={t('common:addMember.skip')}
          variant="ghost"
          size="large"
          onClick={setNextStatus}
        />
        {addPersonModalOpen && (
          <ModalAddNewTeamMember
            isOpen={addPersonModalOpen}
            onClose={onAddComplete}
            onboarding
          />
        )}
      </div>
    </div>
  );
}
