/* eslint-disable import/named */
import { useCallback, useContext, useEffect, useState } from 'react';

import { animated, useTransition } from '@react-spring/web';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { cloneDeep, first } from 'lodash';


import { UserContext } from '@/contexts/UserContext';
import useOutsideClick from '@/hooks/utils/useClickOutsideComponent';
import {
  PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
  TTimelineProjectListWithResourcesWithTimeblocksPage,
} from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { PROJECT_TYPE } from '@/types/enums';
import { TTimeBlockRange } from '@/types/timeline';

import BlockActions from '@/components/Blocks/BlockActions';
import { TimelineProjectsContext } from '@/components/Timelines/TimelineProjects/context';

import styles from './styles.module.css';
import { ProjectStatusContext } from '../ProjectStatusWrapper/ProjectStatusContext';





type Props = {
  activeBlocks: TTimeBlockRange[];
  left: number;
  width: number;
  capacity: number;
  type: PROJECT_TYPE;
};
export default function ActiveBlockWrapper({
  left,
  width,
  type,
  capacity,
  activeBlocks,
}: Props) {
  const {
    setActiveBlockIds,
    setActiveResourceId,
    setActiveProjectId,
    leftPadding,
    activeBlockIds,
    activeProjectId,
    activeResourceId,
  } = useContext(TimelineProjectsContext);
  const { workspaceId } = useContext(UserContext);
  const { updateBlocksAllocation, onDeleteBlockFn } =
    useContext(ProjectStatusContext);

  const [shouldShowActions, setShouldShowActions] = useState(false);

  useEffect(() => {
    setShouldShowActions(Boolean(activeBlocks.length > 0));
  }, [activeBlocks]);

  const transition = useTransition(shouldShowActions, {
    config: { duration: 50 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const onCloseActions = useCallback(() => {
    setActiveBlockIds([]);
    setActiveProjectId(null);
    setActiveResourceId(null);
  }, [setActiveBlockIds, setActiveResourceId, setActiveProjectId]);

  const ref = useOutsideClick<HTMLDivElement>(() => {
    onCloseActions();
  });

  const handleMouseDown = (e: React.MouseEvent) => e.stopPropagation();

  const onChangeBlocksAllocationFn = useCallback(
    (allocation: string) => {
      updateBlocksAllocation(allocation);
    },
    [updateBlocksAllocation],
  );

  const block = first(activeBlocks);
  const queryClient = useQueryClient();

  const onChangeBlockDetailFn = useCallback(
    (block: TTimeBlockRange | undefined) => {
      const data =
        queryClient.getQueryData<TTimelineProjectListWithResourcesWithTimeblocksPage>(
          [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
        );
      const allocationBlock = data?.pages
        ?.flatMap(({ results }) => results)
        .find(({ id }) => id === activeProjectId)
        ?.resources?.find(({ id }) => id === activeResourceId)
        ?.timeblocks?.find(({ id }) => first(activeBlockIds) === id);
      if (allocationBlock) allocationBlock.note = block?.note;
      queryClient.setQueryData(
        [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
        cloneDeep(data),
      );
    },
    [
      activeBlockIds,
      activeProjectId,
      activeResourceId,
      queryClient,
      workspaceId,
    ],
  );

  return block ? (
    <div
      ref={ref}
      style={{ left: left - 4, width: width + 8 }}
      className={classNames(styles.container, {
        [styles.isMulti]: activeBlocks.length > 1,
        [styles.timeOff]: type === PROJECT_TYPE.TIME_OFF,
      })}
      onMouseDown={handleMouseDown}
      aria-hidden
    >
      {transition((style, isOpen) => (
        <>
          {isOpen && (
            <animated.div
              className={styles.actionsWrapper}
              style={{ opacity: style.opacity }}
            >
              <div className={styles.actions} style={{ left: leftPadding + 4 }}>
                <BlockActions
                  capacity={capacity}
                  block={block}
                  onClose={onCloseActions}
                  onCopy={console.log}
                  onCut={console.log}
                  onPaste={console.log}
                  onDelete={() =>
                    onDeleteBlockFn(activeBlocks.map((b) => b.id))
                  }
                  value={
                    activeBlocks.length > 1
                      ? 'Mix'
                      : block?.allocation?.toString() ?? ''
                  }
                  onChange={onChangeBlocksAllocationFn}
                  onUpdateDetail={onChangeBlockDetailFn}
                />
              </div>
            </animated.div>
          )}
        </>
      ))}
    </div>
  ) : null;
}
