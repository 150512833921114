import { getDownloadURL, getStorage, ref } from '@firebase/storage';

/**
 * This asynchronous function retrieves an image from Firebase storage.
 *
 * @param {string} path - The path of the image in the Firebase storage. If the path is not provided, the function will return an empty string.
 *
 * @returns {Promise<string>} The URL of the image if it exists, otherwise it returns the error.
 *
 * @throws Will log the error to the console if the request fails.
 */

export async function getImageFromFirebase(path: string) {
  const storage = getStorage();
  const currentRef = ref(storage, path);
  try {
    if (!path) return '';
    const url = await getDownloadURL(currentRef);
    return url;
  } catch (err) {
    console.log(err);
    return err;
  }
}
