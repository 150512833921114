import { useContext, useEffect } from 'react';

import ModalMobileExperience from '@components/Modals/ModalMobileExperience';
import ModalTermsOfService from '@components/Modals/ModalTermsOfService';
import { DndContext } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import { UserContext } from '@/contexts/UserContext';
import { ANALYTICS_PAGE_NAME, useAnalytics } from '@/hooks/utils/useAnalytics';

import Drawer from '@/components/Drawer';
import Header from '@/components/Header';
import Layout from '@/components/Layout';
import TTimelineProject from '@/components/Timelines/TimelineProjects';
import { TimelineProjectProvider } from '@/components/Timelines/TimelineProjects/context';
export default function DashboardProjectView() {
  const { trackPage } = useAnalytics();
  const { workspaceId } = useContext(UserContext);
  useEffect(() => {
    trackPage(ANALYTICS_PAGE_NAME.PROJECTS, workspaceId as string);
  }, [workspaceId, trackPage]);

  return (
    <Layout>
      <TimelineProjectProvider>
        <Header />
        <DndContext
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <TTimelineProject />

          <Drawer />
        </DndContext>
        <ModalTermsOfService />
        <ModalMobileExperience />
      </TimelineProjectProvider>
    </Layout>
  );
}
