import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  endOfDay,
  isSameDay,
  isWithinInterval,
  startOfDay,
  subDays,
  subWeeks,
} from 'date-fns';
import { uniqBy } from 'lodash';


import { ALLOCATION_EVENT_OPERATION_TYPE } from '@/types/enums';
import { TTimeBlockRange, TTimelineResource } from '@/types/timeline';

import { generateUUID } from '..';

/**
 * This function merges resource data from two arrays, keeping unique data and updating existing data.
 *
 * @param {TTimelineResource[]} oldData - An array of TTimelineResource objects representing the existing data.
 * @param {TTimelineResource[]} newData - An array of TTimelineResource objects representing the new data to merge.
 *
 * @returns {TTimelineResource[]} - An array of TTimelineResource objects representing the merged data.
 *
 * @example
 *
 * // Example of a TTimelineResource object
 * // {
 * //   id: '1',
 * //   projects: [
 * //     {
 * //       id: 'p1',
 * //       timeblocks: [
 * //         { id: 't1', ... },
 * //         { id: 't2', ... },
 * //       ],
 * //     },
 * //   ],
 * // }
 *
 * const oldData = [...]; // existing data
 * const newData = [...]; // new data
 * const mergedData = mergeResourcesData(oldData, newData);
 *
 * The workflow of the function is as follows:
 * 1. Reduces the `newData` array into a new array.
 * 2. For each `newResource` in `newData`, it looks for an existing resource with the same ID in `prevResources` (which starts as `oldData`).
 * 3. If a resource with the same ID exists, it merges the projects of `newResource` with those of the existing resource.
 * 4. If a project with the same ID exists, it merges the timeblocks of `newProject` with those of the existing project, removing duplicates.
 * 5. If a project with the same ID does not exist, it adds `newProject` to the projects array.
 * 6. If a resource with the same ID does not exist, it adds `newResource` to the resources array.
 */
export function mergeResourcesData(
  oldData: TTimelineResource[] = [],
  newData: TTimelineResource[] = [],
) {
  // The function starts by reducing the `newData` array into a new array.
  return newData.reduce((prevResources, newResource) => {
    // For each `newResource` in `newData`, it looks for an existing resource with the same ID in `prevResources`.
    const existingResource = prevResources.find(
      (resource) => resource.id === newResource.id,
    );
    // If a resource with the same ID exists, it merges the projects of `newResource` with those of the existing resource.
    if (existingResource) {
      const updatedResource = {
        // It keeps the existing resource data...
        ...existingResource,
        // ...and updates the projects array.
        projects: newResource.projects.reduce((prevProjects, newProject) => {
          // For each `newProject` in `newResource.projects`, it looks for an existing project with the same ID in `prevProjects`.
          const existingProject = prevProjects.find(
            (project) => project.id === newProject.id,
          );
          // If a project with the same ID exists, it merges the timeblocks of `newProject` with those of the existing project, removing duplicates.
          if (existingProject) {
            const updatedProject = {
              // It keeps the existing project data...
              ...existingProject,
              // ...and updates the timeblocks array, removing duplicates.
              timeblocks: uniqBy(
                [...newProject.timeblocks, ...existingProject.timeblocks],
                'id',
              ),
            };
            // It updates the `prevProjects` array with the updated project.
            return prevProjects?.map((project) =>
              project.id === updatedProject.id ? updatedProject : project,
            );
          } else {
            // If a project with the same ID does not exist, it adds `newProject` to the projects array.
            return [...prevProjects, newProject];
          }
        }, existingResource.projects),
      };
      // It updates the `prevResources` array with the updated resource.
      return prevResources.map((resource) =>
        resource.id === updatedResource.id ? updatedResource : resource,
      );
    } else {
      // If a resource with the same ID does not exist, it adds `newResource` to the resources array.
      return [...prevResources, newResource];
    }
  }, oldData);
}

/**
 * This function updates a list of project time blocks with proper events based on the current block.
 * It checks for various conditions such as full overlap, inside overlap, end overlap, and start overlap.
 * Depending on the condition, it updates the operation type and potentially the start and end dates of the block.
 *
 * @param {TTimeBlockRange[]} allBlocks - The existing list of project time blocks.
 * @param {TTimeBlockRange} currentBlock - The current block to compare with.
 * @returns {TTimeBlockRange[]} - The updated list of project time blocks.
 */
export const updateBlocksWithProperEvents = (
  allBlocks: TTimeBlockRange[],
  currentBlock: TTimeBlockRange,
) => {
  const allBlockToUpdate = allBlocks.reduce((result: TTimeBlockRange[], block) => {
    // If the block ID matches the current block ID, update the operation type and the start and end dates of the block.
    if (block.id === currentBlock.id) {
      return [
        ...result,
        {
          ...block,
          operation: ALLOCATION_EVENT_OPERATION_TYPE.UPDATE,
          end: endOfDay(currentBlock.end),
          start: startOfDay(currentBlock.start),
        },
      ];
    }
    // If the block start and end dates are within the current block start and end dates, set the operation type to DELETE.
    else if (
      isWithinInterval(block.start, {
        start: startOfDay(currentBlock.start),
        end: endOfDay(currentBlock.end),
      }) &&
      isWithinInterval(block.end, {
        start: startOfDay(currentBlock.start),
        end: endOfDay(currentBlock.end),
      })
    ) {
      return [
        ...result,
        {
          ...block,
          operation: ALLOCATION_EVENT_OPERATION_TYPE.DELETE,
        },
      ];
    }
    // If the block spans more than one week and the current block start and end dates are within the block start and end dates, update the operation type and end date of the block, and insert a new block.
    else if (
      differenceInCalendarWeeks(block.end, block.start) > 1 &&
      isWithinInterval(currentBlock.start, {
        start: startOfDay(addWeeks(block.start, 1)),
        end: endOfDay(block.end),
      }) &&
      isWithinInterval(currentBlock.end, {
        start: startOfDay(block.start),
        end: endOfDay(subWeeks(block.end, 1)),
      })
    ) {
      return [
        ...result,
        {
          ...block,
          operation: ALLOCATION_EVENT_OPERATION_TYPE.UPDATE,
          end: endOfDay(subDays(currentBlock.start, 1)),
        },
        {
          ...block,
          id: generateUUID(),
          operation: ALLOCATION_EVENT_OPERATION_TYPE.INSERT,
          start: startOfDay(addDays(currentBlock.end, 1)),
        },
      ];
    }
    // If the current block overlaps the end of the block, update the operation type and end date of the block.
    else if (
      (isSameDay(currentBlock.end, block.end) &&
        isWithinInterval(currentBlock.start, {
          start: startOfDay(addWeeks(block.start, 1)),
          end: endOfDay(block.end),
        })) ||
      (isWithinInterval(currentBlock.start, {
        start: startOfDay(block.start),
        end: endOfDay(block.end),
      }) &&
        !isWithinInterval(currentBlock.end, {
          start: startOfDay(block.start),
          end: endOfDay(block.end),
        }))
    ) {
      return [
        ...result,
        {
          ...block,
          operation: ALLOCATION_EVENT_OPERATION_TYPE.UPDATE,
          end: endOfDay(subDays(currentBlock.start, 1)),
        },
      ];
    }
    // If the current block overlaps the start of the block, update the operation type and start date of the block.
    else if (
      (isSameDay(currentBlock.start, block.start) &&
        isWithinInterval(currentBlock.end, {
          start: startOfDay(block.start),
          end: endOfDay(subWeeks(block.end, 1)),
        })) ||
      (isWithinInterval(currentBlock.end, {
        start: startOfDay(block.start),
        end: endOfDay(block.end),
      }) &&
        !isWithinInterval(currentBlock.start, {
          start: startOfDay(block.start),
          end: endOfDay(block.end),
        }))
    ) {
      return [
        ...result,
        {
          ...block,
          operation: ALLOCATION_EVENT_OPERATION_TYPE.UPDATE,
          start: startOfDay(addDays(currentBlock.end, 1)),
        },
      ];
    }
    // If none of the above conditions are met, return the result as is.
    else {
      return result;
    }
  }, []);
  // TODO: vedere se c'è un modo per fare lo shift prima
  const currentIndex = allBlockToUpdate.findIndex((block) => block.id === currentBlock.id);
  if (currentIndex === -1) return allBlockToUpdate;
  else {
    const currentBlockToUpdate = allBlockToUpdate.splice(currentIndex, 1)?.[0];
    return [...allBlockToUpdate, currentBlockToUpdate];
  }
};

/**
 * This function updates a list of project time blocks based on the current block.
 * It checks for various conditions such as full overlap, inside overlap, end overlap, and start overlap.
 * Depending on the condition, it updates the start and end dates of the block.
 *
 * @param {TTimeBlockRange[]} projectBlocks - The existing list of project time blocks.
 * @param {TTimeBlockRange} currentBlock - The current block to compare with.
 * @returns {TTimeBlockRange[]} - The updated list of project time blocks.
 */
export const updateCollidingBlocks = (
  projectBlocks: TTimeBlockRange[],
  currentBlock: TTimeBlockRange,
) => {
  return projectBlocks.reduce((result: TTimeBlockRange[], block) => {
    // If the block ID matches the current block ID, add the current block to the result.
    if (block.id === currentBlock.id) {
      return [...result, currentBlock];
    }
    // If the block start and end dates are within the current block start and end dates, return the result as is.
    else if (
      isWithinInterval(block.start, {
        start: startOfDay(currentBlock.start),
        end: endOfDay(currentBlock.end),
      }) &&
      isWithinInterval(block.end, {
        start: startOfDay(currentBlock.start),
        end: endOfDay(currentBlock.end),
      })
    ) {
      return result;
    }
    // If the block spans more than one week and the current block start and end dates are within the block start and end dates, update the end date of the block, and insert a new block.
    else if (
      differenceInCalendarWeeks(block.end, block.start) > 1 &&
      isWithinInterval(currentBlock.start, {
        start: startOfDay(addWeeks(block.start, 1)),
        end: endOfDay(block.end),
      }) &&
      isWithinInterval(currentBlock.end, {
        start: startOfDay(block.start),
        end: endOfDay(subWeeks(block.end, 1)),
      })
    ) {
      return [
        ...result,
        {
          ...block,
          end: endOfDay(subDays(currentBlock.start, 1)),
        },
        {
          ...block,
          id: generateUUID(),
          start: startOfDay(addDays(currentBlock.end, 1)),
        },
      ];
    }
    // If the current block overlaps the end of the block, update the end date of the block.
    else if (
      (isSameDay(currentBlock.end, block.end) &&
        isWithinInterval(currentBlock.start, {
          start: startOfDay(addWeeks(block.start, 1)),
          end: endOfDay(block.end),
        })) ||
      (isWithinInterval(currentBlock.start, {
        start: startOfDay(block.start),
        end: endOfDay(block.end),
      }) &&
        !isWithinInterval(currentBlock.end, {
          start: startOfDay(block.start),
          end: endOfDay(block.end),
        }))
    ) {
      return [
        ...result,
        {
          ...block,
          end: endOfDay(subDays(currentBlock.start, 1)),
        },
      ];
    }
    // If the current block overlaps the start of the block, update the start date of the block.
    else if (
      (isSameDay(currentBlock.start, block.start) &&
        isWithinInterval(currentBlock.end, {
          start: startOfDay(block.start),
          end: endOfDay(subWeeks(block.end, 1)),
        })) ||
      (isWithinInterval(currentBlock.end, {
        start: startOfDay(block.start),
        end: endOfDay(block.end),
      }) &&
        !isWithinInterval(currentBlock.start, {
          start: startOfDay(block.start),
          end: endOfDay(block.end),
        }))
    ) {
      return [
        ...result,
        {
          ...block,
          start: startOfDay(addDays(currentBlock.end, 1)),
        },
      ];
    }
    // If none of the above conditions are met, add the block to the result.
    else {
      return [...result, block];
    }
  }, []);
};
