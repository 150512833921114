import { IndicatorProp } from './IndicatorProp';

export default function InProgress({
  size = 24,
  variant = 'default',
}: IndicatorProp) {
  const primaryColor = variant === 'default' ? '#0560FD' : '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C4.00035 5.85752 3.26375 6.95991 2.7612 8.17317C2.25866 9.38642 2 10.6868 2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C13.3132 22 14.6136 21.7413 15.8268 21.2388C17.0401 20.7362 18.1425 19.9997 19.0711 19.0711C19.9997 18.1425 20.7362 17.0401 21.2388 15.8268C21.7413 14.6136 22 13.3132 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM9.10654 5.01457C10.0239 4.63459 11.0071 4.43902 12 4.43902C12.9929 4.43902 13.9761 4.63459 14.8935 5.01457C15.8108 5.39454 16.6443 5.95148 17.3464 6.65358C18.0485 7.35569 18.6055 8.1892 18.9854 9.10654C19.3654 10.0239 19.561 11.0071 19.561 12C19.561 12.9929 19.3654 13.9761 18.9854 14.8935C18.6055 15.8108 18.0485 16.6443 17.3464 17.3464C16.6443 18.0485 15.8108 18.6055 14.8935 18.9854C13.9761 19.3654 12.9929 19.561 12 19.561C11.0071 19.561 10.0239 19.3654 9.10654 18.9854C8.1892 18.6055 7.35568 18.0485 6.65358 17.3464C5.95148 16.6443 5.39454 15.8108 5.01457 14.8935C4.63459 13.9761 4.43902 12.9929 4.43902 12C4.43902 11.0071 4.63459 10.0239 5.01457 9.10654C5.39454 8.1892 5.95148 7.35568 6.65358 6.65358C7.35568 5.95148 8.1892 5.39454 9.10654 5.01457ZM12 17.5C15.0376 17.5 17.5 15.0376 17.5 12C17.5 8.96243 15.0376 6.5 12 6.5L12 12L12 17.5Z"
        fill={primaryColor}
      />
    </svg>
  );
}
