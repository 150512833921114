import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { validate as EmailValidator } from 'email-validator';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';


import { UserContext } from '@/contexts/UserContext';
import useDeleteAccountImage from '@/hooks/account/useDeleteAccountImage';
import useUpdateAccount from '@/hooks/account/useUpdateAccount';
import useUpdateAccountImage from '@/hooks/account/useUpdateAccountImage';
import { STORAGE_BASE_PATH } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TUserAccount } from '@/types/workspace';

import AvatarUploader from '@/components/AvatarUploader';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Section from '@/components/Section';

import styles from './styles.module.css';




type AccountForm = Omit<TUserAccount, 'workspaces' | 'image'> & {
  password?: string;
};

export default function ModalProfileContent() {
  const { account, workspaceId } = useContext(UserContext);
  const [attachments, setAttachments] = useState<TAttachmentDataUrls[]>(
    account?.image?.ref ? [account.image] : [],
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { mutate: mutateUpdateAccount, isLoading: isUpdateAccountLoading } =
    useUpdateAccount();

  const queryClint = useQueryClient();

  const updateResources = useCallback(() => {
    queryClint.invalidateQueries(['get-resources', workspaceId]);
  }, [queryClint, workspaceId]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<AccountForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setAttachments(account?.image?.ref ? [account.image] : []);
  }, [account?.image]);

  const onSubmitFn = useCallback(
    async (data: AccountForm) => {
      mutateUpdateAccount(data, {
        onSuccess: () => {
          updateResources();
          reset(undefined, { keepValues: true });
        },
      });
    },
    [mutateUpdateAccount, reset, updateResources],
  );

  const { mutate: mutateUpdateAccountImage } = useUpdateAccountImage();
  const { mutate: mutateDeleteAccountImage } = useDeleteAccountImage();

  const onUploadSuccessFn = useCallback(
    (data: TAttachmentDataUrls) => {
      setAttachments([data]);
      mutateUpdateAccountImage(
        {
          filePath: data?.ref as string,
        },
        { onSuccess: updateResources },
      );
    },
    [mutateUpdateAccountImage, updateResources],
  );

  const onRemoveImageFn = useCallback(() => {
    setAttachments([]);
    mutateDeleteAccountImage(undefined, { onSuccess: updateResources });
  }, [mutateDeleteAccountImage, updateResources]);

  useEffect(() => {
    const firstAndLastNameWatcher = watch(({ firstName, lastName }) =>
      setIsDisabled(
        firstName === account?.firstName && lastName === account?.lastName,
      ),
    );
    () => firstAndLastNameWatcher.unsubscribe();
  }, [account?.firstName, account?.lastName, watch]);

  return (
    <React.Fragment>
      <Section title="Profile picture">
        <div className={styles['image-container']}>
          <AvatarUploader
            onRemoveFileSuccess={onRemoveImageFn}
            basePath={STORAGE_BASE_PATH.ACCOUNTS}
            onChange={setAttachments}
            resourceName={account?.firstName + ' ' + account?.lastName}
            onUploadSuccess={onUploadSuccessFn}
            multiple={false}
            data={attachments}
          />
        </div>
      </Section>
      <Section title="Profile Info">
        <form
          onSubmit={handleSubmit(onSubmitFn)}
          className={styles.profileForm}
        >
          <div className={styles.inputWrapper}>
            <Controller
              rules={{
                maxLength: {
                  value: 180,
                  message: t('forms:profileSettings.firstName.maxLength'),
                },
                required: {
                  value: true,
                  message: t('forms:profileSettings.firstName.required'),
                },
              }}
              control={control}
              defaultValue={account?.firstName ?? ''}
              name="firstName"
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    required
                    label={t('forms:profileSettings.firstName.label')}
                    value={value}
                    placeholder={t(
                      'forms:profileSettings.firstName.placeholder',
                    )}
                    autoComplete="given-name"
                    onChange={onChange}
                    disabled={isUpdateAccountLoading}
                    name={name}
                    onBlur={onBlur}
                    error={errors?.firstName?.message}
                  />
                );
              }}
            />
            <Controller
              rules={{
                maxLength: {
                  value: 180,
                  message: t('forms:profileSettings.lastName.maxLength'),
                },
                required: {
                  value: true,
                  message: t('forms:profileSettings.lastName.required'),
                },
              }}
              defaultValue={account?.lastName ?? ''}
              control={control}
              name="lastName"
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    required
                    disabled={isUpdateAccountLoading}
                    label={t('forms:profileSettings.lastName.label')}
                    value={value}
                    autoComplete="family-name"
                    placeholder={t(
                      'forms:profileSettings.lastName.placeholder',
                    )}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors?.lastName?.message}
                  />
                );
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: t('forms:profileSettings.email.required'),
                },
                validate: (value) => {
                  if (!EmailValidator(value)) {
                    return t('forms:profileSettings.email.invalid') as string;
                  }
                  return true;
                },
              }}
              defaultValue={account?.email ?? ''}
              control={control}
              name="email"
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    required
                    label={t('forms:profileSettings.email.label')}
                    value={value}
                    disabled={true}
                    placeholder={t('forms:profileSettings.email.placeholder')}
                    onChange={onChange}
                    name={name}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </div>
          <div>
            <Button
              label={t('forms:profileSettings.submit')}
              variant="outline"
              size="medium"
              isLoading={isUpdateAccountLoading}
              disabled={!isDirty || !isValid || isDisabled}
            />
          </div>
        </form>
      </Section>
    </React.Fragment>
  );
}
