import classNames from 'classnames';

import { truncate } from '@/services/helpers';

import styles from './styles.module.css';


export type GroupItemProps = {
  itemId: string;
  icon: JSX.Element;
  title: string;
  isActive?: boolean;
  onClick?: (groupId: string) => void;
};

export type GroupProps = {
  title?: string | null;
  items?: GroupItemProps[];
};

export function SectionGroup(props: GroupProps) {
  return (
    <div className={styles.container}>
      {props?.title && (
        <h4 className={styles.title}>
          {truncate(
            props.title,
            20,
            `...${props?.title?.slice(props?.title?.length - 5, props?.title?.length)}`,
          )}
        </h4>
      )}

      {props.items?.map((item) => {
        return (
          <button
            onClick={() => item.onClick?.(item.itemId)}
            className={classNames(styles.item, {
              [styles.active]: item.isActive,
            })}
            key={item.title}
          >
            <div className={styles['item-icon']}>{item.icon}</div>
            <div className={styles['item-title']}>{item.title}</div>
          </button>
        );
      })}
    </div>
  );
}
