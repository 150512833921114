import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { t } from 'i18next';
import { noop } from 'lodash';

import useDeleteResource from '@/hooks/workspace/resources/useDeleteResource';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import Input from '../../Input';


type Props = {
  name: string;
  resourceId: string;
  isOpen: boolean;
  onClose: () => void;
  onDeleteSuccess?: typeof noop;
};
export default function ModalDeleteTeamMember({
  isOpen,
  onClose,
  resourceId,
  name = '',
  onDeleteSuccess = noop,
}: Props) {
  const { mutate, isLoading } = useDeleteResource();
  const [value, setValue] = useState('');
  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onDeleteSuccessFn = useCallback(() => {
    onDeleteSuccess?.(true);
  }, [onDeleteSuccess]);

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-delete-team-member-${resourceId}`}
      onClose={onClose}
      title={t('common:modals.deleteResource.title')}
      footer={
        <>
          <Button
            label={t('common:modals.deleteResource.primaryCtaLabel')}
            onClick={() => {
              mutate(
                { resourceId },
                {
                  onSuccess: onDeleteSuccessFn,
                },
              );
            }}
            isLoading={isLoading}
            disabled={value !== name}
            variant="destructive"
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <div className={styles.container}>
        <div className={styles.alert}>
          <IconAlertCircleFilled size={24} />
          <p>{t('common:modals.deleteResource.description')}</p>
        </div>
        <p className={styles.actionLabel}>
          {t('common:modals.deleteResource.actionLabel', { value: name })}
        </p>
        <Input ref={ref} value={value} onChange={onChangeFn} name="destroy" />
      </div>
    </Modal>
  );
}
