import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { format } from 'date-fns';
import { filter, find, forEach, isUndefined, map, noop, omit } from 'lodash';


import { UserContext } from '@/contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useAllocation from '@/hooks/workspace/projects/useAllocation';
import { ALLOCATION_EVENT_OPERATION_TYPE, PROJECT_STATUS } from '@/types/enums';
import type {
  TAllocationEvent,
  TProjectListWithResources,
  TResourceItemList,
  TTimeBlockRange,
} from '@/types/timeline';

import { TimelineProjectsContext } from '../context';



type TData = TProjectListWithResources<TResourceItemList>[];
type TBlocksToUpdate = {
  events: TTimeBlockRange[];
  projectId: string;
  resourceId: string;
  shouldInvalidated?: boolean;
};

const ProjectStatusContext = createContext<{
  data: TData;
  setBlocksToUpdate: React.Dispatch<React.SetStateAction<TBlocksToUpdate>>;
  blocksToUpdate: TBlocksToUpdate;
  updateBlocksAllocation: (allocation: string) => void;
  updateData: (newBlock: TTimeBlockRange) => void;
  onDeleteBlockFn: (blockIds: string[]) => void;
  status: PROJECT_STATUS;
}>({
  data: [],
  updateBlocksAllocation: noop,
  blocksToUpdate: { events: [], projectId: '', resourceId: '' },
  setBlocksToUpdate: noop,
  onDeleteBlockFn: noop,
  updateData: noop,
  status: PROJECT_STATUS.UNCONFIRMED,
});

const ProjectStatusProvider = ({
  children,
  status,
  initialData,
}: PropsWithChildren<{
  status: PROJECT_STATUS;
  initialData: TProjectListWithResources<TResourceItemList>[];
}>) => {
  const { trackEvent } = useAnalytics();

  const { workspaceId } = useContext(UserContext);
  const {
    activeBlockIds,
    activeProjectId,
    activeResourceId,
    setActiveBlockIds,
  } = useContext(TimelineProjectsContext);

  const { mutate: mutateAllocation } = useAllocation();

  const [blocksToUpdate, setBlocksToUpdate] = useState<TBlocksToUpdate>({
    events: [],
    projectId: '',
    resourceId: '',
    shouldInvalidated: false,
  });

  const currentProject = useMemo(
    () =>
      find(initialData, {
        id: activeProjectId,
      }) as unknown as TProjectListWithResources<TResourceItemList>,
    [initialData, activeProjectId],
  );

  const currentResource = useMemo(
    () =>
      find(currentProject?.resources, {
        id: activeResourceId,
      }) as unknown as TResourceItemList,
    [currentProject, activeResourceId],
  );

  const onDeleteBlockFn = useCallback(
    (blockIds: string[]) => {
      const allBlocksToBeDeleted = blockIds.map((allocationId) => ({
        allocationId,
        operation: ALLOCATION_EVENT_OPERATION_TYPE.DELETE,
      }));
      mutateAllocation(
        {
          events: allBlocksToBeDeleted as unknown as TAllocationEvent[],
          projectId: activeProjectId as string,
          resourceId: activeResourceId as string,
          status,
        },
        {
          onSuccess: () => {
            setActiveBlockIds([]);
            trackEvent(ANALYTICS_EVENTS.BLOCK_DELETED, workspaceId as string);
          },
        },
      );
    },
    [
      mutateAllocation,
      activeProjectId,
      activeResourceId,
      status,
      setActiveBlockIds,
      trackEvent,
      workspaceId,
    ],
  );

  useEffect(() => {
    if (blocksToUpdate === null) return;
    const blocks = blocksToUpdate?.events.map((b) => ({
      ...omit(b, ['start', 'end', 'id', 'allocation']),
      allocationId: b.id,
      allocation: b.allocation,
      startDate: String(format(b.start, 'yyyy-MM-dd')),
      endDate: String(format(b.end, 'yyyy-MM-dd')),
    })) as TAllocationEvent[];
    if (!blocks.length) return;
    mutateAllocation(
      {
        events: blocks,
        projectId: blocksToUpdate.projectId,
        resourceId: blocksToUpdate.resourceId,
        shouldInvalidate: blocksToUpdate.shouldInvalidated,
        status,
      },
      {
        onSuccess: () => {
          forEach(blocks, (block) => {
            let event;
            switch (block?.operation) {
              case ALLOCATION_EVENT_OPERATION_TYPE.INSERT:
                event = ANALYTICS_EVENTS.BLOCK_CREATED;
                break;
              case ALLOCATION_EVENT_OPERATION_TYPE.UPDATE:
                event = ANALYTICS_EVENTS.BLOCK_UPDATED;
                break;
              case ALLOCATION_EVENT_OPERATION_TYPE.DELETE:
                event = ANALYTICS_EVENTS.BLOCK_DELETED;
                break;
              default:
                event = null;
            }
            if (!event) return;
            trackEvent(event, workspaceId as string);
            setBlocksToUpdate({ events: [], projectId: '', resourceId: '' });
          });
        },
      },
    );
  }, [blocksToUpdate, trackEvent, mutateAllocation, workspaceId, status]);

  const updateData = useCallback(
    (newBlock: TTimeBlockRange) => {
      // Update blocks

      const newTimeblocks = map(currentResource?.timeblocks, (tb) => {
        return tb.id === newBlock.id ? newBlock : tb;
      });

      // Update resource
      const newResource = map(currentProject?.resources, (r) => {
        return r.id === activeProjectId
          ? { ...r, timeblocks: newTimeblocks }
          : r;
      });
      // Update project
      const newProject = { ...currentProject, resources: newResource };
      // Update data context
      if (JSON.stringify(newProject) === JSON.stringify(currentProject)) return;
      if (isUndefined(currentResource) || isUndefined(newResource)) return;
    },
    [currentResource, currentProject, activeProjectId],
  );

  const updateBlocksAllocation = useCallback(
    (allocation: string) => {
      const allBlocks = filter(currentResource?.timeblocks, (block) => {
        return activeBlockIds.includes(block?.id);
      });

      if (allBlocks.length > 0) {
        const allBlocksToBeUpdated = allBlocks.map((b) => {
          updateData({
            ...b,
            allocation: Number(allocation),
          });
          return {
            allocationId: b.id,
            allocation: Number(allocation),
            startDate: String(format(b.start, 'yyyy-MM-dd')),
            endDate: String(format(b.end, 'yyyy-MM-dd')),
            operation: ALLOCATION_EVENT_OPERATION_TYPE.UPDATE,
          };
        }) as TAllocationEvent[];

        mutateAllocation(
          {
            events: allBlocksToBeUpdated,
            projectId: activeProjectId as string,
            resourceId: activeResourceId as string,
            status,
          },
          {
            onSuccess: () => {
              trackEvent(ANALYTICS_EVENTS.BLOCK_UPDATED, workspaceId as string);
            },
          },
        );
      }
    },
    [
      currentResource?.timeblocks,
      activeBlockIds,
      mutateAllocation,
      activeProjectId,
      activeResourceId,
      status,
      updateData,
      trackEvent,
      workspaceId,
    ],
  );

  return (
    <ProjectStatusContext.Provider
      value={{
        data: initialData,
        updateData,
        status,
        setBlocksToUpdate,
        blocksToUpdate,
        updateBlocksAllocation,
        onDeleteBlockFn,
      }}
    >
      {children}
    </ProjectStatusContext.Provider>
  );
};

export { ProjectStatusContext, ProjectStatusProvider };
