import React, { useCallback, useEffect, useRef } from 'react';

import Button from '@components/Button';
import Section from '@components/Section';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';


import useUpdateWorkspace from '@/hooks/workspace/useUpdateWorkspace';
import { useWorkspaceFromContext } from '@/hooks/workspace/useWorkspaceFromContext';

import Input from '@/components/Input';

import styles from './styles.module.css';

export type UpdateWorkspaceDataForm = {
  workspaceName: string;
};

export default function ModalWorkspaceSettingGeneral() {
  const ref = useRef<HTMLInputElement>(null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateWorkspaceDataForm>({ mode: 'onSubmit' });

  const queryClient = useQueryClient();
  const { workspace } = useWorkspaceFromContext();

  const { mutate, isLoading } = useUpdateWorkspace({
    onSuccess: () => queryClient.invalidateQueries(),
  });

  useEffect(() => {
    setValue('workspaceName', workspace?.name ?? '');
  }, [setValue, workspace?.name]);

  const onSubmitFn = useCallback(
    (data: UpdateWorkspaceDataForm) => {
      mutate({
        workspaceName: data.workspaceName,
        workspaceId: workspace?.id ?? '',
      });
    },
    [mutate, workspace?.id],
  );

  return (
    <React.Fragment>
      <Section title={t('common:profileModal.workspaceSettings.title')}>
        <div className={styles.mainContainer}>
          <form onSubmit={handleSubmit(onSubmitFn)}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('forms:required', {
                    field: t('forms:workspace.create.name.label'),
                  }),
                },
                maxLength: {
                  value: 180,
                  message: t('forms:maxLength', {
                    field: t('forms:workspace.create.name.label'),
                    count: 180,
                  }),
                },
              }}
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    required
                    ref={ref}
                    label={'Name'}
                    value={value}
                    placeholder={'E.g Acme'}
                    name={name}
                    error={errors?.workspaceName?.message as string}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isLoading}
                  />
                );
              }}
              name={'workspaceName'}
            />
            <p className={styles.textLabel}>
              {t('common:profileModal.workspaceSettings.description')}
            </p>
            <input type="submit" hidden />
            <div className={styles.submitButton}>
              <Button
                label={t(`forms:workspace.settings.submitBtn`)}
                type="submit"
                variant="outline"
                size="medium"
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </Section>
    </React.Fragment>
  );
}
