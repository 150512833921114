import { useCallback, useEffect, useState } from 'react';

import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { clamp } from 'lodash';
import { Resizable } from 're-resizable';
import type { NumberSize } from 're-resizable';

import styles from './styles.module.css';

const MIN_WIDTH = 240;
const MAX_WIDTH = 440;
const root = document.querySelector(':root');

export default function SidebarResizer() {
  const [localStorageWidth, setLocalStorageWidth] = useLocalStorage<string>(
    'sidebar-width',
    String(MIN_WIDTH),
  );

  const width = Number(localStorageWidth);

  const [initialWidth, setInitialWidth] = useState(width);

  const updateWidth = useCallback(
    (delta: NumberSize) => {
      const newWidth = initialWidth + delta?.width;
      const newValue = clamp(newWidth, MIN_WIDTH, MAX_WIDTH);
      setLocalStorageWidth(String(newValue));
    },
    [initialWidth, setLocalStorageWidth],
  );

  useEffect(() => {
    // set css variable for sidebar width
    if (!root && !width) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    root?.style.setProperty('--sidebar-width', `${width}px`);
  }, [width]);

  return (
    <Resizable
      maxWidth={MAX_WIDTH}
      minWidth={MIN_WIDTH}
      size={{
        width,
        height: '100%',
      }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResize={(e, direction, ref, d) => {
        updateWidth(d);
      }}
      onResizeStart={() => {
        setInitialWidth(width);
      }}
      onResizeStop={(e, direction, ref, d) => {
        updateWidth(d);
      }}
      className={styles.container}
      handleClasses={{
        right: styles.handle,
      }}
    />
  );
}
