import { t } from 'i18next';

import useThemeSwitcher, { THEMES } from '@/hooks/utils/useThemeSwitcher';
import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';

import errorIllustrationDark from '@/assets/illustrations/error-500-dark.svg';
import errorIllustration from '@/assets/illustrations/error-500.svg';

import styles from './styles.module.css';
import { ErrorScreen } from '..';




export function Error500({ onClearError }: { onClearError?: () => void }) {
  const { theme } = useThemeSwitcher();

  return (
    <ErrorScreen>
      <div className={styles.wrapper}>
        <img
          src={
            theme === THEMES.DARK ? errorIllustrationDark : errorIllustration
          }
          alt="Error 500"
        />
        <div className={styles.content}>
          <h1 className={styles.head}>{t('common:error500.title')}</h1>
          <p className={styles.descr}>{t('common:error500.description')}</p>
        </div>
        <div className={styles.cta}>
          <Button
            label={t('common:error500.ctaLabel')}
            size="large"
            onClick={onClearError}
            link={
              onClearError
                ? undefined
                : { to: ROUTES.ROOT, preventScrollReset: false }
            }
          />
        </div>
      </div>
    </ErrorScreen>
  );
}
