import { StrictMode } from 'react';

import './styles/index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import 'react-charts';
import { HotkeysProvider } from 'react-hotkeys-hook';

import { ModalPreferencesProvider } from '@/contexts/ModalPreferenceContext';
import { NotificationsProvider } from '@/contexts/NotificationContext';
import { UIProvider } from '@/contexts/UIContext';
import { UserProvider } from '@/contexts/UserContext';
import { GLOBAL_SCOPE, LOCAL_SCOPE } from '@/types/constants';

import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Number(import.meta.env.VITE_QUERY_CACHE_TIME) },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <HotkeysProvider initiallyActiveScopes={[GLOBAL_SCOPE, LOCAL_SCOPE]}>
      <QueryClientProvider client={queryClient}>
        <UIProvider>
          <NotificationsProvider>
            <UserProvider>
              <ModalPreferencesProvider>
                <App />
              </ModalPreferencesProvider>
            </UserProvider>
          </NotificationsProvider>
        </UIProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HotkeysProvider>
  </StrictMode>,
);
